import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize, fontSizeAndHeight, FontSizeKeys } from '../../../styleHelpers/fontSizes';
import { modalsShadow } from '../../../styleHelpers/mixins/shadow';

const Wrapper = styled.div`
    position: relative;
    > div {
        display: none;
        position: absolute;
        padding: 10px;
        border-radius: 4px;
        font-size: ${fontSize[16]};
        ${modalsShadow()};
        background: ${colorStack.white};
        color: ${colorStack.content};
    }
    &:hover {
        > div {
            display: block;
            z-index: 99;
        }
    }
`;

const Text = styled.span<{ numberOfLines: number, fontSize: number }>`
    width: 100%;
    display: block;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    ${props => props.fontSize && css`
        ${fontSizeAndHeight[props.fontSize]};
    `}
    line-height: 1.1rem;
    ${props => props.numberOfLines > 1 && css`
        display: -webkit-box !important;
        white-space: normal;
        -webkit-line-clamp: ${props.numberOfLines};
    `}
`;

interface IProps {
    numberOfLines: number;
    noTooltip?: boolean;
    fontSize?: FontSizeKeys;
}

export const MultilineTextWithEllipsis: FC<React.PropsWithChildren<IProps>> = props => {
    const textRef = useRef<HTMLDivElement>();
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    useEffect(() => {
        const element = textRef?.current;
        const isTextClamped = element.scrollHeight > element.clientHeight + 1;
        const isTextTruncated = element.scrollWidth > element.clientWidth;
        setShowTooltip(isTextClamped || isTextTruncated);
    }, [textRef, textRef?.current]);

    useLayoutEffect(() => {
        const element = textRef?.current;
        if (props.noTooltip || !element) return;
        const resizeObserver = new ResizeObserver(() => {
            const isTextClamped = element.scrollHeight > element.clientHeight;
            const isTextTruncated = element.scrollWidth > element.clientWidth;
            setShowTooltip(isTextClamped || isTextTruncated);
        });
        resizeObserver.observe(element);
        return () => resizeObserver.disconnect();
    });

    return (
        <Wrapper>
            <Text numberOfLines={props.numberOfLines} ref={textRef} fontSize={props.fontSize}>
                {props.children}
            </Text>
            {showTooltip &&
                <div>{props.children}</div>
            }
        </Wrapper>
    );
};
