import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faChevronUp, faChevronDown, faCog, faPlus } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown, faCaretUp, faCircle, faGridHorizontal } from '@fortawesome/pro-solid-svg-icons';
import { faBooks } from '@fortawesome/pro-light-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { colorStack } from '../../../../styleHelpers/colors';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { ETypeOfOrganization } from '../../../../entities/IOrganization';
import { cardsShadow } from '../../../../styleHelpers/mixins/shadow';
import { CreateCluster } from '../../ClustersV2/ClusterCreation/CreateCluster';
import { GiftInvitationPopup } from '../../Wizards/GiftInvitationPopup/GiftInvitationPopup';
import { COIPopup } from '../../Wizards/COIWizard/COIPopup';
import { CorporatePopup } from '../../Wizards/CorporateWizard/CorporatePopup';
import { ContractPopup } from '../../Wizards/ContractWizard/ContractPopup';
import { SponsoringPopup } from '../../Wizards/SponsoringWizard/SponsoringPopup';
import { LobbyingWizard } from '../../Wizards/LobbyingWizard';
import { MenuItem, MenuItemLabel } from './MenuItem';
import { NormsMenu } from './NormsMenu';
import { EOrganizationPermissionsBase, EClusterPermissionsBase, EApprovalModulePermissions, ESignatureModulePermissions, EKeyDatesModulePermissions } from '../../../../entities/IPermissions';
import { ILanguageReducer } from '../../../../reducers/languageReducer';
import { IMenuReducer } from '../../../../reducers/menuReducer';
import { getClusterTemplates } from '../../../../actions/clustersActions';
import { IState } from '../../../../reducers';
import { instanceConfig } from '../../../../instance';
import { useDropdown, useResize } from '../../../../tools/hooks';
import { useOrganization } from '../../../../tools/organizationHooks';
import { IClusterTemplate, EClusterPrivacy } from '../../../../entities/IClusters';
import { EMenuItem } from '../../../../entities/IMenu';
import { media, sizes } from '../../../../styleHelpers/breakpoint';
import { useNormsMenuTOCAutoScroll } from '../../../../tools/legalDocTools/legalDocSidebarToc';
import { Explore } from '../../../Common/CutomIcons/Explore';
import { Signature } from '../../../Common/CutomIcons/Signature';
import { Dashboard } from '../../../Common/CutomIcons/Dashboard';
import { Members } from '../../../Common/CutomIcons/Members';
import { Graph } from '../../../Common/CutomIcons/Graph';
import { KeyDates } from '../../../Common/CutomIcons/KeyDates';
import { Euro } from '../../../Common/CutomIcons/Euro';
import { Entities } from '../../../Common/CutomIcons/Entities';
import { Participation } from '../../../Common/CutomIcons/Participation';
import { Mandates } from '../../../Common/CutomIcons/Mandates';
import { Panel } from '../../../Common/CutomIcons/Panel';
import { Request } from '../../../Common/CutomIcons/Request';
import { Approval } from '../../../Common/CutomIcons/Approval';
import { HeartBook } from '../../../Common/CutomIcons/HeartBook';
import { LegalSupportWizard } from '../../Wizards/LegalSupportWizard';
import { getNormativeDocumentations } from '../../../../actions/sogeActions';
import { getSogeCollectionsUrl } from '../../../../tools/legalDocTools/sogeTools';
import { AssetsManagementWizard } from '../../Wizards/AssetsManagement';
import { LandLordWizard } from '../../Wizards/LandLord';
import { LineSeparator } from '../../../Common/LineSeparator/LineSeparator';
import { DisputeWizard } from '../../Wizards/DisputeWizard';
import { TradeAssociationWizard } from '../../Wizards/TradeAssociationWizard';
import { EApprovalWizard } from '../../Wizards/EApprovalWizard';
import { POAWizard } from '../../Wizards/POAWizard';
import { topMenuHeight } from '../../../../tools/globalVariables';
import IntlMessage from '../../../Common/IntlMessage';
import GeneralWizard from '../../Wizards/General';

type GetClusterTemplates = ReturnType<typeof getClusterTemplates>;
type GetNormativeDocumentations = ReturnType<typeof getNormativeDocumentations>;
interface IParentPositionProps {
    parentWrapperPosition?: DOMRect;
}

const Wrapper = styled.div<IParentPositionProps & { dropdownOpen?: boolean }>`
    background: ${colorStack.whiteGrey};
    width: 100%;
    display: flex;
    margin: 0 .5rem .5rem 0;
    flex-direction: column;
    z-index: 10;
    ${media.desktop`
        padding: 1rem .5rem;
        margin: 0;
    `}
    ${({ parentWrapperPosition, dropdownOpen }) => parentWrapperPosition && css`
        ${media.desktop`
            overflow: auto;
            position: fixed;
            left: ${parentWrapperPosition.x}px;
            width: ${parentWrapperPosition.width}px;
            top: calc(${topMenuHeight}px + .5rem);
            bottom: .5rem;
            ${dropdownOpen && css`
                overflow: visible;
            `}
        `}
    `}
`;

const NameBox = styled.div`
    display: flex;
    align-items: center;
    ${cardsShadow()};
    padding: .5rem 1rem;
    ${media.desktop`
        padding: 0;
        margin-bottom: 3rem;
        box-shadow: none;
    `}
    img, svg {
        width: 32px;
        border-radius: 4px;
        margin: 0 1rem 0 0;
        font-size: ${fontSize[39]};
    }
    span {
        font-size: ${fontSize[20]};
        color: ${colorStack.darkBlue};
        font-weight: 500;
        ${media.desktop`
            font-size: ${fontSize[20]};
        `}
    }
    > div {
        margin: 0 0 0 auto;
        > svg {
            font-size: ${fontSize[13]};
        }
    }
`;

const Links = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    ${media.desktop`
        max-width: 100%;
        margin: 0;
    `}
`;

const Button = styled.button<{ active?: boolean }>`
    position: relative;
    text-decoration: none;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    ${props => props.active && css`
        border-radius: 4px;
        background: ${colorStack.white};
    `}
    span, svg {
        width: 20px;
        color: ${colorStack.darkGrey};
        font-size: ${fontSize[16]};
        stroke: ${colorStack.disabled};
        text-decoration: none;
        ${props => props.active && css`
            color: ${colorStack.middleBlue};
            text-decoration: none;
            stroke: ${colorStack.middleBlue};
        `}
    }
`;

const ExpandMenuBox = styled.div`
    background: ${colorStack.white};
    border-radius: 4px;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 210px;
    padding: .5rem 0;
    white-space: nowrap;
    min-width: 250px;
    z-index: 1000;
    ${cardsShadow()};
    a {
        text-decoration: none;
    }
`;

const LoadingInfoContainer = styled.div`
    align-items: center;
    color: ${colorStack.middleBlue};
    padding: .5rem 1rem;
    background-color: ${colorStack.white};
    > span {
        font-size:${fontSize[13]};
        margin: 0 .5rem;
        width: 100%;
        font-weight: 500;
    }
`;

const SingleTemplate = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${colorStack.middleBlue};
    padding: .5rem 1rem;
    background-color: ${colorStack.white};
    transition: background-color .3s;
    &:hover {
        background-color: ${colorStack.whiteBlue};
    }
    img {
        width: 19px;
    }
    > span {
        font-size:${fontSize[13]};
        margin: 0 .5rem;
        width: 100%;
        font-weight: 500;
        text-align: left;
    }
    > svg {
        font-size: ${fontSize[10]};
    }
`;

const MenuListContainer = styled.div`
    margin-left: 16px;
`;

export enum EPopupType {
    POA = 'poa',
    COI = 'coi',
    SPONSORING = 'sponsoring',
    CORPORATE = 'corporate',
    CONTRACT = 'contract',
    LOBBYING = 'lobbying',
    GIFT = 'gift',
    TRADEASSOCIATION = 'tradeAssociation',
    DISPUTE = 'dispute',
    LEGALSUPPORT = 'legalSupport',
    ASSETSMANAGMENT = 'assetsManagement',
    LANDLORD = 'landlord',
    EAPPROVAL = 'eApproval',
    DUPLICATE = 'duplicate',
    GENERATE = 'generate',
    APPROVAL = 'APPROVAL',
    GENERAL = 'GENERAL'
}
interface IProps extends IParentPositionProps {
    withNormsConsultationToc?: boolean;
    bookCommonLevelId?: string;
}

interface IClusterForm {
    title: string;
    description: string;
    type: EClusterPrivacy;
}

export const Menu: FC<React.PropsWithChildren<IProps>> = props => {
    const { leftMenu } = instanceConfig;
    const dispatch = useDispatch();
    const { organization } = useParams<{ organization: string }>();
    const { innerWidth } = useResize();
    const history = useNavigate();
    const [dropdownRef, dropdownOpen, toggleDropdown] = useDropdown();
    const { currentOrganization, currentOrgPermissions } = useOrganization(organization);
    const { userLanguage, menuItem } = useSelector<IState, ILanguageReducer & IMenuReducer>(state => ({
        ...state.language,
        ...state.menu
    }));
    const { search } = useLocation();
    const params = new URLSearchParams(search);

    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
    const [clusterTemplates, setClusterTemplates] = useState<IClusterTemplate[]>([]);
    const [clusterForm, setClusterForm] = useState<IClusterForm>(undefined);
    const [showGifInvitationtWizard, setShowGifInvitationtWizard] = useState<boolean>(false);
    const [showCOIWizard, setShowCOIWizard] = useState<boolean>(false);
    const [showContract, setShowContract] = useState<boolean>(false);
    const [showLegalSupport, setShowLegalSupport] = useState<boolean>(false);
    const [showLobbying, setShowLobbying] = useState<boolean>(false);
    const [showLandlordWizard, setShowLandlordWizard] = useState<boolean>(false);
    const [showAssetsManagmentWizard, setShowAssetsManagmentWizard] = useState<boolean>(false);
    const [showCorporatePopup, setShowCorporatePopup] = useState<boolean>(false);
    const [showDisputeWizard, setShowDisputeWizard] = useState<boolean>(false);
    const [showTradeAssociationWizard, setShowTradeAssociationWizard] = useState<boolean>(false);
    const [showSponsoringWizard, setShowSponsoringWizard] = useState<boolean>(false);
    const [showEApprovalWizard, setShowEApprovalWizard] = useState<boolean>(false);
    const [showPOAWizard, setShowPOAWizard] = useState<boolean>(false);
    const [showGeneralWizard, setShowGeneralWizard] = useState<boolean>(false);
    const [selectedTemplate, setSelectedTemplate] = useState<IClusterTemplate>(undefined);
    const [showDashboardsList, setShowDashboardsList] = useState<boolean>(false);
    const wrapperScrollRef = useNormsMenuTOCAutoScroll<HTMLDivElement>();

    const isCorporate = useMemo(() => {
        return currentOrganization?.details?.organizationType?.key === ETypeOfOrganization.CORPORATE;
    }, [currentOrganization]);
    const isGift = useMemo(() => {
        return currentOrganization?.details?.organizationType?.key === ETypeOfOrganization.GIFT;
    }, [currentOrganization]);
    const isCOI = useMemo(() => {
        return currentOrganization?.details?.organizationType?.key === ETypeOfOrganization.COI;
    }, [currentOrganization]);
    const isPanels = useMemo(() => {
        return currentOrganization?.details?.organizationType?.key === ETypeOfOrganization.PANELS;
    }, [currentOrganization]);
    const isContract = useMemo(() => {
        return currentOrganization?.details?.organizationType?.key === ETypeOfOrganization.CONTRACT;
    }, [currentOrganization]);
    const isQuotation = useMemo(() => {
        return currentOrganization?.details?.organizationType?.key === ETypeOfOrganization.QUOTATION;
    }, [currentOrganization]);
    const isNorms = useMemo(() => {
        return currentOrganization?.details.organizationType?.key === ETypeOfOrganization?.NORMS;
    }, [currentOrganization]);
    const isPOA = useMemo(() => {
        return currentOrganization?.details.organizationType?.key === ETypeOfOrganization.POA;
    }, [currentOrganization]);
    const isSponsoring = useMemo(() => {
        return currentOrganization?.details.organizationType?.key === ETypeOfOrganization.SPONSORING;
    }, [currentOrganization]);
    const isGeneral = useMemo(() => {
        return currentOrganization?.details.organizationType?.key === ETypeOfOrganization.GENERAL;
    }, [currentOrganization]);
    const isLobbying = useMemo(() => {
        return currentOrganization?.details.organizationType?.key === ETypeOfOrganization.LOBBYING;
    }, [currentOrganization]);
    const isLegalSupport = useMemo(() => {
        return currentOrganization?.details.organizationType?.key === ETypeOfOrganization.LEGALSUPPORT;
    }, [currentOrganization]);
    const isAssetsManagement = useMemo(() => {
        return currentOrganization?.details.organizationType?.key === ETypeOfOrganization.ASSETSMANAGEMENT;
    }, [currentOrganization]);
    const isLandlord = useMemo(() => {
        return currentOrganization?.details.organizationType?.key === ETypeOfOrganization.LANDLORD;
    }, [currentOrganization]);
    const isEApproval = useMemo(() => {
        return currentOrganization?.details.organizationType?.key === ETypeOfOrganization.EAPPROVAL;
    }, [currentOrganization]);
    const isDispute = useMemo(() => {
        return currentOrganization?.details.organizationType?.key === ETypeOfOrganization.DISPUTE;
    }, [currentOrganization]);
    const isTradeAssociation = useMemo(() => {
        return currentOrganization?.details.organizationType?.key === ETypeOfOrganization.TRADEASSOCIATION;
    }, [currentOrganization]);

    useEffect(() => {
        setClusterTemplates(undefined);
        leftMenu.clusters && currentOrganization?.id && dispatch<GetClusterTemplates>(getClusterTemplates(currentOrganization.id))
            .then(response => {
                setClusterTemplates(response || []);
            });
        if (isAssetsManagement) {
            dispatch<GetNormativeDocumentations>(getNormativeDocumentations(currentOrganization.id));
        }
    }, [currentOrganization?.id, userLanguage]);

    const setWizardOn = (name: EPopupType) => {
        history({
            search: `popup=${name}`
        });
    };

    const newHandler = () => {
        toggleDropdown();
    };

    const setDashboardslistVisibility = (state) => {
        setShowDashboardsList(state);
    };

    const onSelectTemplate = useCallback((index: number) => {
        setSelectedTemplate(clusterTemplates[index]);
    }, [clusterTemplates]);

    const setCorporatePopupVisibility = (state) => {
        setShowCorporatePopup(state);
        history({
            search: ``
        });
    };

    const setGiftInvitatioWizard = (state) => {
        setShowGifInvitationtWizard(state);
        history({
            search: ``
        });
    };

    const setTradeAssociationWizardVisibility = (state) => {
        setShowTradeAssociationWizard(state);
        history({
            search: ``
        });
    };

    const setDisputeWizardVisibility = (state) => {
        setShowDisputeWizard(state);
        history({
            search: ``
        });
    };

    const setCOIWizard = (state) => {
        setShowCOIWizard(state);
        history({
            search: ``
        });
    };

    const setContractPopupVisibility = (state) => {
        setShowContract(state);
        history({
            search: ``
        });
    };

    const setLegalSupportPopupVisibility = (state) => {
        setShowLegalSupport(state);
        history({
            search: ``
        });
    };

    const setLobbyingWizardVisibility = (state) => {
        setShowLobbying(state);
        history({
            search: ``
        });
    };

    const setSponsoringWizard = (state) => {
        setShowSponsoringWizard(state);
        history({
            search: ``
        });
    };

    const showAssetsManagmentPopup = (state) => {
        setShowAssetsManagmentWizard(state);
        history({
            search: ``
        });
    };

    const showLandlordPopup = (state) => {
        setShowLandlordWizard(state);
        history({
            search: ``
        });
    };

    const showEApprovalPopup = (state) => {
        setShowEApprovalWizard(state);
        history({
            search: ``
        });
    };

    const showGeneralPopup = (state) => {
        setShowGeneralWizard(state);
        history({
            search: ``
        });
    };

    const mobileMenuHndler = useCallback(() => {
        if (innerWidth < sizes.desktop) {
            setShowMobileMenu(state => !state);
        }
    }, [innerWidth]);

    useEffect(() => {
        switch (params.get('popup')) {
            case EPopupType.POA:
                return setShowPOAWizard(true);
            case EPopupType.ASSETSMANAGMENT:
                return setShowAssetsManagmentWizard(true);
            case EPopupType.COI:
                return setShowCOIWizard(true);
            case EPopupType.CONTRACT:
                return setShowContract(true);
            case EPopupType.CORPORATE:
                return setShowCorporatePopup(true);
            case EPopupType.DISPUTE:
                return setShowDisputeWizard(true);
            case EPopupType.EAPPROVAL:
                return setShowEApprovalWizard(true);
            case EPopupType.GIFT:
                return setShowGifInvitationtWizard(true);
            case EPopupType.LANDLORD:
                return setShowLandlordWizard(true);
            case EPopupType.LEGALSUPPORT:
                return setShowLegalSupport(true);
            case EPopupType.LOBBYING:
                return setShowLobbying(true);
            case EPopupType.SPONSORING:
                return setShowSponsoringWizard(true);
            case EPopupType.TRADEASSOCIATION:
                return setShowTradeAssociationWizard(true);
            case EPopupType.GENERAL:
                return setShowGeneralWizard(true);
            default:
                setShowGeneralWizard(false);
                setShowPOAWizard(false);
                setShowCOIWizard(false);
                setShowContract(false);
                setShowAssetsManagmentWizard(false);
                setShowGifInvitationtWizard(false);
                setShowCorporatePopup(false);
                setShowDisputeWizard(false);
                setShowEApprovalWizard(false);
                setShowLandlordWizard(false);
                setShowLegalSupport(false);
                setShowLobbying(false);
                setShowSponsoringWizard(false);
                setShowTradeAssociationWizard(false);
                return;
        }
    }, [params.get('popup')]);

    return (
        <>
            <Wrapper parentWrapperPosition={props.parentWrapperPosition} dropdownOpen={dropdownOpen} ref={wrapperScrollRef}>
                <NameBox onClick={mobileMenuHndler}>
                    {currentOrganization?.details?.logo ? (
                        <img src={currentOrganization.details.logo} />
                    ) : (
                        <FontAwesomeIcon icon={faBuilding} />
                    )}
                    <span>{currentOrganization.details.name}</span>
                    {innerWidth < sizes.desktop && <div><FontAwesomeIcon icon={showMobileMenu ? faChevronUp : faChevronDown} /></div>}
                </NameBox>
                {(innerWidth >= sizes.desktop || showMobileMenu) &&
                    <Links>
                        {isNorms ? (
                            <NormsMenu organizationUrlName={organization} activeMenuItem={props.withNormsConsultationToc ? EMenuItem.norms : menuItem} withToc={props.withNormsConsultationToc} bookCommonLevelId={props?.bookCommonLevelId} />
                        ) : (
                            <>
                                {(isGeneral && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <Button onClick={() => setWizardOn(EPopupType.GENERAL)} type="button" data-lc="js-lc-button-newContract"><IntlMessage id="menu.l3.New" tagName="span" /> <FontAwesomeIcon icon={faPlus} /></Button>
                                }
                                {(isContract && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <Button onClick={() => setWizardOn(EPopupType.CONTRACT)} type="button" data-lc="js-lc-button-newContract"><IntlMessage id="menu.l3.NewContract" tagName="span" /> <FontAwesomeIcon icon={faPlus} /></Button>
                                }
                                {(isAssetsManagement && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <Button onClick={() => setWizardOn(EPopupType.ASSETSMANAGMENT)} type="button" data-lc="js-lc-button-new"><IntlMessage id="menu.l3.addInvestors" tagName="span" /> <FontAwesomeIcon icon={faPlus} /></Button>
                                }
                                {(isLandlord && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <Button onClick={() => setWizardOn(EPopupType.LANDLORD)} type="button" data-lc="js-lc-button-new"><IntlMessage id="menu.l3.addLease" tagName="span" /> <FontAwesomeIcon icon={faPlus} /></Button>
                                }
                                {(isEApproval && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <Button onClick={() => setWizardOn(EPopupType.EAPPROVAL)} type="button" data-lc="js-lc-button-new"><IntlMessage id="menu.l3.Add" tagName="span" /> <FontAwesomeIcon icon={faPlus} /></Button>
                                }
                                {(isLegalSupport && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <Button onClick={() => setWizardOn(EPopupType.LEGALSUPPORT)} type="button" data-lc="js-lc-button-newContract"><IntlMessage id="menu.l3.NewLegalSupport" tagName="span" /> <FontAwesomeIcon icon={faPlus} /></Button>
                                }
                                {(isLobbying && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <Button onClick={() => setWizardOn(EPopupType.LOBBYING)} type="button" data-lc="js-lc-button-newContract"><IntlMessage id="menu.l3.New" tagName="span" /> <FontAwesomeIcon icon={faPlus} /></Button>
                                }
                                {(isCorporate && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <Button onClick={() => setWizardOn(EPopupType.CORPORATE)} type="button" data-lc="js-lc-button-add"><IntlMessage id="menu.l3.Add" tagName="span" /> <FontAwesomeIcon icon={faPlus} /></Button>
                                }
                                {(isSponsoring && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <Button onClick={() => setWizardOn(EPopupType.SPONSORING)} type="button" data-lc="js-lc-button-add"><IntlMessage id="menu.l3.Add" tagName="span" /> <FontAwesomeIcon icon={faPlus} /></Button>
                                }
                                {(isGift && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <Button onClick={() => setWizardOn(EPopupType.GIFT)} type="button" data-lc="js-lc-button-declare"><IntlMessage id="menu.l3.Declare" tagName="span" /> <FontAwesomeIcon icon={faPlus} /></Button>
                                }
                                {(isCOI && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <Button onClick={() => setWizardOn(EPopupType.COI)} type="button" data-lc="js-lc-button-new"><IntlMessage id="menu.l3.Declare" tagName="span" /> <FontAwesomeIcon icon={faPlus} /></Button>
                                }
                                {(isDispute && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <Button onClick={() => setWizardOn(EPopupType.DISPUTE)} type="button" data-lc="js-lc-button-new"><IntlMessage id="menu.l3.New" tagName="span" /> <FontAwesomeIcon icon={faPlus} /></Button>
                                }
                                {(isTradeAssociation && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <Button onClick={() => setWizardOn(EPopupType.TRADEASSOCIATION)} type="button" data-lc="js-lc-button-new"><IntlMessage id="menu.l3.New" tagName="span" /> <FontAwesomeIcon icon={faPlus} /></Button>
                                }
                                {isQuotation &&
                                    <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/quotation`} active={menuItem === EMenuItem.askQuotation} data-lc="js-lc-button-askQuotation"><IntlMessage id="menu.l3.askQuotation" tagName="span" /> <Euro width={20} /></MenuItem>
                                }
                                {isPanels &&
                                    <>
                                        <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/dashboard`} active={menuItem === EMenuItem.explore} data-lc="js-lc-button-explore"><IntlMessage id="menu.l3.explore" tagName="span" /> <Explore width={20} /></MenuItem>
                                        <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/panel`} active={menuItem === EMenuItem.panel} data-lc="js-lc-button-panel"><IntlMessage id="menu.l3.panel" tagName="span" /> <Panel width={20} /></MenuItem>
                                        <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/rfp/myrequests`} active={menuItem === EMenuItem.myrequests} data-lc="js-lc-button-myRequests"><IntlMessage id="menu.l3.myrequests" tagName="span" /> <Request width={20} /></MenuItem>
                                    </>
                                }
                                {(isPOA && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <Button onClick={() => setWizardOn(EPopupType.POA)} disabled={!clusterTemplates || !clusterTemplates.length} type="button" data-lc="js-lc-button-new">
                                        <IntlMessage id="menu.l3.New" tagName="span" />
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                }
                                {(!isPanels && !isLobbying) &&
                                    <>
                                        <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/dashboard`} active={menuItem === EMenuItem.explore} data-lc="js-lc-button-explore"><IntlMessage id="menu.l3.explore" tagName="span" /> <Explore width={20} /></MenuItem>
                                        {(!isCorporate && !isPOA && !isGift && !isCOI && !isSponsoring && !isContract && !isLegalSupport && !isAssetsManagement && !isLandlord && !isTradeAssociation) &&
                                            <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters`} active={menuItem === EMenuItem.dashboard} data-lc="js-lc-button-dashboard"><IntlMessage id="menu.l3.dashboard" tagName="span" /> <Dashboard width={20} /></MenuItem>
                                        }
                                        {isPOA &&
                                            <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/poa-holders`} active={menuItem === EMenuItem.poaDashboard} data-lc="js-lc-button-poaDashboard"><IntlMessage id="menu.l3.poaDashboard" tagName="span" /> <Dashboard width={20} /></MenuItem>
                                        }
                                        {isSponsoring &&
                                            <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/sponsoring-dashboard`} active={menuItem === EMenuItem.sponsoringDashboard} data-lc="js-lc-button-sponsoringDashboard"><IntlMessage id="menu.l3.dashboard" tagName="span" /> <Dashboard width={20} /></MenuItem>
                                        }
                                        {isGift && currentOrgPermissions?.[EOrganizationPermissionsBase.ManageOrganization] &&
                                            <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/gift-dashboard`} active={menuItem === EMenuItem.dashboard} data-lc="js-lc-button-giftDashboard"><IntlMessage id="menu.l3.dashboard" tagName="span" /> <Dashboard width={20} /></MenuItem>
                                        }
                                        {isCOI && currentOrgPermissions?.[EOrganizationPermissionsBase.ManageOrganization] &&
                                            <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/coi-dashboard`} active={menuItem === EMenuItem.dashboard} className="js-lc-button-coiDashboard"><IntlMessage id="menu.l3.dashboard" tagName="span" /> <Dashboard width={20} /></MenuItem>
                                        }
                                        {isTradeAssociation && currentOrgPermissions?.[EOrganizationPermissionsBase.ManageOrganization] &&
                                            <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/trade-association-dashboard`} active={menuItem === EMenuItem.tradeAssociationDashboard} className="js-lc-button-coiDashboard"><IntlMessage id="menu.l3.dashboard" tagName="span" /> <Dashboard width={20} /></MenuItem>
                                        }
                                        {isContract &&
                                            <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters`} active={menuItem === EMenuItem.dashboard} data-lc="js-lc-button-dashboard"><IntlMessage id="menu.l3.dashboard" tagName="span" /> <Dashboard width={20} /></MenuItem>
                                        }
                                        {isLegalSupport &&
                                            <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/legal-support-dashboard`} active={menuItem === EMenuItem.dashboard} data-lc="js-lc-button-dashboard"><IntlMessage id="menu.l3.dashboard" tagName="span" /> <Dashboard width={20} /></MenuItem>
                                        }
                                        {isLandlord && currentOrgPermissions?.[EClusterPermissionsBase.ViewDatagid] &&
                                            <>
                                                <Button onClick={setDashboardslistVisibility} type="button">
                                                    <IntlMessage id="menu.l3.dashboard" tagName="span" />
                                                    <FontAwesomeIcon icon={showDashboardsList ? faCaretUp : faCaretDown} />
                                                </Button>
                                                {showDashboardsList &&
                                                    <>
                                                        <MenuListContainer>
                                                            <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/landlord-dashboard`} active={menuItem === EMenuItem.landLordDashboard} data-lc="js-lc-button-landlordGeneral-dashboard"><IntlMessage id="menu.l3.landlordGeneral" tagName="span" /> <Dashboard width={20} /></MenuItem>
                                                            <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/landlord-owner`} active={menuItem === EMenuItem.landlordOwnerDashboard} data-lc="js-lc-button-landlordOwner-dashboard"><IntlMessage id="menu.l3.landlordOwner" tagName="span" /> <Dashboard width={20} /></MenuItem>
                                                            <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/landlord-lease`} active={menuItem === EMenuItem.landlordLeaseDashboard} data-lc="js-lc-button-landlordLease-dashboard"><IntlMessage id="menu.l3.landlordLease" tagName="span" /> <Dashboard width={20} /></MenuItem>
                                                            <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/landlord-lot`} active={menuItem === EMenuItem.landlordLotDashboard} data-lc="js-lc-button-landlordLot-dashboard"><IntlMessage id="menu.l3.landlordLot" tagName="span" /> <Dashboard width={20} /></MenuItem>
                                                            <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/landlord-occupant`} active={menuItem === EMenuItem.landlordOccupantDashboard} data-lc="js-lc-button-landlordOccupant-dashboard"><IntlMessage id="menu.l3.landlordOccupant" tagName="span" /> <Dashboard width={20} /></MenuItem>
                                                        </MenuListContainer>
                                                    </>
                                                }
                                            </>
                                        }
                                        {!isLandlord && <LineSeparator />}
                                    </>
                                }
                                {isDispute &&
                                    <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/instances`} active={menuItem === EMenuItem.instancesDashboard} data-lc="js-lc-button-disputeInstance-dashboard"><IntlMessage id="menu.l3.instancesDashboard" tagName="span" /> <Dashboard width={20} /></MenuItem>
                                }
                                {isLobbying &&
                                    <>
                                        <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/dashboard`} active={menuItem === EMenuItem.explore} data-lc="js-lc-button-explore"><IntlMessage id="menu.l3.explore" tagName="span" /> <Explore width={20} /></MenuItem>
                                        <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/project-dashboard`} active={menuItem === EMenuItem.projectDashboard} data-lc="js-lc-button-projectDashboard"><IntlMessage id="menu.l3.projectDashboard" tagName="span" /> <Explore width={20} /></MenuItem>
                                        <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/actions-dashboard`} active={menuItem === EMenuItem.actionsDashboard} data-lc="js-lc-button-projectDashboard"><IntlMessage id="menu.l3.actionsDashboard" tagName="span" /> <Explore width={20} /></MenuItem>
                                    </>
                                }
                                {isCorporate &&
                                    <>
                                        <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/entities/datagrid`} active={menuItem === EMenuItem.entitiesDashboard} data-lc="js-lc-button-entitiesDashboard"><IntlMessage id="menu.l3.entitiesDashboard" tagName="span" /><Entities width={20} /></MenuItem>
                                        <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/participation`} active={menuItem === EMenuItem.participationDashboard} data-lc="js-lc-button-participationDashboard"><IntlMessage id="menu.l3.participationDashboard" tagName="span" /><Participation width={20} /></MenuItem>
                                        <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/entities/graph`} active={menuItem === EMenuItem.chart} data-lc="js-lc-button-chart"><IntlMessage id="menu.l3.chart" tagName="span" /><Graph width={20} /></MenuItem>
                                        <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/mandate-holders`} active={menuItem === EMenuItem.mandateDashboard} data-lc="js-lc-button-mandateDashboard"><IntlMessage id="menu.l3.mandateDashboard" tagName="span" /><Mandates width={20} /></MenuItem>
                                    </>
                                }
                                {(!isGift && !isTradeAssociation && !isLandlord && currentOrgPermissions?.[EOrganizationPermissionsBase.OrganizationsAccountsView]) &&
                                    <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/directory`} active={menuItem === EMenuItem.workspaceUsers} data-lc="js-lc-button-directory"><IntlMessage id="menu.l3.workspaceUsers" tagName="span" /><Members width={20} /></MenuItem>
                                }
                                {isCOI && isTradeAssociation && currentOrgPermissions?.[EOrganizationPermissionsBase.ManageOrganization] &&
                                    <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/directory`} active={menuItem === EMenuItem.workspaceUsers} data-lc="js-lc-button-directory"><IntlMessage id="menu.l3.workspaceUsers" tagName="span" /><Members width={20} /></MenuItem>
                                }
                                {isPOA &&
                                    <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/poa-holders`} active={menuItem === EMenuItem.POA} data-lc="js-lc-button-poaHolders"><IntlMessage id="menu.l3.POA" tagName="span" /><Members width={20} /></MenuItem>
                                }
                                {(leftMenu.signature /*  && haveSignature */ && !isLegalSupport && !isAssetsManagement && currentOrgPermissions?.[ESignatureModulePermissions.View]) && <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/signature`} active={menuItem === EMenuItem.signature} data-lc="js-lc-button-signature"><IntlMessage id="menu.l3.signature" tagName="span" /> <Signature width={20} /></MenuItem>}
                                {(leftMenu.campaign && !isLandlord && currentOrgPermissions?.['Organizations.Campaigns.View'] && currentOrgPermissions?.[EOrganizationPermissionsBase.ManageOrganization] && !isAssetsManagement) && <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/campaigns`} active={menuItem === EMenuItem.campaignDashboard} className="js-lc-button-campaigns"><IntlMessage id="menu.l3.campaign" tagName="span" /> <Dashboard width={20} /></MenuItem>}
                                {(leftMenu.approval /*  && haveApproval */ && !isAssetsManagement && currentOrgPermissions?.[EApprovalModulePermissions.View]) && <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/approval`} active={menuItem === EMenuItem.approval} data-lc="js-lc-button-approval"><IntlMessage id="menu.l3.approval" tagName="span" /> <Approval width={20} /></MenuItem>}
                                {(isAssetsManagement) &&
                                    <>
                                        <MenuItem dataLc="js-lc-button-norm-menu-lpa" to={getSogeCollectionsUrl(currentOrganization?.details?.urlName, 'current')} active={menuItem === EMenuItem.LPA}><IntlMessage id="menu.l3.LPA" tagName="span" /> <FontAwesomeIcon icon={faBooks} /></MenuItem>

                                        {currentOrganization &&
                                            <>
                                                <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/allFAQ`} active={menuItem === EMenuItem.AllFAQ} data-lc="js-lc-button-allFAQ"><IntlMessage id="menu.l3.AllFAQ" tagName="span" /> <FontAwesomeIcon icon={faGridHorizontal} /> </MenuItem>
                                                <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clausier`} active={menuItem === EMenuItem.Clausier} data-lc="js-lc-button-Clausier"><IntlMessage id="menu.l3.Clausier" tagName="span" /> <FontAwesomeIcon icon={faGridHorizontal} /> </MenuItem>
                                            </>
                                        }
                                        <LineSeparator />
                                        <Button onClick={setDashboardslistVisibility} type="button">
                                            <IntlMessage id="menu.l3.dashboard" tagName="span" />
                                            <FontAwesomeIcon icon={showDashboardsList ? faCaretUp : faCaretDown} />
                                        </Button>
                                        {showDashboardsList &&
                                            <>
                                                <MenuListContainer>
                                                    <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/questions`} active={menuItem === EMenuItem.AllQNA} data-lc="js-lc-button-allQNA"><IntlMessage id="menu.l3.AllQNA" tagName="span" /> <FontAwesomeIcon icon={faGridHorizontal} /> </MenuItem>
                                                    <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/composite-side-letter`} active={menuItem === EMenuItem.CompositeSideLetter} data-lc="js-lc-button-compositeSideLetter"><IntlMessage id="menu.l3.compositeSideLetter" tagName="span" /> <FontAwesomeIcon icon={faGridHorizontal} /> </MenuItem>
                                                    <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/tracker`} active={menuItem === EMenuItem.Tracker} data-lc="js-lc-button-tracker"><IntlMessage id="menu.l3.Tracker" tagName="span" /> <FontAwesomeIcon icon={faGridHorizontal} /> </MenuItem>
                                                </MenuListContainer>
                                            </>
                                        }
                                    </>
                                }
                                {(isPOA) &&
                                    <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clausier-poa`} active={menuItem === EMenuItem.ClausierPOA} data-lc="js-lc-button-Clausier"><IntlMessage id="menu.l3.Clausier" tagName="span" /> <FontAwesomeIcon icon={faGridHorizontal} /> </MenuItem>
                                }
                                {(isContract || isPOA || isGeneral || isLegalSupport || isLobbying || isLandlord || isDispute) && currentOrgPermissions?.[EKeyDatesModulePermissions.ViewAll] &&
                                    <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/clusters/dates`} active={menuItem === EMenuItem.keyDate} data-lc="js-lc-button-keyDate"><IntlMessage id="menu.l3.keyDate" tagName="span" /> <KeyDates width={20} /></MenuItem>
                                }
                                {((isQuotation || isPanels) && currentOrgPermissions?.[EOrganizationPermissionsBase.CreateCluster]) &&
                                    <>
                                        <LineSeparator />
                                        <Button onClick={newHandler} type="button">
                                            <MenuItemLabel ref={dropdownRef}><IntlMessage id="menu.l3.newCluster" tagName="span" /> <FontAwesomeIcon icon={faPlus} /></MenuItemLabel>
                                            {dropdownOpen &&
                                                <ExpandMenuBox>
                                                    {
                                                        !clusterTemplates
                                                            ? (
                                                                <LoadingInfoContainer>
                                                                    <IntlMessage id="global.loadingDots" tagName="span" />
                                                                </LoadingInfoContainer>
                                                            ) : (
                                                                clusterTemplates?.map((template, i) => (
                                                                    <SingleTemplate key={template.id} onClick={() => onSelectTemplate(i)}>
                                                                        <FontAwesomeIcon icon={faCircle} />
                                                                        <span>{template.templateName}</span>
                                                                    </SingleTemplate>
                                                                ))
                                                            )
                                                    }
                                                </ExpandMenuBox>
                                            }
                                        </Button>
                                    </>
                                }
                            </>
                        )}
                        {(currentOrganization?.kmClusterId && currentOrgPermissions?.[EOrganizationPermissionsBase.OrganizationsAccountsView]) &&
                            <MenuItem to={`/orgs/${currentOrganization?.details?.urlName}/cluster/${currentOrganization?.kmClusterId}/activity`} active={menuItem === EMenuItem.KnowledgeManagement} data-lc="js-lc-button-knowledgeManagement">
                                <IntlMessage id="leftmenu.elem.KnowledgeManagement" tagName="span" />
                                <HeartBook width={20} />
                            </MenuItem>
                        }
                        {currentOrgPermissions?.[EOrganizationPermissionsBase.ManageOrganization] &&
                            <>
                                <LineSeparator />
                                <MenuItem dataLc="js-lc-button-morm-menu-settings" to={`/orgs/${currentOrganization?.details?.urlName}/settings`} active={menuItem === EMenuItem.settings} data-lc="js-lc-button-settings"><IntlMessage id="menu.l3.settings" tagName="span" /><FontAwesomeIcon icon={faCog} /></MenuItem>
                            </>
                        }
                    </Links>
                }
            </Wrapper>
            {selectedTemplate &&
                <CreateCluster
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    clusterForm={clusterForm}
                    setClusterForm={setClusterForm}
                />
            }
            {showGeneralWizard &&
                <GeneralWizard
                    organizationUrlName={currentOrganization?.details?.urlName}
                    isPopupShown={showGeneralWizard}
                    setShowPopup={showGeneralPopup}
                />
            }
            {showContract &&
                <ContractPopup
                    organizationId={currentOrganization?.id}
                    organizationUrlName={currentOrganization?.details?.urlName}
                    isPopupShown={showContract}
                    setIsContractPopupOn={setContractPopupVisibility}
                />
            }
            {showGifInvitationtWizard &&
                <GiftInvitationPopup
                    organizationId={currentOrganization?.id}
                    organizationUrlName={currentOrganization?.details?.urlName}
                    isPopupShown={showGifInvitationtWizard}
                    setIsGiftInvitationPopupOn={setGiftInvitatioWizard}
                />
            }
            {showCOIWizard &&
                <COIPopup
                    organizationId={currentOrganization?.id}
                    organizationUrlName={currentOrganization?.details?.urlName}
                    isPopupShown={showCOIWizard}
                    setIsCOIPopupOn={setCOIWizard}
                />
            }
            {showCorporatePopup &&
                <CorporatePopup
                    organizationId={currentOrganization?.id}
                    organizationUrlName={currentOrganization?.details?.urlName}
                    organizationName={currentOrganization?.details?.name}
                    isPopupShown={showCorporatePopup}
                    setShowCorporatePopup={setCorporatePopupVisibility}
                />
            }
            {showSponsoringWizard &&
                <SponsoringPopup
                    organizationId={currentOrganization?.id}
                    organizationUrlName={currentOrganization?.details?.urlName}
                    organizationName={currentOrganization?.details?.name}
                    isPopupShown={showSponsoringWizard}
                    setShowSponsoringWizard={setSponsoringWizard}
                />
            }
            {showLobbying &&
                <LobbyingWizard
                    organizationId={currentOrganization?.id}
                    organizationUrlName={currentOrganization?.details?.urlName}
                    isPopupShown={showLobbying}
                    setShowPopup={setLobbyingWizardVisibility}
                />
            }
            {showLegalSupport &&
                <LegalSupportWizard
                    organizationId={currentOrganization?.id}
                    organizationUrlName={currentOrganization?.details?.urlName}
                    isPopupShown={showLegalSupport}
                    setShowPopup={setLegalSupportPopupVisibility}
                />
            }
            {showAssetsManagmentWizard &&
                <AssetsManagementWizard
                    organizationId={currentOrganization?.id}
                    organizationUrlName={currentOrganization?.details?.urlName}
                    isPopupShown={showAssetsManagmentWizard}
                    setShowPopup={showAssetsManagmentPopup}
                />
            }
            {showLandlordWizard &&
                <LandLordWizard
                    organizationId={currentOrganization?.id}
                    organizationUrlName={currentOrganization?.details?.urlName}
                    isPopupShown={showLandlordWizard}
                    setShowPopup={showLandlordPopup}
                />
            }
            {showDisputeWizard &&
                <DisputeWizard
                    organizationId={currentOrganization?.id}
                    organizationUrlName={currentOrganization?.details?.urlName}
                    isPopupShown={showDisputeWizard}
                    setShowPopup={setDisputeWizardVisibility}
                />
            }
            {showTradeAssociationWizard &&
                <TradeAssociationWizard
                    organizationId={currentOrganization?.id}
                    organizationUrlName={currentOrganization?.details?.urlName}
                    isPopupShown={showTradeAssociationWizard}
                    setShowPopup={setTradeAssociationWizardVisibility}
                />
            }
            {showEApprovalWizard &&
                <EApprovalWizard
                    organizationId={currentOrganization?.id}
                    organizationUrlName={currentOrganization?.details?.urlName}
                    isPopupShown={showEApprovalWizard}
                    setShowPopup={showEApprovalPopup}
                />
            }
            {showPOAWizard &&
                <POAWizard
                    organizationId={currentOrganization?.id}
                    organizationUrlName={currentOrganization?.details?.urlName}
                    isPopupShown={showPOAWizard}
                    setShowPopup={setShowPOAWizard}
                />
            }
        </>
    );
};
