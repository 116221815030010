import { Dispatch } from 'redux';
import * as queryString from 'query-string-for-all';
import { get } from 'lodash';

import { Http } from '../tools/http';
import { action } from '../tools/reduxTools';
import { IState } from '../reducers';
import {
    ISingleCompany, IReferentialIdentitie, IReferentialContexts, EReferentialTypes, IListOfReferetnialTypes, INormReferentialItemsResult,
    IFullReferentialElement, INormReferentialItem, ISingleGroup, IManageUsefulLinkItem, ICreateUsefulLink,
    ISearchMyLinks, IListOfReferetnialTypesPayload, IPeopleSearch, IGetPeople, IGetPeopleAndGroupsPersona, ISingleGroupDataTableItem, ISingleGroupMembersDataTableItem,
    IGroupsAndPeopleItems,
    IGroupsAndPeope
} from '../entities/IAdmin';
import * as actionTypes from './actionTypes/adminTypes';
import { ECompanyGroupKey } from '../entities/ICompany';
import { IReferentialTranslation } from '../entities/ILabelTranslation';
import { ISearchResponse } from '../entities/IGlobal';
import { ISIngleLegalEntity } from '../entities/ILegalEntities';
import { formDataEncodeAndAppendFile } from '../tools/files';
import { IFacetsSearchResponse } from '../entities/IEntity';
import { EGroupResourceType } from '../entities/IPermissions';

export const getAllBars = (): Promise<{}> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/admin/bars`);
}) as any;

export const setNewBar = (Label: string): Promise<{}> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const body = {
        Name: [
            {
                Lcid: 1033,
                Label
            }
        ]
    };
    return http.apiPost(`/admin/bars`, body);
}) as any;

export const updateBar = (Label: string, id: string): Promise<{}> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const body = {
        Name: [
            {
                Lcid: 1033,
                Label
            }
        ]
    };
    return http.apiPut(`/admin/bars/${id}`, body);
}) as any;

export const editCompany = (body: ISingleCompany, id: string, context: string): Promise<{}> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiPut(`/admin/${context === 'organization' ? 'associations' : 'companies'}/${id}`, body);
}) as any;

export const getSingleCompany = (id: string, context: 'company' | 'organization'): Promise<{}> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/admin/${context === 'organization' ? 'associations' : 'companies'}/${id}`).then((company: ISingleCompany) => {
        dispatch({
            type: actionTypes.GET_SINGLE_COMPANY,
            company
        });
    });
}) as any;

export const createCompany = (company: ISingleCompany, context: string) => ((dispatch, getState, http: Http) => {
    return http.apiPost(`/admin/${context === 'organization' ? 'associations' : 'companies'}`, company)
        .then(response => {
            dispatch({
                type: actionTypes.SUCCESSFULL_ACTION_CREATE_COMPANY,
                company: response
            });
        })
        .catch(error => {
            dispatch({
                type: actionTypes.FAILED_ACTION_CREATE_COMPANY,
                error: get(JSON.parse(error), 'response.data.Message')
            });
        });
}) as any;

export const clearCompany = () => ({
    type: actionTypes.CLEAR_COMPANY
});

export const getFilteredCompanies = (filter: string = undefined, amount: number = 10, groupKey?: ECompanyGroupKey, onlyNoneReferenced?: boolean): Promise<ISIngleLegalEntity[]> => ((dispatch, getState, http: Http) => {
    const request = `/legal-entities/filtered?${filter ? `name=${encodeURIComponent(filter)}&` : ''}top=${amount}${groupKey ? `&groupKey=${groupKey}` : ''}&lcid=1033${onlyNoneReferenced ? `&onlyNoneReferenced=${onlyNoneReferenced}` : ''}`;
    return http.profilesApiGet(request, undefined, undefined, 15000);
}) as any;

export const getCompaniesByFilters = (name: string, filter: string): Promise<{ id: string, name: string, picture: string }[]> => ((dispatch, getState, http: Http) => {
    return http.apiGet(`/v2/companies/filtered?input=${name}&entityTypeKey=${filter}&top=9999`);
}) as any;

export const getAllOrganizations = (): Promise<{}> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/admin/associations`);
}) as any;

export const getAllSchools = (): Promise<{}> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/admin/schools`);
}) as any;

export const setNewSchool = (Name: string): Promise<{}> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const body = {
        Name
    };
    return http.apiPost(`/admin/schools`, body);
}) as any;

export const updateSchool = (Name: string, id: string): Promise<{}> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const body = {
        Name
    };
    return http.apiPut(`/admin/schools/${id}`, body);
}) as any;

export const getListofStakes = (referentialId: string) => action<Promise<INormReferentialItemsResult>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/referential-identities/${referentialId}/referentials?size=999999`);
});

interface IListElement {
    Name: string;
    id: string;
}

export const getReferentialListByType = (type: EReferentialTypes, payload: IListOfReferetnialTypesPayload) => action<Promise<IListOfReferetnialTypes>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { userLanguage } = getState().language;
    const queryParams = queryString.stringify(payload, { skipEmptyString: true });
    return http.apiGet(`/client-referentials/types/${type}?lcid=${userLanguage}${queryParams && `&${queryParams}`}`);
});

export const setReferentialByType = (name: string, type: EReferentialTypes) => action<Promise<IListElement>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const body = {
        Name: name,
        Type: type
    };

    return http.apiPost('/client-referentials', body);
});

export const updateReferentialByType = (id: string, name: string) => action<Promise<IListElement>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { userLanguage } = getState().language;
    const body = {
        Lcid: userLanguage,
        Name: name
    };
    return http.apiPut(`/client-referentials/${id}`, body);
});

export const updateReferentialIdentity = (referentialIdentityId: string, Name: string, isVisible?: boolean) => action<Promise<IReferentialIdentitie>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const body = {
        isVisible: true,
        Name
    };
    return http.profilesApiPut(`/referentials/identities/${referentialIdentityId}`, body);
});

export const getReferentialIdentities = (context: string) => action<Promise<IReferentialIdentitie[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.profilesApiGet(`/referentials/contexts/${context}/identities?size=9999`).then(res => res.items);
});

export const getReferentialsData = () => action<Promise<IReferentialContexts>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.profilesApiGet(`/referentials`);
});

export const getSingleReferential = (context: string, type: string) => action<Promise<{ id: string, name: { label: string, lcid: number }[] }[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { userLanguage } = getState().language;
    return http.profilesApiGet(`/referentials/contexts/${context}/types/${type}/elements/full?size=9999&lcid=${userLanguage}`).then(res => res.items);
});

export const getSingleReferentialById = (identityId: string, query?: string) => action<Promise<{ id: string, name: { label: string, lcid: number }[], parents: string[], children: string[] }[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { userLanguage } = getState().language;
    const queryParams = queryString.stringify({
        query,
        lcid: userLanguage
    });
    return http.profilesApiGet(`/referentials/identities/${identityId}/elements/full?${queryParams}`).then(res => res.items);
});

export const getFullReferentialElementsByIdentityId = (identityId: string, query = '', size = 9999, page = 0) => action<Promise<ISearchResponse<IFullReferentialElement>>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({
        ...(query && { query } || {}),
        size,
        page
    });
    return http.profilesApiGet(`/referentials/identities/${identityId}/elements/full?${queryParams}`);
});

export const updateRef = (id: string, body: IReferentialTranslation[]) => action<Promise<string>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.profilesApiPut(`/referentials/elements/${id}`, body);
});

export const updateStakeParent = (id: string, body: string[]) => action<Promise<string>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.profilesApiPut(`/referentials/elements/${id}/add-children`, body);
});

export const removeStakeParent = (id: string, body: string[]) => action<Promise<string>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.profilesApiPut(`/referentials/elements/${id}/remove-children`, body);
});

export const updateStakeRef = (referentialIdentityId: string, id: string, body: INormReferentialItem) => action<Promise<string>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiPut(`/referential-identities/${referentialIdentityId}/referentials/${id}`, body);
});

export const createRef = (identityId: string, body: IReferentialTranslation[], key?: string) => action<Promise<INormReferentialItem>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.profilesApiPost(`/referentials/identities/${identityId}/elements`, {
        key,
        name: body
    });
});
export const createStakeRef = (referentialId: string, body: INormReferentialItem) => action<Promise<INormReferentialItem>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiPost(`/referential-identities/${referentialId}/referentials`, body);
});

export const getPeople = (query: string = '') => action<Promise<IPeopleSearch[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {

    const { userLanguage } = getState().language;

    return http.profilesApiPost(`/users/search?lcid=${userLanguage}`, { search: query, hideCurrentUser: false }).then((results) => {
        return ((results && results.items) || []).map((profile: IGetPeople) => {
            const persona = {
                text: `${profile.firstName} ${profile.lastName}`,
                imageInitials: `${profile.firstName.slice(0, 1)}${profile.lastName.slice(0, 1)}`,
                imageUrl: profile.picture,
                secondaryText: [profile.jobTitle, profile.cabinet].filter(val => val).join(' - '),
                id: profile.id,
                originalResult: profile
            };
            return persona;
        });
    });
});

export const getPeopleAndGroups = (query: string = '', type?: number) => action<Promise<any>>((dispatch, getState: () => IState, http: Http) => {
    return http.permissionsApiPost(`/groups/search-items?query=${query}&size=10`, { types: type !== undefined ? [type] : [0, 1] }).then((results) => {
        return ((results && results.items) || []).map((profile: { id: string; name: string; picture: string; type: 'Group' | 'Personal', emailWithoutAtSign: string }) => {
            const persona: IGetPeopleAndGroupsPersona = {
                text: profile.name,
                imageInitials: '',
                imageUrl: profile.picture,
                secondaryText: '',
                id: profile.id,
                originalResult: profile
            };

            return persona;
        });
    });
});

export const createNewGroup = (body: ISingleGroup) => action<Promise<ISingleGroup>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.permissionsApiPost(`/groups`, body);
});
export const updateGroup = (body: ISingleGroup, groupId: string) => action<Promise<ISingleGroup>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.permissionsApiPut(`/groups/${groupId}`, body);
});
export const getAllGroups = (page: number, query: string = '') => action<Promise<ISearchResponse<ISingleGroup>>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.permissionsApiGet(`/groups/search?size=20&query=${query}&page=${page}`);
});
export const getSingleGroup = (groupId: string) => action<Promise<ISingleGroup>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.permissionsApiGet(`/groups/${groupId}`);
});

export const updateGroupInformation = (groupId: string, body: { name: string, description: string }) => action<Promise<ISingleGroup>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.permissionsApiPut(`/groups/${groupId}`, body);
});

export const removeGroup = (groupId: string) => action<Promise<ISingleGroup>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.permissionsApiDelete(`/groups/${groupId}`);
});
export const getPlatformData = () => action<Promise<{ id: string }>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/platform-resource`);
});
export const getUsefulLinks = (page?: number, size?: number) => action<Promise<ISearchMyLinks>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({
        page,
        size
    });
    return http.cmsApiGet(`/useful-links/my?${queryParams}`);
});
export const getManageableUsefulLinks = () => action<Promise<IManageUsefulLinkItem[]>>((dispatch, getState, http) => {
    return http.cmsApiGet(`/useful-links/all`); // TODO: add pagination when API is ready
});
export const setUsefulLinkOrder = (id: string, order: number) => action<Promise<IManageUsefulLinkItem>>((dispatch, getState, http) => {
    return http.cmsApiPut(`/useful-links/${id}/change-order?order=${order}`);
});
export const createUsefulLink = (id: string, data: ICreateUsefulLink) => action<Promise<IManageUsefulLinkItem>>((dispatch, getState, http) => {
    return http.cmsApiPost(`/useful-links/${id}`, data);
});
export const deleteUsefulLink = (id: string) => action<Promise<IManageUsefulLinkItem>>((dispatch, getState, http) => {
    return http.cmsApiDelete(`/useful-links/${id}`);
});
export const setUsefulLinkPicture = (id: string, file: File) => action<Promise<string>>((dispatch, getState, http) => {
    const form = new FormData();
    formDataEncodeAndAppendFile(form, 'file', file);
    return http.cmsApiPost(`/useful-links/${id}/upload-picture`, form);
});
export const getGroupsAndPeople = (query: string, resourceChildId: string, resourceType: EGroupResourceType, size?: number) => action<Promise<IGroupsAndPeopleItems[]>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({
        pageSize: size || 10,
        resourceType,
        query,
        resourceChildId
    });
    return http.permissionsApiGet(`/groups/system-groups-search?${queryParams}`, undefined, undefined, 15000);
});

export const getWorkspaceMembersGroupsAndPeople = (query: string, organizationId: string, size: number = 10) => action<Promise<IGroupsAndPeope>>((dispatch, getState, http) => {
    return http.permissionsApiPost(`/groups/search-items?size=${size}&query=${query}`, { organizationId }, undefined, 15000);
});

export const updateUsefulLink = (id: string, data: ICreateUsefulLink) => action<Promise<IManageUsefulLinkItem>>((dispatch, getState, http) => {
    return http.cmsApiPut(`/useful-links/${id}`, data);
});

export const getCTAPermissions = () => action<Promise<string[]>>((dispatch, getState, http) => {
    return http.permissionsApiGet(`/resources/types/organization/call-to-action/allowed-actions`);
});

export const getGroupsDataTable = (filters: any, page?: number) => action<Promise<IFacetsSearchResponse<ISingleGroupDataTableItem, Record<string, any[]>>>>(async (dispatch, getState, http) => {
    return http.permissionsApiPost(`/groups/as-datatable?page=${page || 0}&pageSize=50`, { ...filters, query: filters.query?.[0] });
});

export const getSingleGroupMembersDataTable = (groupId: string, filters: any, page?: number) => action<Promise<IFacetsSearchResponse<ISingleGroupMembersDataTableItem, Record<string, any[]>>>>(async (dispatch, getState, http) => {
    return http.permissionsApiPost(`/groups/${groupId}/members/as-datatable?page=${page || 0}&pageSize=50`, { ...filters, query: filters.query?.[0] });
});
