import React, { useCallback, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';

import { getProfileById } from '../../../../actions/profileActions';
import { IFormData } from '../../../../entities/IDeclaration';
import { getCompanyById } from '../../../../actions/legalEntitiesActions';
import { EProfileType, IUserBrief } from '../../../../entities/IGlobal';
import { ISIngleLegalEntity } from '../../../../entities/ILegalEntities';
import { TextComponent } from '../../../Common/Inputs/TextComponent';
import { UsersPicker } from '../../../Common/Pickers/UsersPicker';
import { LegalEntitiesPicker } from '../../../Common/Pickers/LegalEntitiesPicker';
import { LabelWithEllipsis } from '../../../Common/Label/Label';
import { EAlertType, EAlertTypeSchema } from '../../../../entities/IAlert';
import { MandatoryFieldStar } from '../../../Common/MandatoryFieldStar/MandatoryFieldStar';
import { colorStack } from '../../../../styleHelpers/colors';
import { Alert } from '../../../Common/Alert/Alert';
import IntlMessage, { useIntlMessage } from '../../../Common/IntlMessage';

type GetCompanyById = ReturnType<typeof getCompanyById>;

const Wrapper = styled.div`
    width: 100%;
    display: block;
    margin: 0 auto;
`;

const InnerWrapper = styled.div`
    position: relative;
    background: white;
    border-radius: 4px;
    margin-bottom: 26px;
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const InputSection = styled.div<{ row?: boolean; text?: boolean; radio?: boolean }>`
    padding: 20px 28px;
    width: 50%;
    ${props => props.row && css`
        display: flex;
        flex-direction: row;
    `}
    ${props => props.text && css`
        display: inline-flex;
        align-items: center;
    `}
    ${props => props.radio && css`
        label {
            margin-right: 29px;
        }
    `}
`;

const Title = styled.div`
    color: ${colorStack.content};
    margin: 0 0 2rem 0;
    sup {
        color: ${colorStack.red};
    }
`;

interface IProps {
    legalEntityId: string;
}

export const YourInformation = (props: IProps) => {
    const dispatch = useDispatch();
    const { intlFormatMessage } = useIntlMessage();
    const { setFieldValue, values, errors } = useFormikContext<IFormData>();
    const [inputBlur, setInputBlur] = useState({
        fullName: false,
        jobTitle: false,
        legalEntity: false,
        country: false,
        manager: false
    });

    useEffect(() => {
        setFieldValue('step', 5);
        setFieldValue('tableLoaded', false);
        if (props.legalEntityId && !values.formData.information?.legalEntity?.id) {
            dispatch<GetCompanyById>(getCompanyById(props.legalEntityId)).then(res => {
                setFieldValue('formData.information.legalEntity', res);
            });
        }
    }, []);

    const fillUserInformation = useCallback(async (user: IUserBrief, manager?: boolean) => {
        if (user || (user && manager)) {
            const result = await dispatch(getProfileById(user?.id));
            const entityInfo = result.cabinet ? await dispatch(getCompanyById(result.cabinet?.id)) : {};
            !manager
                ? setFieldValue(`formData.information`, {
                    fullName: `${result?.firstName} ${result?.lastName}`,
                    firstName: result?.firstName,
                    picture: result?.picture,
                    lastName: result?.lastName,
                    jobTitle: result?.jobTitle || '',
                    email: result?.emailContact || '',
                    userId: result?.id,
                    country: result?.cabinet?.address?.country || '',
                    manager: result?.manager || { firstName: '', lastName: '' },
                    legalEntity: { ...entityInfo, type: EProfileType.Company }
                })
                :
                setFieldValue(`formData.information.manager`, (user && result) ? {
                    firstName: result.firstName || '',
                    lastName: result.lastName || '',
                    jobTitle: result.jobTitle,
                    picture: result.picture,
                    cabinet: result.cabinet?.name,
                    id: result.id
                    // tslint:disable-next-line:no-null-keyword
                } : null);
        } else {
            !manager
                ? setFieldValue(`formData.information`, {
                    fullName: '',
                    firstName: '',
                    picture: '',
                    lastName: '',
                    jobTitle: '',
                    email: '',
                    // tslint:disable-next-line:no-null-keyword
                    userId: null,
                    country: '',
                    // tslint:disable-next-line:no-null-keyword
                    manager: null,
                    // tslint:disable-next-line:no-null-keyword
                    legalEntity: null
                })
                :
                // tslint:disable-next-line:no-null-keyword
                setFieldValue(`formData.information.manager`, null);
        }
    }, [values.formData.information]);

    const fillLegalEntityInformation = (data: ISIngleLegalEntity) => {
        // tslint:disable-next-line:no-null-keyword
        setFieldValue(`formData.information.legalEntity`, data || null);
    };

    const onChangeSelectedEntity = useCallback((element: { key: string; text: string; data: ISIngleLegalEntity; }[]) => {
        fillLegalEntityInformation(element?.[0]?.data);
    }, []);

    const fillNameInputOrUserInformation = useCallback((user: IUserBrief) => {
        // tslint:disable-next-line:no-null-keyword
        fillUserInformation(user);
    }, [values.formData.information]);

    const fillManagerInformation = useCallback((user: IUserBrief) => {
        // tslint:disable-next-line:no-null-keyword
        fillUserInformation(user, true);
    }, [values.formData.information]);

    const onChangeSelected = useCallback(async (element: { key: string; text: string; data: IUserBrief; }[]) => {
        fillNameInputOrUserInformation(element?.[0]?.data);
    }, []);

    const onChangeManagerSelected = useCallback((element: { key: string; text: string; data: IUserBrief; }[]) => {
        fillManagerInformation(element?.length > 0 ? element?.[0]?.data : undefined);
    }, []);

    const handleBlur = useCallback((name: string) => {
        setInputBlur({
            ...inputBlur,
            [name]: true
        });
    }, [inputBlur]);

    return (
        <Wrapper>
            <InnerWrapper>
                <Row>
                    <InputSection className="lc-st-gift-invitation-wizard-step5-fullName">
                        <UsersPicker
                            label={intlFormatMessage({ id: 'giftinvitation.beneficiary.fullName' })}
                            value={values.formData?.information?.userId ? [{
                                key: values.formData.information.userId,
                                text: `${values.formData.information.firstName} ${values.formData.information.lastName}`,
                                data: {
                                    firstName: values.formData.information.firstName,
                                    lastName: values.formData.information.lastName,
                                    jobTitle: values.formData.information.jobTitle,
                                    picture: values.formData.information.picture,
                                    cabinet: '',
                                    id: values.formData.information.userId
                                }
                            }] : undefined}
                            onSelectElement={onChangeSelected}
                            required
                        />
                    </InputSection>
                    <InputSection className="lc-st-gift-invitation-wizard-step5-jobTitle">
                        <TextComponent
                            label={intlFormatMessage({ id: 'giftinvitation.beneficiary.jobTitle' })}
                            value={values.formData.information.jobTitle}
                            error={inputBlur.jobTitle && !!errors?.formData?.information?.jobTitle}
                            onChange={(value: string) => setFieldValue('formData.information.jobTitle', value)}
                            onBlur={() => handleBlur('jobTitle')}
                        />
                    </InputSection>
                </Row>
                <Row>
                    <InputSection className="lc-st-gift-invitation-wizard-step5-legalEntity">
                        <LegalEntitiesPicker
                            label={intlFormatMessage({ id: 'giftinvitation.beneficiary.legalEntity' })}
                            value={values.formData?.information?.legalEntity?.id ? [{
                                key: values.formData?.information?.legalEntity?.id,
                                text: values.formData?.information?.legalEntity?.name,
                                data: values.formData?.information?.legalEntity
                            }] : undefined}
                            required
                            onSelectElement={onChangeSelectedEntity}
                            filteredCompanies
                            hideButtons
                        />
                    </InputSection>
                    <InputSection className="lc-st-gift-invitation-wizard-step5-manager">
                        <UsersPicker
                            label={intlFormatMessage({ id: 'giftinvitation.beneficiary.manager' })}
                            required
                            value={values.formData?.information?.manager?.id ? [{
                                key: values.formData.information.manager.id || '',
                                text: `${values.formData.information.manager.firstName} ${values.formData.information.manager.lastName}`,
                                data: {
                                    firstName: values.formData.information.manager.firstName || '',
                                    lastName: values.formData.information.manager.lastName || '',
                                    jobTitle: values.formData.information.manager.jobTitle || '',
                                    picture: values.formData.information.manager.picture || '',
                                    cabinet: values.formData.information.manager.cabinet || '',
                                    id: values.formData.information.manager.id || ''
                                }
                            }] : undefined}
                            onSelectElement={onChangeManagerSelected}
                        />
                    </InputSection>
                </Row>
            </InnerWrapper>
            <Title>
                <MandatoryFieldStar />
                <IntlMessage id="giftinvitation.requiredInfo" />
            </Title>
            <Alert
                withoutClose
                visible
                alertId=""
                withoutProgress
                typeSchema={EAlertTypeSchema.SECONDARY}
                type={EAlertType.INFO}
            >
                <span dangerouslySetInnerHTML={{ __html: intlFormatMessage({ id: 'giftinvitation.requiredInfo.text' }) }} />
            </Alert>
            <Alert
                withoutClose
                visible
                alertId=""
                withoutProgress
                typeSchema={EAlertTypeSchema.SECONDARY}
                type={EAlertType.DEFAULT}
            >
                <span dangerouslySetInnerHTML={{ __html: intlFormatMessage({ id: 'giftinvitation.popup.grayInfoBox.text' }) }} />
            </Alert>
        </Wrapper>
    );
};
