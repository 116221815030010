import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { EOverviewColumnSubType } from '../../../../entities/IDatagrid';
import { colorStack } from '../../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../../styleHelpers/fontSizes';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    img {
        width: 50px;
        height: 50px;
        border-radius: 8px;
    }
`;

const WrapperLink = styled(Link)`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: 500;
    &:hover {
        text-decoration: none;
    }
`;

const WrapperNoLink = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: 500;
`;

const Info = styled.span<{ highlight?: boolean }>`
    ${fontSizeAndHeight[13]};
    font-weight: ${({ highlight }) => highlight ? 500 : 400};
    & + & {
        padding-inline-start: 8px;
        margin-inline-start: 8px;
        border-inline-start: 2px ${colorStack.ligthGrey} solid;
    }
`;

const Title = styled.span`
    ${fontSizeAndHeight[20]};
`;

interface IProps {
    businessId: number;
    templateName: string;
    clusterId?: string;
    name?: string;
    organizationUrlName?: string;
    title: string;
    externalLink?: string;
    pictureUrl?: string;
    vieMode?: boolean;
}

const OverviewType = (props: IProps) => {
    return (
        <Wrapper>
            {props.pictureUrl && <img src={props.pictureUrl} alt="" />}
            {props.vieMode ? (
                <WrapperNoLink>
                    <span>
                        {props.name && <Info highlight>{props.name}</Info>}
                        {props.businessId ? <Info highlight>#{props.businessId}</Info> : ''}
                        {props.templateName && <Info>{props.templateName}</Info>}
                    </span>
                    <Title>{props.title}</Title>
                </WrapperNoLink>
            ) : (
                <WrapperLink to={props.externalLink ? props.externalLink : `/orgs/${props.organizationUrlName}/cluster/${props.clusterId}/overview`}>
                    <span>
                        {props.name && <Info highlight>{props.name}</Info>}
                        {props.businessId ? <Info highlight>#{props.businessId}</Info> : ''}
                        {props.templateName && <Info>{props.templateName}</Info>}
                    </span>
                    <Title>{props.title}</Title>
                </WrapperLink>
            )}
        </Wrapper>
    );
};

export interface IOverviewProps {
    businessId: number;
    clusterId?: string;
    clusterName?: string;
    organizationName?: string;
    organizationUrlName?: string;
    clusterTemplateName?: string;
    organizationTemplateName?: string;
    externalLink?: string;
    pictureUrl?: string;
    subType: EOverviewColumnSubType;
    viewMode?: boolean;
}

const OverviewOrganization = (props: IOverviewProps) => {
    return (
        <OverviewType
            vieMode={props.viewMode}
            businessId={props.businessId}
            templateName={props.organizationTemplateName}
            title={props.organizationName}
            externalLink={props.externalLink}
            pictureUrl={props.pictureUrl}
        />
    );
};

const OverviewCluster = (props: IOverviewProps) => {
    return (
        <OverviewType
            vieMode={props.viewMode}
            businessId={props.businessId}
            templateName={props.clusterTemplateName}
            clusterId={props.clusterId}
            name={props.organizationName}
            organizationUrlName={props.organizationUrlName}
            title={props.clusterName}
            externalLink={props.externalLink}
            pictureUrl={props.pictureUrl}
        />
    );
};

export const Overview = (props: IOverviewProps) => {
    return (
        <>
            {props.subType === EOverviewColumnSubType.organization && (
                <OverviewOrganization {...props} />
            )}
            {props.subType === EOverviewColumnSubType.cluster && (
                <OverviewCluster {...props} />
            )}
        </>
    );
};