import * as actionTypes from '../actions/actionTypes/datatableTypes';

export enum EBackgroundDatatableName {
    ClausierPOA = 'ClausierPOA',
    ClausierPOACluster = 'ClausierPOACluster',
    Clausier = 'Clausier',
    FAQ = 'FAQ',
    QA = 'QA',
    SL = 'SL'
}

export interface IDatatableReducer {
    backgroundDatatablesIds: {
        [datatableName in keyof typeof EBackgroundDatatableName]?: string[];
    };
}

const defaultState = (): IDatatableReducer => ({ backgroundDatatablesIds: {} });

export default (state = defaultState(), action): IDatatableReducer => {
    switch (action.type) {
        case actionTypes.SET_BACKGROUND_DATATABLE_IDS: {
            const payload: actionTypes.IDatatableTypes['SET_BACKGROUND_DATATABLE_IDS'] = action;
            return {
                ...state,
                backgroundDatatablesIds: {
                    ...state.backgroundDatatablesIds,
                    [payload.datatableName]: payload.backgroundDatatableIds
                }
            };
        }
        case actionTypes.ADD_BACKGROUND_DATATABLE_ID: {
            const payload: actionTypes.IDatatableTypes['ADD_BACKGROUND_DATATABLE_ID'] = action;
            return {
                ...state,
                backgroundDatatablesIds: {
                    ...state.backgroundDatatablesIds,
                    [payload.datatableName]: [payload.backgroundDatatableId].concat(state.backgroundDatatablesIds[payload.datatableName] || [])
                }
            };
        }
        case actionTypes.CLEAR_BACKGROUND_DATATABLE: {
            const newBackgroundDatatablesIds = { ...state.backgroundDatatablesIds };
            const payload: actionTypes.IDatatableTypes['CLEAR_BACKGROUND_DATATABLE'] = action;

            delete newBackgroundDatatablesIds[payload.datatableName];

            return {
                ...state,
                backgroundDatatablesIds: newBackgroundDatatablesIds
            };
        }
        case actionTypes.CLEAR_ALL_BACKGROUND_DATATABLES: {
            return {
                ...state,
                backgroundDatatablesIds: {}
            };
        }
        default: {
            return state;
        }
    }
};