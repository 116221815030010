import React, { FC, useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeBranch } from '@fortawesome/pro-light-svg-icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getVersions, getDocumentationStyles, createApproval, getApprovalRequest, abandonBranch, downloadWordDocument, reactivateBranch } from '../../../actions/sogeActions';
import { ISogeReducer } from '../../../reducers/sogeReducer';
import { IContextReducer } from '../../../reducers/contextReducer';
import { IState } from '../../../reducers';
import { IValidator, ICreateApproval, IApprovalRequest, IIValidatorPayload, EBranchNotificationType } from '../../../entities/ISoge';
import { getErrorMessage } from '../../../tools/errorTools';
import { useBranchContent, useSogeCompare, useVersionName, useRelatedBranches, useConsistencyCheck, useBranchWithoutContent, useSogeFeatureBranchCompare, useBranchNotification, useNormsConfiguration } from '../../../tools/legalDocTools/sogeHooks';
import { useAlert } from '../../../tools/hooks';
import { EAlertType } from '../../../entities/IAlert';
import { IBranchCompareRouteProps } from '../../../entities/LegalDoc/ILegalDocRoute';
import { languageIcons } from '../../../tools/languageTools';
import { getBranchUrl, getSogeApprovalUrl, getNormsAuditTrailUrl, getSogeCreateApprovalUrl } from '../../../tools/legalDocTools/sogeTools';
import { typed } from '../../../tools/generalTools';
import { SogePageWrapper } from '../SogePageWrapper';
import { WhiteBox } from '../../Common/WhiteBox/WhiteBox';
import { PageHeader } from '../Components/PageHeader';
import { ApprovalRequestForm } from '../Branches/ApprovalRequestForm';
import { ApproveForm } from '../Branches/ApproveForm';
import { Loader } from '../../Common/Loader/Loader';
import { StructureLevel } from '../LegalDocument/StructureLevel';
import { BranchPicker } from '../Components/BranchPicker';
import { RelatedRequestPickerPopup } from '../Components/RequestPicker';
import { BranchDescription } from './BranchDescription';
import { ContentToolbar } from './ContentToolbar';
import { BranchActions } from './BranchActions';
import { PreviewHeader } from './PreviewHeader';
import { BranchDiff } from './BranchDiff';
import { StatusTag } from '../Components/StatusTag';
import { RelatedBranches } from './RelatedBranches';
import { Tabs } from '../../Common/Tabs';
import { LinkButton } from '../../Common/Buttons/Button';
import { Popup } from '../../Common/Popup/Popup';
import { colorStack } from '../../../styleHelpers/colors';
import IntlMessage from '../../Common/IntlMessage';

type getDocumentationVersions = ReturnType<typeof getVersions>;
type CreateApproval = ReturnType<typeof createApproval>;
type GetRequest = ReturnType<typeof getApprovalRequest>;
type AbandonBranch = ReturnType<typeof abandonBranch>;

const ModifyBox = styled(WhiteBox)`
    padding: 1.5rem;
`;

const Language = styled.img`
    height: 1.2rem;
    vertical-align: middle;
    margin: 0 .5rem;
`;

const StatusAndLang = styled.div`
    display: flex;
    align-items: center;
`;

interface IBranchCompareProps extends IBranchCompareRouteProps {}

type IBranchCompareSelectors = ISogeReducer & IContextReducer;

export const Component: FC<React.PropsWithChildren<IBranchCompareProps>> = ({ requestApprovalMode, approveMode }) => {
    const { organization, versionId, branchId, documentElementCommonId, requestId } = useParams();
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const history = useNavigate();
    const { documentationId, contextOrganizations, sgStylesContainer } = useSelector<IState, IBranchCompareSelectors>(state => ({
        ...state.soge,
        ...state.context
    }));
    const { isLanguageConsistencyOn } = useNormsConfiguration();
    const [branchPickerVisible, setBranchPickerVisible] = useState<boolean>(false);
    const [currentApproval, setCurrentApproval] = useState<IApprovalRequest>(undefined);
    const [requestPicker, setRequestPicker] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [selectedApprovalBranchId, setSeletedApprovalBranchId] = useState<string>(undefined);
    const branchesParamFromUrl = params.get('branches');
    const queryBranches = useMemo(() => typeof branchesParamFromUrl === 'string' ? [branchesParamFromUrl] : branchesParamFromUrl, [branchesParamFromUrl]);
    const [checkStructure, setCheckStructure] = useState<boolean>(false);
    const [isConsistencyPopupEnabled, setIsConsistencyPopupEnabled] = useState<boolean>(false);
    const [isBranchRejectInitialized, setIsBranchRejectInitialized] = useState<boolean>(false);
    const [isCheckMessageVisible, setIsCheckMessagevisible] = useState<boolean>(false);

    const approvalBranchIds = useMemo(() => {
        return (approveMode && currentApproval?.sourceBranchIds) || (requestApprovalMode && queryBranches);
    }, [requestApprovalMode, approveMode, queryBranches, currentApproval]);

    // IMPORTANT: FIXME:
    // using useRelatedBranches instead of useElementBranches may break documents without language consistency
    // to be investigated and fixed as needed.
    const { activeRelatedBranches, allRelatedBranches, currentApprovalBranches } = useRelatedBranches(documentationId, versionId, selectedApprovalBranchId || branchId, approvalBranchIds);
    const { consistencyFullMatch, isCheckEnabled, unmatchedElements, launchConsistencyCheck } = useConsistencyCheck(documentationId, versionId, allRelatedBranches);

    const {
        branch,
        updateBranch,
        error,
        setError,
        loadBranch
    } = useBranchContent(documentationId, versionId, documentElementCommonId, selectedApprovalBranchId || branchId);

    const {branchProps, loadBranchWithoutContent} = useBranchWithoutContent(documentationId, versionId, branchId);
    const {featureBranchesResponse} = useSogeFeatureBranchCompare(documentationId, versionId, branchId || selectedApprovalBranchId);

    const versionName = useVersionName(versionId, branch?.lcid);

    const { comparisonState, versionsState, comparedBranchVersion, highlightChanges, sideBySide, setVersions, toggleHighlightChanges, toggleSideBySide, onChangeCompareBranchVersion } = useSogeCompare('feature', organization, documentationId, {
        currentVersionId: versionId,
        currentLcid: branch?.lcid,
        currentElementCommonId: documentElementCommonId,
        featureBranchesResponse
    }, true, setError);

    const {notificationObject, isBranchListUpdate} = useBranchNotification();
    const [isConsistencyCheckEnd, setIsConsistencyCheckEnd] = useState<boolean>(false);

    useEffect(() => {
        setSeletedApprovalBranchId(approvalBranchIds?.[0]?.split(',')[0]);
    }, [approvalBranchIds]);

    const getCurrentApprovalRequest = useCallback(() => {
        approveMode && documentationId && versionId && requestId && dispatch<GetRequest>(getApprovalRequest(documentationId, versionId, requestId))
            .then(approvalRequest => {
                setCurrentApproval(approvalRequest);
            });
    }, [documentationId, versionId, approveMode, requestId]);

    useEffect(() => {
        getCurrentApprovalRequest();
    }, [getCurrentApprovalRequest]);

    useEffect(() => {
        documentationId && dispatch<getDocumentationVersions>(getVersions(documentationId)).then(response => {
            const currentVersion = response.find(version => version.versionId === versionId && version.lcid === branch?.lcid);
            if (!!currentVersion?.id) {
                !sgStylesContainer?.[currentVersion.id]?.styles && dispatch(getDocumentationStyles(documentationId, currentVersion.id));
            }
            setVersions(response);
        });
    }, [documentationId, versionId, branch?.lcid, setVersions]);

    const onSaveDescription = useCallback((description: string) => {
        updateBranch(branchState => ({ ...branchState, description }));
    }, []);

    const onArticleDetailsClick = useCallback((articleId: string) => {
        history(`/orgs/${organization}/documentation/versions/${versionId}/branches/${documentElementCommonId}/${selectedApprovalBranchId || branchId}/article/${articleId}`);
    }, [organization, versionId, branchId, selectedApprovalBranchId, documentElementCommonId]);

    const checkStructureBetweenBranches = useCallback (async () => {
        setCheckStructure(true);
        await launchConsistencyCheck()?.then(() => {
            setIsConsistencyCheckEnd(true);
            if (allRelatedBranches?.length > 1 ) {
                setIsCheckMessagevisible(true);
                setCheckStructure(false);
            } else {
                setCheckStructure(false);
                setIsCheckMessagevisible(false);
            }

        });
    }, [activeRelatedBranches, consistencyFullMatch, isCheckEnabled, launchConsistencyCheck]);

    useEffect(() => {
        if (isConsistencyCheckEnd === true) {
            if (consistencyFullMatch === false) {
                setIsConsistencyPopupEnabled(true);
                setIsConsistencyCheckEnd(false);
            }
        }
    }, [isConsistencyCheckEnd, consistencyFullMatch, setIsConsistencyCheckEnd]);

    const showBranchPicker = useCallback(() => {
        setBranchPickerVisible(visible => !visible);
        if (!branchPickerVisible && isLanguageConsistencyOn) {
            checkStructureBetweenBranches();
        }
    }, [branchPickerVisible, isLanguageConsistencyOn, checkStructureBetweenBranches]);

    const toggleRequestPicker = useCallback(() => {
        setRequestPicker(state => !state);
    }, []);

    const onStartApproval = useCallback((branchesForApproval: string[]) => {
        setBranchPickerVisible(false);
        history(`${getSogeCreateApprovalUrl(organization, versionId, documentElementCommonId)}?branches=${branchesForApproval.join()}`);
    }, [organization, versionId, documentElementCommonId]);

    const onRedirectToApproval = useCallback((approvelRequestId: string) => {
        setRequestPicker(false);
        history(getSogeApprovalUrl(organization, versionId, documentElementCommonId, approvelRequestId));
    }, [organization, versionId, documentElementCommonId]);

    const onCreateApproval = useCallback((title: string, description: string, approvers: IValidator[]) => {
        const approval: ICreateApproval = {
            name: title,
            description,
            approvers: typed<IIValidatorPayload[]>(approvers.map(validator => validator.id.match('invite') ? ({
                emailAddress: validator.emailContact,
                isMandatory: validator.isMandatory
            }) : ({
                id: validator.id,
                isMandatory: validator.isMandatory
            }))),
            branchIds: approvalBranchIds
        };
        return dispatch<CreateApproval>(createApproval(documentationId, versionId, approval))
            .then(createdApproval => {
                setCurrentApproval(createdApproval);
                onRedirectToApproval(createdApproval.id);
            });
    }, [documentationId, versionId, approvalBranchIds, onRedirectToApproval]);

    const onBranchAbandon = useCallback(() => {
        !!branchProps?.id && dispatch<AbandonBranch>(abandonBranch(documentationId, versionId, branchProps.id, branchProps.lcid))
            .then(() => {
                setIsBranchRejectInitialized(true);
            })
            .catch(abandonBranchError => {
                addAlert(getErrorMessage(abandonBranchError), EAlertType.ERROR);
            });
    }, [branchProps?.id, loadBranch]);

    const onDownloadClick = useCallback(async () => {
        setIsDownloading(true);
        try {
            await dispatch(downloadWordDocument(documentationId, versionId, branch?.relatedItemCrossId, branch.contents?.parents?.[0]?.commonLevelId, branch?.lcid, selectedApprovalBranchId || branch?.id, `${branch?.name}.docx`, 'management', versionName));
        } catch (error) {
            addAlert(getErrorMessage(error), EAlertType.ERROR);
        }
        setIsDownloading(false);
    }, [versionId, documentationId, branch, branch?.relatedItemCrossId, versionName, selectedApprovalBranchId]);

    const onReactivateClick = useCallback(async () => {
        try {
            await dispatch(reactivateBranch(documentationId, versionId, branchProps?.id, branchProps?.lcid));
            loadBranch();
        } catch (error) {
            addAlert(getErrorMessage(error), EAlertType.ERROR);
        }
    }, [documentationId, versionId, branchProps?.lcid, branchProps?.id, loadBranch]);

    const onMinorChangeChanged = useCallback((isMinorChange: boolean) => {
        updateBranch(branchState => ({ ...branchState, isMinorChange }));
    }, [updateBranch]);

    const onPreviewTabChange = useCallback((index: number) => {
        setSeletedApprovalBranchId(approvalBranchIds?.[index]);
    }, [approvalBranchIds]);

    const onCloseBranchRejectionPopup = useCallback(() => {
        history(`/orgs/${organization}/documentation/versions/${versionId}/branches`);
    }, []);

    useEffect(() => {
        if (isBranchListUpdate === true) {
            loadBranchWithoutContent();
        }
        (notificationObject?.key === EBranchNotificationType.CommitAmendmentRequestSucceeded) && loadBranch();
    }, [isBranchListUpdate, notificationObject, loadBranchWithoutContent]);

    return (
        <SogePageWrapper organizationUrlName={organization} editVersionId={versionId} hideVersion hideLang editMode editPage="branches">
            {branchProps && !requestApprovalMode && !approveMode && (
                <PageHeader
                    headerText={(
                        <>
                            <FontAwesomeIcon icon={faCodeBranch} />
                            <span>{branchProps?.name}</span>
                        </>
                    )}
                    rightCol={(
                        <StatusAndLang>
                            {branchProps?.lcid && (
                                <Language src={languageIcons[branchProps.lcid]} />
                            )}
                            {typeof branchProps?.status !== 'undefined' &&  (
                                <StatusTag noBreak branchStatus={branchProps.status} />
                            )}
                        </StatusAndLang>
                    )}
                />
            )}

            {!(requestApprovalMode || approveMode) && (
                <RelatedBranches branch={branchProps} allRelatedBranches={allRelatedBranches} organizationUrlName={organization} versionId={versionId} />
            )}

            {requestApprovalMode && (
                <ApprovalRequestForm
                    onCancel={() => history(getBranchUrl(organization, versionId, documentElementCommonId, selectedApprovalBranchId))}
                    branches={currentApprovalBranches}
                    versionName={versionName}
                    organizationId={contextOrganizations?.byUrl?.[organization]?.id}
                    versionId={versionId}
                    documentationId={documentationId}
                    onCreateApproval={onCreateApproval}
                    organizationUrlName={organization}
                />
            )}

            {(approveMode && currentApproval) && (
                <ApproveForm
                    branches={currentApprovalBranches}
                    versionName={versionName}
                    approval={currentApproval}
                    versionId={versionId}
                    documentationId={documentationId}
                    updateView={getCurrentApprovalRequest}
                    organizationId={contextOrganizations?.byUrl?.[organization]?.id}
                    organizationUrlName={organization}
                    unmatchedElements={unmatchedElements}
                    branchId={branchId || selectedApprovalBranchId}
                    allRelatedBranches={allRelatedBranches}
                    consistencyFullMatch={isLanguageConsistencyOn && consistencyFullMatch}
                    isLanguageConsistencyOn={isLanguageConsistencyOn}
                    checkStructureBetweenBranches={launchConsistencyCheck}
                />
            )}

            {branchPickerVisible &&
                <BranchPicker
                    availableBranches={activeRelatedBranches || []}
                    loading={!allRelatedBranches}
                    onCancel={showBranchPicker}
                    onSelect={onStartApproval}
                    checkStructure={checkStructure}
                    isCheckMessageVisible={isCheckMessageVisible}
                    currentBranchId={branch?.id}
                    consistencyFullMatch={consistencyFullMatch}
                    isLanguageConsistencyOn={isLanguageConsistencyOn}
                />
            }

            {requestPicker && (
                <RelatedRequestPickerPopup
                    documentationId={documentationId}
                    versionId={versionId}
                    branchPullRequestIds={branch?.pullRequestIds || []}
                    onCancel={toggleRequestPicker}
                    onRedirect={onRedirectToApproval}
                />
            )}

            {(requestApprovalMode || approveMode) && (
                <Tabs
                    scrollMode
                    forceTabById={selectedApprovalBranchId}
                    onTabChange={onPreviewTabChange}
                    tabs={currentApprovalBranches.map(approvalBranch => ({
                        title: approvalBranch.name,
                        id: approvalBranch.id,
                        tab: <></>
                    }))}
                />
            )}

            <ModifyBox marginBottom>
                {branchProps && !requestApprovalMode && !approveMode && (
                    <BranchActions
                        organizationUrlName={organization}
                        versionId={versionId}
                        documentElementCommonId={documentElementCommonId}
                        branchId={branchId || selectedApprovalBranchId}
                        branch={branchProps}
                        showRequestPicker={toggleRequestPicker}
                        showBranchPicker={showBranchPicker}
                        onBranchAbandon={onBranchAbandon}
                        onReactivateClick={onReactivateClick}
                        onMinorChangeChanged={onMinorChangeChanged}
                        notificationObject={notificationObject}
                    />
                )}
            </ModifyBox>

            <ModifyBox marginBottom>
                <IntlMessage id="norms.modificationDetails" />
                <BranchDescription
                    branch={branch}
                    versionId={versionId}
                    onSaveDescription={onSaveDescription}
                />
                <LinkButton to={getNormsAuditTrailUrl(organization, versionId, documentElementCommonId, branchId || selectedApprovalBranchId)} bgColor="transparent" fontColor={colorStack.middleBlue}>
                    <IntlMessage id="norms.auditTrail" />
                </LinkButton>
            </ModifyBox>

            <WhiteBox>
                <PreviewHeader branch={branch} />
                <ContentToolbar
                    versionsLcid={versionsState.versionsLcid}
                    versions={versionsState.uniqueVersions}
                    comparedBranchVersion={comparedBranchVersion}
                    onChangeCompareBranchVersion={onChangeCompareBranchVersion}
                    onDownloadClick={onDownloadClick}
                    downloadDisabled={!(versionId && documentationId && branch)}
                    isDownloading={isDownloading}
                    highlightChanges={highlightChanges}
                    sideBySide={sideBySide}
                    toggleHighlightChanges={toggleHighlightChanges}
                    toggleSideBySide={toggleSideBySide}
                    featureBranchesResponse={featureBranchesResponse}
                    isBranchPreview={!!featureBranchesResponse}
                />
                <Loader loading={!(branch || comparisonState.branchComparison || error) || comparisonState.isLoading}>
                    {branch && !comparisonState.branchComparison && (
                        <StructureLevel
                            structureLevel={branch.contents}
                            structureId={branch.contents?.commonLevelId}
                            versionId={versionId}
                            organizationUrlName={organization}
                            disableStructureLinks
                            isNormsManagement={true}
                            onArticleDetailsClick={onArticleDetailsClick}
                        />
                    )}
                    {comparisonState.branchComparison && (
                        <BranchDiff
                            branchComparison={comparisonState.branchComparison}
                            versionId={versionId}
                            organizationUrlName={organization}
                            comparedBranchVersionId={comparedBranchVersion.versionId}
                            onArticleDetailsClick={onArticleDetailsClick}
                            highlightChanges={highlightChanges}
                            sideBySide={sideBySide}
                        />
                    )}
                    {error && (
                        <div className="lcr-error">{error}</div>
                    )}
                </Loader>
            </WhiteBox>
            <Popup
                isVisible={isConsistencyPopupEnabled}
                text={<IntlMessage id="norms.noStructureConsistency"/>}
                cancelAction={() => setIsConsistencyPopupEnabled(false)}
                cancelButtonText={<IntlMessage id="global.close" />}
            >
            </Popup>
            <Popup
                isVisible={!!isBranchRejectInitialized}
                text={<IntlMessage id="norms.branchRejectionStarted"/>}
                cancelAction={onCloseBranchRejectionPopup}
                cancelButtonText={<IntlMessage id="global.close" />}
            >
            </Popup>
        </SogePageWrapper>
    );
};

export default Component;