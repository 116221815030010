import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { SelectCard } from '../../../Common/SelectCard/SelectCard';
import { EClusterPrivacy } from '../../../../entities/IClusters';
import IntlMessage, { useIntlMessage } from '../../../Common/IntlMessage';
import { GeneralContext, IGeneralContext } from '.';
import { TextComponent } from '../../../Common/Inputs/TextComponent';

const Wrapper = styled.div`
    width: 100%;
`;
const Privacy = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    > div {
        width: 31%;
    }
`;

const Step2: React.FC = () => {
    const generalContext = useContext<IGeneralContext>(GeneralContext);
    const { intlFormatMessage } = useIntlMessage();
    const privacyTranslation = {
        [EClusterPrivacy.Public]: intlFormatMessage({ id: 'cluster.privacy.public' }),
        [EClusterPrivacy.Private]: intlFormatMessage({ id: 'cluster.privacy.private' }),
        [EClusterPrivacy.Secret]: intlFormatMessage({ id: 'cluster.privacy.secret' })
    };

    useEffect(() => {
        generalContext.setData({ ...generalContext.data, stepNumber: 2 });
    }, []);

    return (
        <Wrapper>
            <TextComponent
                label={<IntlMessage id="general.wizard.label.name" />}
                onChange={(value) => generalContext.setData({ ...generalContext.data, name: value })}
                value={generalContext.data.name}
                required
            />
            <Privacy>
                {Object.keys(privacyTranslation).map(elem => (
                    <SelectCard
                        value={elem}
                        isChecked={generalContext.data.privacy === elem}
                        templateKey={elem}
                        templateName={privacyTranslation[elem]}
                        onClick={(value: EClusterPrivacy) => generalContext.setData({ ...generalContext.data, privacy: value })}
                        imageKey={`icon${elem}`}
                        name={`general.popup.step2.name.${elem}`}
                        description={`general.popup.step2.description.${elem}`}
                    />
                ))}
            </Privacy>
        </Wrapper>
    );
};

export default Step2;