import React, { createContext, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { StepsPopup } from '../../../Common/StepsPopup/StepsPopup';
import { EPopupSizes } from '../../../../entities/IGlobal';
import { useIntlMessage } from '../../../Common/IntlMessage';
import Step1 from './Step1';
import Confirm from './Confirm';
import { EClusterPrivacy, ISingleCluster } from '../../../../entities/IClusters';
import { createNewCluster, ICreateNewClusterPayload } from '../../../../actions/clustersActions';
import { useOrganization } from '../../../../tools/organizationHooks';
import Step2 from './Step2';

type CreateNewCluster = ReturnType<typeof createNewCluster>;

interface IClusterData {
    name: string;
    selectedTemplateId: string;
    privacy: EClusterPrivacy;
    stepNumber: number;
}

export interface IGeneralContext {
    data: IClusterData;
    organizationId: string;
    setData: (data: IClusterData) => void;
}

export const GeneralContext = createContext<IGeneralContext>(undefined);

interface IProps {
    organizationUrlName: string;
    isPopupShown: boolean;
    isHome?: boolean;
    setShowPopup: (show: boolean) => void;
}

const GeneralWizard: FC<IProps> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const history = useNavigate();
    const dispatch = useDispatch();
    const [isValid, setIsValid] = useState<boolean>(false);
    const [data, setData] = useState<IClusterData>(undefined);
    const [stepFinished, setStepFinished] = useState<boolean>(false);
    const [isFormFinnished, setIsFormFinnished] = useState<boolean>(false);
    const [newClusterData, setNewClusterData] = useState<ISingleCluster>(undefined);
    const { currentOrganization } = useOrganization(props.organizationUrlName);

    const initState: IGeneralContext = {
        data,
        organizationId: currentOrganization.id,
        setData
    };

    const finishHandler = () => {
        setData(undefined);
        props.setShowPopup(false);
        if (isFormFinnished) {
            history(`/orgs/${props.organizationUrlName}/cluster/${newClusterData.id}/overview`);
            setIsFormFinnished(false);
        } else if (props.isHome) {
            history('/');
        }
    };

    const clearForm = () => {
        props.setShowPopup(false);
        setData(undefined);
    };

    const confirmHandler = () => {
        const body: ICreateNewClusterPayload = {
            organizationId: currentOrganization?.id,
            name: data.name,
            description: '',
            privacy: data.privacy || EClusterPrivacy.Private,
            organizationUrlName: currentOrganization?.details?.urlName,
            organizationName: currentOrganization?.details?.name,
            clusterTemplateId: data.selectedTemplateId
        };
        return dispatch<CreateNewCluster>(createNewCluster(body)).then((response) => {
            setStepFinished(true);
            setIsFormFinnished(true);
            setNewClusterData(response);
        }).catch(reason => {
            console.log(reason);
        });
    };

    useEffect(() => {
        globalValidation(data?.stepNumber);
    }, [data]);

    const globalValidation = async (stepNumber: number) => {
        let schema = Yup.object().shape({
            selectedTemplateId: stepNumber === 1 && Yup.string().required(),
            name: stepNumber === 2 && Yup.string().required(),
            privacy: stepNumber === 2 && Yup.string().required()
        });
        if (data) {
            const result = await schema.isValid(data);
            console.log(result, data);
            setIsValid(result);
        } else {
            setIsValid(false);
        }
    };

    return (
        <GeneralContext.Provider value={initState}>
            <StepsPopup
                showPopup={props.isPopupShown}
                size={EPopupSizes.MEDIUM}
                title={intlFormatMessage({ id: 'general.wizard.title' })}
                isFormValid={isValid}
                finishHandler={finishHandler}
                dirty
                confirmFinished={stepFinished}
                preventClosingOnClickingOutside
                clearForm={clearForm}
                confirmHandler={confirmHandler}
                confirmDisabled={false}
                steps={[
                    {
                        title: intlFormatMessage({ id: 'general.wizard.step1.title' }),
                        content: <Step1 />
                    },
                    {
                        title: intlFormatMessage({ id: 'general.wizard.step2.title' }),
                        content: <Step2 />
                    },
                    {
                        content: <Confirm clusterName={newClusterData?.presentation?.name} />
                    }
                ]}
            />
        </GeneralContext.Provider>
    );
};

export default GeneralWizard;