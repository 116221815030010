export const SET_BACKGROUND_DATATABLE_IDS = 'SET_BACKGROUND_DATATABLE_IDS';
export const ADD_BACKGROUND_DATATABLE_ID = 'ADD_BACKGROUND_DATATABLE_ID';
export const CLEAR_BACKGROUND_DATATABLE = 'CLEAR_BACKGROUND_DATATABLE';
export const CLEAR_ALL_BACKGROUND_DATATABLES = 'CLEAR_ALL_BACKGROUND_DATATABLES';

export interface IDatatableTypes {
    SET_BACKGROUND_DATATABLE_IDS: {
        datatableName: string;
        backgroundDatatableIds: string[];
    };
    ADD_BACKGROUND_DATATABLE_ID: {
        datatableName: string;
        backgroundDatatableId: string;
    };
    CLEAR_BACKGROUND_DATATABLE: {
        datatableName: string;
    };
    CLEAR_ALLBACKGROUND_DATATABLES: undefined;
}