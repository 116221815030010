import { IOrganizationMember, IOrganizationAccount } from './IOrganization';
import { IClusterDynamicStakes, EStakeDetailsType } from './IClusterStake';
import { ELcid } from './ILanguage';
import { ClusterPermissions } from './IPermissions';
import { EColumnSubTypes } from '../tools/dataGridTools';
import { IFacetsSearchResponse } from './IEntity';
import { IRegistrations } from './ILegalEntities';
import { EProfileDataType } from '../tools/profileConstants';
import {
    EReferentials,
    EContextList,
    IAddressGlobal,
    ISearchResponse,
    IUserBrief,
    IViewReferential,
    ISingleAttachment,
    EStatus,
    EClusterStatus,
    ISingleFile
} from './IGlobal';
import { IValidationStage } from './IValidation';
import { EColumnType } from './IDatagrid';
import { ITagShim } from './ITag';
import { IFullUserProfile } from './IProfile';
import { IOverviewProps } from '../components/Common/ColoredDatagrid/CellTypeComponents/Overview';
import { IExtendedAvatarProps } from '../components/Common/ColoredDatagrid/CellTypeComponents/ExtendedAvatar';
import { IMember } from '../components/Common/ColoredDatagrid/CellTypeComponents/People';

export interface IClusterMember extends Omit<IOrganizationMember, 'role'> {
    role: EClusterRole;
}

export type IClustersListPage = ISearchResponse<IClustersListCluster>;

export interface IClustersListCluster {
    clusterMembers: IClusterMember[];
    clusterMembersByUserId?: {
        [id: string]: IClusterMember;
    };
    id: string;
    organizationId: string;
    status: EClusterStatus;
    name: string;
    photo: string;
    privacy: EClusterPrivacy;
    description: string;
    createdAt: string;
    lastActivity: string;
    nature: EClusterNature;
    documents: IClusterListDocument[];
    peopleInCharge: IClusterPersonInCharge[];
}
export interface IClusterInvolvedParty {
    name: string;
    involvedParties: IInvolvedPartyRequest[];
    id: string;
}

export enum EClusterNature {
    Project = 'Project',
    Dispute = 'Dispute',
    Contract = 'Contract',
    Support = 'Support',
    Other = 'Other',
    ReceivingGift = 'ReceivingGift',
    SendingGift = 'SendingGift',
    ReceivingInvitation = 'ReceivingInvitation',
    SendingInvitation = 'SendingInvitation',
    Compliance = 'Compliance'
}

export enum EClusterApplicableLaw {
    FrenchLaw = 'FrenchLaw',
    EnglishLaw = 'EnglishLaw'
}

export interface IClusterReferential {
    name: string;
    type: string;
    referentials: {
        name: string;
        id: string;
    }[];
}

export interface IClusterPersonInCharge {
    firstName: string;
    lastName: string;
    name?: string;
    jobTitle: string;
    department: {
        name: string;
        id: string;
    };
    picture: string;
    cabinet?: string;
    id: string;
}

export interface IClusterPresentation {
    name: string;
    privacy: string;
    status: EClusterStatus;
    nature: EClusterNature;
    description: string;
    photo: string;
    peopleInCharge: IClusterPersonInCharge[];
    startDate: Date;
    endDate: Date | string;
    firstReferential: IClusterReferential;
    secondReferential: IClusterReferential;
    thirdReferential: IClusterReferential;
    lastActivity: string;
    createdAt: string;
    categories: string[];
}

export interface IClusterSpecification {
    expertises: IClusterExpertise[];
    geographies: IClusterInformation[];
    businessSectors: IClusterInformation[];
    account: IClusterInformation;
    tags: { name: string }[];
    applicableLaw: EClusterApplicableLaw;
}

export interface IClusterWorkplaceDetails {
    tenantId: string;
    teamId: string;
    teamName: string;
    channelId: string;
    channelName: string;
    channelEmail: string;
    groupId: string;
    teamUrl: string;
    channelUrl: string;
    tabUrl: string;
}

export type IClustersListSearch = IFacetsSearchResponse<ISingleSearchCluster>;

export interface ISingleSearchCluster {
    photo: string;
    name: string;
    description: string;
    privacy: string;
    startDate: string;
    endDate: string;
    organizationId: string;
    organizationName: string;
    status: EClusterStatus;
    nature: string;
    applicableLaw: string;
    geographies: IClusterInformation[];
    businessSectors: IClusterInformation[];
    expertises: {
        specialities: IClusterInformation[];
        name: string;
        id: string;
    }[];
    specialities: IClusterInformation[];
    members: {
        id: string;
        firstName: string;
        lastName: string;
        role: number;
        picture: string;
    }[];
    peopleInCharge: {
        id: string;
        firstName: string;
        lastName: string;
        picture: string;
    }[];
    tags: string[];
    account: IClusterInformation[];
    createdAt: string;
    lastActivity: string;
    involvedParties: IClusterInformation[];
    firstReferentials: IClusterInformation[];
    secondReferentials: IClusterInformation[];
    thirdReferentials: IClusterInformation[];
    id: string;
    nextTimelineTitle: string;
    nextTimelineDate: string;
    referenceTimelineDate: string;
    referenceTimelineTitle: string;
}

export interface ITimelineItem {
    subject: string;
    description?: string;
    startTime: string | Date;
    endTime: string | Date;
    timezone: string;
    isAllDay?: boolean;
    location?: string;
    isReferenceDate?: boolean;
    id?: string;
}

export interface ITimelineItemForm {
    subject: string;
    description?: string;
    startDate: Date;
    startTime?: string;
    endDate: Date;
    endTime?: string;
    timezone: string;
    isAllDay?: boolean;
    downloadAfterSave?: boolean;
}

export type CClusterHeaderStatuses = 'ToDo' | 'Ongoing' | 'Completed' | 'DealBroken';

export interface ISingleCluster {
    associatedClusters: {
        clusterId: string;
        relation: string;
    }[];
    businessId: {
        breadcrumb: string;
        id: string;
        value: number;
    };
    clusterHeaders: {
        settings?: CClusterHeaderStatuses;
        notification?: CClusterHeaderStatuses;
        export?: CClusterHeaderStatuses;
        activity?: CClusterHeaderStatuses;
        overview?: CClusterHeaderStatuses;
        qAndA?: CClusterHeaderStatuses;
        sideLetter?: CClusterHeaderStatuses;
        assesment?: CClusterHeaderStatuses;
        subs?: CClusterHeaderStatuses;
        links?: CClusterHeaderStatuses;
        documents?: CClusterHeaderStatuses;
        members?: CClusterHeaderStatuses;
    };
    areDatesAvailable: boolean;
    alreadyRequestedAccess: boolean;
    isHub: boolean;
    clusterMembers: IClusterMember[];
    clusterTemplateId: string;
    clusterType: string;
    createdAt: string;
    organizationUrlName: string;
    dynamicStakes: IClusterDynamicStakes[];
    economicStakes: IEconomicStakes[];
    id: string;
    involvedParties: IClusterInvolvedParty[];
    lastActivity: string;
    legalStakes: {
        legalStakes: ILegalStakes[];
        exclusivity: boolean;
        confidentiality: boolean;
        changeOfControlClause: boolean;
    };
    organizationId: string;
    organizationName: string;
    permissions: ClusterPermissions[];
    presentation: IClusterPresentation;
    specification: IClusterSpecification;
    timeline: ITimelineItem[];
    workplaceDetails: IClusterWorkplaceDetails;
    clusterMembersByUserId?: {
        [id: string]: IClusterMember;
    };
    referencedItem: IRreferencedItem;
    templateType?: ETemplateTypes;
    templateKey?: ETemplateKeys;
    errorCode?: number;
    validationStatus: EValidationStatus;
    validationStatusModifiedAt: Date;
    removeMultistake?: boolean;
    editStakeId?: string;
    objectVersionCounter?: number;
}

export enum EValidationStatus {
    Active = 'Active',
    Completed = 'Completed',
    Rejected = 'Rejected',
    Disabled = 'Disabled',
    Done = 'Done'
}

export enum ETemplateTypes {
    Gift = 'Gift',
    Invitation = 'Invitation',
    Contract = 'Contract',
    POA = 'Poa',
    COI = 'Coi',
    Sponsoring = 'Sponsoring',
    Patronage = 'Patronage',
    Lobbying = 'Lobbying',
    LegalSupport = 'LegalSupport',
    TradeAssociations = 'TradeAssociations',
    EApprovalGrouping = 'EApprovalGrouping',
    EApprovalSponsoring = 'EApprovalSponsoring',
    EApprovalFreeDeclaration = 'EApprovalFreeDeclaration',
    EApprovalEventExpenses = 'EApprovalEventExpenses'
}

export enum EClusterErrors {
    JoinTheCluster = 1000,
    CreationInProgress = 2600,
    TeamChannelCreationInProgress = 2300
}

export enum ETemplateKeys {
    GiftSend = 'gift-send',
    GiftReceive = 'gift-receive',
    InvitationSend = 'invitation-send',
    InvitationReceive = 'invitation-receive',
    NoLinkCoiCreate = 'no-link-coi-create',
    CoiCreate = 'coi-create',
    SponsoringSaur = 'sponsoring-saur',
    PatronageSaur = 'patronage-saur',
    Patronage = 'patronage-v2-create',
    Sponsoring = 'sponsoring-v2-create',
    Lobbying = 'lobbying',
    LegalSupport = 'legal-support',
    LeaseLot = 'lease-lot',
    Dispute = 'dispute',
    CorporateSimpleSas = 'corporate-simple-sas',
    CorporateForeignMfBrands = 'corporate-foreign-mf-brands',
    CorporateSc = 'corporate-sc',
    CorporateSarl = 'corporate-sarl',
    CorporateSas = 'corporate-sas',
    CorporateSaSupervisory = 'corporate-sa-supervisory',
    CorporateSaBoard = 'corporate-sa-board',
    SupplyContract = 'supply-mf-brand',
    FranchiseContract = 'franchise-mf-brand',
    DistributionMfBrand = 'distribution-mf-brand',
    KnowledgeManagement = 'knowledge-management',
    contractSupplierItSepro = 'contract-supplier-IT-sepro',
    contractLeaseSepro = 'contract-lease-sepro',
    contractKeyAccountsSepro = 'contract-key-accounts-sepro',
    contractMaintenanceSepro = 'contract-maintenance-sepro',
    contractDistributionSepro = 'contract-distribution-sepro',
    contractSaleRepresentativeSepro = 'contract-sale-representative-sepro',
    contractSupplierSepro = 'contract-supplier-sepro',
    contractLease = 'contract-lease',
    basicContract = 'basic-contract',
    TradeAssociations = 'trade-associations',
    NEGOTIATION = 'negotiation',
    CONTRACT_NDA = 'contract-nda'
}

export interface ISingleClusterGift extends ISingleCluster {
    wizard: IWizard;
}

export interface IWizard {
    estimatedTime: number;
    steps: IStep[];
}

export interface IStep {
    name: string;
    stakes: {
        stakeDefintionKey: string;
        stakeFamilyId: string;
        stakeListKey: string;
    }[];
}

export interface IRreferencedItem {
    referencedItem: {
        address: IAddressGlobal;
        websiteUrl: string;
        phoneNumber: string;
        description: string;
        id: string;
        isPublic: boolean;
        legalForm: { id: string; name: string };
        name: string;
        picture: string;
        size: string;
        typeOfActivity: string;
        typeOfLegalEntity: { id: string; name: string };
        nationality: { id: string; name: string };
        group: { id: string; name: string };
        registeredCapital: { number: number; unit: IViewReferential };
        registrations: IRegistrations[];
    };
    type: string;
}

export interface ISingleClusterCore extends ISingleCluster {
    permissions: ClusterPermissions[];
}

export interface IDataGridSettings {
    userId?: string;
    dataOrders?: [];
    columnsSettings: {
        width: number;
        field: string;
        headerText: string;
        isVisible: boolean;
        type?: EColumnType;
    }[];
    id?: string;
}

export interface ITemplateDataGridSettings {
    userId: string;
    dataOrders: unknown[];
    columnsSettings: IColumnSettings[];
    id: string;
    clusterTemplateId: string;
    format: string;
    clusterCustomDataGridConfigurationId?: string;
    key: string;
}

export enum EKeysForDataTableExport {
    AdministrationUsers= 'administration-users',
    AdministrationWorkspaces = 'administration-workspaces',
    AdministrationInvitations = 'administration-invitations',
    AdministrationGroups = 'administration-groups',
    Groups = 'groups',
    Approvals = 'approvals',
    WorkspaceSettingsUsers  = 'workspace-settings-users',
    WorkspaceSettingsPendingMembers  = 'workspace-settings-pending-members',
    ClusterSettingsUsers = 'cluster-settings-users',
    ClusterSettingsPendingInvitations = 'cluster-settings-pending-invitations',
    ClusterSettingsAccessRequest = 'cluster-settings-access-request',
    ProfileClusterLink = 'profile-cluster-link',
    ProfileMandate = 'profile-mandate'
}

export enum ENamesForDataTableExport {
    Explore = 'Explore',
    Approvals = 'Approvals',
    AdminConsoleGroups = 'AdminConsoleGroups',
    AdminConsoleInvitations = 'AdminConsoleInvitations',
    AdminConsoleMembers = 'AdminConsoleMembers',
    AdminConsoleWorkspaces = 'AdminConsoleWorkspaces',
    SingleGroupClusterLink = 'SingleGroupClusterLink',
    SingleGroupMembers = 'SingleGroupMembers',
    Clausier = 'Clausier',
    FAQ = 'FAQ',
    AssetMenagmentCluster = 'AssetMenagmentCluster',
    AllRights = 'AllRights',
    MainSL = 'MainSL',
    SideLetter = 'SideLetter',
    Preview = 'Preview',
    AccessRequest = 'AccessRequest',
    ClusterSettingsMembers = 'ClusterSettingsMembers',
    ClusterSettingsGroupMembers = 'ClusterSettingsGroupMembers',
    ClusterSettingsPendingInvitations = 'ClusterSettingsPendingInvitations',
    Owner = 'Owner',
    OrganizationSettingsMembers = 'OrganizationSettingsMembers',
    OrganizationSettingsGroupMembers = 'OrganizationSettingsGroupMembers',
    OrganizationSettingsPendingMembers = 'OrganizationSettingsPendingMembers',
    ProfileClusterLink = 'ProfileClusterLink',
    Mandat = 'Mandat',
    Categories = 'Categories',
    Limits = 'Limits',
    Periods = 'Periods',
    Beneficiary = 'Beneficiary',
    Donor = 'Donor',
    Declaration = 'Declaration',
    DelegationChain = 'DelegationChain',
    KeyDates = 'KeyDates'
}

export interface IColumnSettings {
    translationKey?: string;
    width?: number;
    resizable?: boolean;
    maxWidth?: number
    minWidth?: number
    field: string;
    headerText: string;
    isVisible: boolean;
    type?: EColumnType;
    format?: string;
    properties?: IColumnSettings[];
    subType?: EColumnSubTypes;
}

export interface IEconomicStakes {
    title: string;
    description: string;
    amount: number;
    currency: ECurrencyTypes;
    id?: string;
}

export enum ECurrencyTypes {
    Dollar = 'Dollar',
    Euro = 'Euro',
    Pound = 'Pound',
    PLN = 'PLN'
}

export enum EOrderBy {
    OrderByMostRecentActivity = 'OrderByMostRecentActivity',
    OrderByLastCreationDate = 'OrderByLastCreationDate'
}

export interface ICLusterListPayload {
    isMine: boolean;
    orderBy: EOrderBy;
    query?: string;
}

export interface ILegalStakes {
    title: string;
    description: string;
    id: string;
}

export interface IRequestAccessUser {
    lastName: string;
    firstName: string;
    picture: string;
    id: string;
    role: number;
    date: string;
}

export interface ISIngleDocument {
    clusterId: string;
    contentType: string;
    fileName: string;
    id: string;
    sender: {
        id: string;
    };
}

export interface IClusterListDocument {
    clusterId: string;
    contentType: string;
    fileName: string;
    clusterChannelId: string;
    sender: {
        id: string;
    };
    thumbnailBlobLink: string;
    id: string;
}

export interface ISnapshotData {
    link: string;
    contentType: string;
    name: string;
}

export interface SendMessageBody {
    mentionedItems?: {
        id?: number;
        text: string;
        linkedId: string;
        type: EMentionType;
    }[];
    redirectUrl?: string;
    files?: ISingleFile[];
    content: string;
    type: 'TextFile' | 'Text';
    tempId: string;
    threadId?: string;
}

export interface IChannels {
    name: string;
    type: number;
    members: IOrganizationMember[];
    clusterId: string;
    id: string;
}

export interface IDocumentList {
    count: number;
    items: ISIngleDocument[];
    page: number;
    size: number;
}

export interface IDetailsCluster {
    accounts: IClusterInformation;
    description: string;
    endDate: string;
    expertises: IClusterExpertise[];
    geographies: IClusterInformation[];
    name: string;
    startDate: string;
    photo: string;
    peopleInCharge: {
        id: string;
        lastName: string;
        firstName: string;
        picture: string;
        role: EClusterRole;
    }[];
    tags: { name: string }[];
    account: IOrganizationAccount;
}

export interface IClusterInformation {
    name: string;
    id: string;
    Key: string;
}

export interface IClusterExpertise {
    name: string;
    id: string;
    Key: string;
    specialities?: IClusterInformation[];
}

export interface IChannel {
    teamId: string;
    title: string;
    membersSettings: {
        isPrivate: boolean;
        members: IOrganizationMember[];
    };
    id: string;
}

export interface ITeam {
    parentId: string;
    title: string;
    membersSettings: {
        members: IOrganizationMember[];
    };
    id: string;
}

export interface IChannelMessages {
    teamId: string;
    teamChannelId: string;
    items: ISingleChannelMessage[];
    size: number;
    page: number;
    count: number;
}
export interface IMentionedItem {
    id: number;
    text: string;
    linkedId: string;
    type: EMentionType;
}
export interface IMessageFile {
    size?: number;
    contentType: string;
    directUri: string;
    name: string;
}

export enum EPresentationProperties {
    Privacy = 'Privacy',
    Status = 'Status',
    Nature = 'Nature',
    Description = 'Description',
    EndDate = 'EndDate',
    StartDate = 'StartDate',
    PeopleInCharge = 'PeopleInCharge',
    FirstReferential = 'FirstReferential',
    SecondReferential = 'SecondReferential',
    ThirdReferential = 'ThirdReferential',
    Expertises = 'Expertises',
    Geographies = 'Geographies',
    BusinessSectors = 'BusinessSectors',
    ApplicableLaw = 'ApplicableLaw',
    Tags = 'Tags'
}

export interface IPresentationPropertiesItem {
    name: EPresentationProperties;
    oldValue: any;
    newValue: any;
}
export interface IAdditionalInformation {
    date: string;
    stakes?: IDynamicStakeMessage[];
    removedMessageId?: string;
    fileName?: string;
    requestId?: string;
    RequestId?: string;
    filesNames?: string[];
    firstName?: string;
    lastName?: string;
    folder?: string;
    files?: IMessageFile[];
    properties?: IPresentationPropertiesItem[];
    snapshot?: string;
    newTitle: string;
    involvedPartyName: string;
    involvedPartiesGroupName: string;
    timelineTitle: string;
    legalStakeTitle: string;
    economicStakeTitle: string;
    context?: {
        type: string;
        referencedItem: {
            name: string;
            organizationId: string;
            id: string;
        };
    };
    stages: IValidationStage[];
    status: EStatus;
    invoker: IUserBrief;
    validator: IUserBrief;
    connectedMember: IUserBrief;
    memberA: IUserBrief;
    memberB: IUserBrief;
    member: IUserBrief;
    command: any;
}

export interface IClusterSingleMessage {
    additionalInformation?: any;
    attachments: ISingleAttachment[];
    canDelete: boolean;
    teamChannelId: string;
    clusterId: string;
    content: string;
    conversationId: string;
    createdDate: string;
    id: string;
    isThread: boolean;
    mentionedItems: IMentionedItem[];
    channelMessageId?: string;
    sender: IUserBrief;
    senderUserId: string;
    status: EStatus;
    tempId: string;
    threadId: string;
    type: string;
    clusterChannelId?: string;
    translationKey: string;
    translationId: string; // temp very bad Fix for BE problems
    name?: string;
    newName?: string;
    path?: string;
    cluster?: {
        id: string;
        name: string
        organizationId: string;
    }
    teamId: string;
}

export interface IClusterValidationRequestStatusUpdateMessage {
    additionalInformation: {
        clusterId: string;
    }
}
export interface ISingleClusterChannelMessage extends IClusterSingleMessage {
    clusterId: string;
    messages: IClusterSingleMessage[];
}
export interface ISingleChannelMessage extends IClusterSingleMessage {
    threadMessages?: IClusterSingleMessage[];
}
export interface IDynamicStakeMessage {
    key: string;
    name: IStakeName[];
    stakeDefinitionId: string;
    properties: IDynamicStakeMessagePropertie[];
    id: string;
}

export interface IDynamicStakeMessagePropertie {
    name: IStakeName[];
    type: EStakeDetailsType;
    key: string;
    oldValue: any; // type depends on Type propertie
    newValue: any; // type depends on Type propertie
    id: string;
}

export interface IStakeName {
    label: string;
    lcid: ELcid;
}

export enum EInvolvedQuality {
    Buyer = 'Buyer',
    Advisor = 'Advisor',
    Seller = 'Seller',
    Client = 'Client',
    Supplier = 'Supplier',
    BusinessPartner = 'BusinessPartner',
    MainContractor = 'MainContractor',
    SubContractor = 'SubContractor',
    CoContractor = 'CoContractor',
    Distributor = 'Distributor',
    Agent = 'Agent',
    AdministratorPublic = 'AdministratorPublic',
    Other = 'Other',
    Applicant = 'Applicant',
    Claimant = 'Claimant',
    Defendant = 'Defendant',
    JVPartner = 'JVPartner',
    ServiceProvider = 'ServiceProvider',
    ProjectLeader = 'ProjectLeader'
}

export const ClusterStatuses = {
    10: 'New',
    20: 'Active',
    30: 'Closed',
    40: 'Issue',
    50: 'Blocked',
    60: 'Archived'
};

export interface IClusterFormExpertise {
    id: string;
    Name: string;
    speciality: {
        Name: string;
        id: string;
    }[];
}

export interface IClusterFormProperties {
    name: string;
    description: string;
    status?: EClusterStatus;
    expertise?: IClusterFormExpertise[];
    location?: {
        id: string;
        Name: string;
        barAdmission: {
            Name: string;
            id: string;
        }[];
    }[];
    startDate?: Date;
    endDate?: Date;
    logo?: string;
    privacy?: EClusterPrivacy;
    peopleInCharge?: IFullUserProfile[];
    tags?: ITagShim[];
    account?: IOrganizationAccount;
}

export enum EClusterRole {
    Owner = 10,
    Member = 20,
    Viewer = 30
}

export enum EClusterRoleName {
    Member = 'Clusters.Member',
    Editor = 'Clusters.Editor',
    Owner = 'Clusters.Owner',
    Guest = 'Clusters.Editor'
}

export const clusterRolesPlural = {
    10: 'Owners',
    20: 'Members',
    30: 'Viewers'
};

export enum EClusterPrivacy {
    Public = 'Public',
    Private = 'Private',
    Secret = 'Secret'
}

export enum EClusterVisibility {
    Hidden = 'Hidden',
    Visible = 'Visible'
}

export enum EContextTypes {
    PLE = 'PLE'
}

export enum EClusterType {
    Personal = 10,
    Organizational = 20
}

export interface ISingleTag {
    name: string;
    id: string;
}
export interface IClusterTags {
    geographies: ISingleTag[];
    statuses: [];
    specialities: ISingleTag[];
    expertises: ISingleTag[];
    tags: ISingleTag[];
    accounts: ISingleTag[];
}

export enum EClusterAccessError {
    NOT_MEMBER = 1,
    NOT_MEMBER_REQ_ALREADY_SENT = 2,
    NOT_FOUND = 3,
    NOT_EXIST_ANYMORE = 1100
}

export const CHANNEL_MENTION = 'CHANNEL';

export enum EMentionType {
    User = 'User',
    Channel = 'Channel'
}

export enum EClusterPageTab {
    Overview = 'overview',
    Activity = 'activity',
    Documents = 'documents',
    Members = 'members',
    Settings = 'settings',
    SettingsV2 = 'settingsV2',
    Agenda = 'agenda',
    Assesment = 'assesment',
    Links = 'links',
    NotificationSettings = 'notification-settings',
    Datagrid = 'datagrid',
    GetFile = 'getFile',
    Negotiations = 'negotiations',
    Qanda = 'qAndA',
    SideLetter = 'sideLetter',
    Subs = 'subs'
}

export enum EOrderClusersBy {
    MostRecentActivity = 10,
    CreationDate = 20,
    Name = 30,
    LastUpdateDate = 40,
    LastVisitedDate = 50
}

// TODO: temp referentials types, until we have real data in AP
export enum EClusterReferentialType {
    FirstFakeReferential = 'FirstReferential',
    SecondFakeReferential = 'SecondReferential',
    ThirdFakeReferential = 'ThirdReferential'
}

export interface IClusterReferentialResponse {
    id: string;
    Name: string;
}

export interface IGetInvolvedPartiesResponse {
    Items: ISingleInvolvedPartie[];
    Size: number;
    Page: number;
    Count: number;
}

export interface ISingleInvolvedPartie {
    id: string;
    name: string;
    Name?: string;
    jobTitle?: string;
    cabinet?: string;
    picture: string;
    Picture?: string;
    subTitle?: string;
    type: 'Company' | 'Personal' | 'Cluster' | 'LegalEntity'
    DataType?: 'Company' | 'Personal' | 'Cluster';
}

export interface IInvolvedPartyRequest extends ISingleInvolvedPartie {
    quality?: string;
    peopleInCharges?: IClusterPersonInCharge[];
}

export interface INewClusterForm {
    name: string;
    description: string;
    privacy?: EClusterPrivacy;
    nature?: EClusterNature;
    workplaceDetails?: IClusterWorkplaceDetails;
}

export interface IClusterTemplate {
    templateName: string;
    templateKey: string;
    isActive: boolean;
    id: string;
    color: string;
    isHub: boolean;
}

export type DatagridType = 'template' | 'custom';
export type DataGridKey =
    | 'custom-corporate'
    | 'custom-mandates'
    | 'custom-dates'
    | 'custom-signature'
    | 'custom-contract';

export interface IDatagridView extends IClusterTemplate {
    dataGridType: DatagridType;
    dataGridKey: DataGridKey;
}

export enum EReferentialsType {
    FirstReferential = 'FirstReferential',
    SecondReferential = 'SecondReferential',
    ThirdReferential = 'ThirdReferential'
}

export enum ECustomDatagidsKeys {
    CustomMandates = 'custom-mandates',
    CustomSignature = 'custom-signature',
    CustomDates = 'custom-dates',
    CustomGift = 'custom-gift',
    CustomParticipatrion = 'custom-participation',
    CustomApproval = 'custom-approval',
    CustomPOA = 'custom-poa',
    CustomCOI = 'custom-coi',
    CustomSponsoring = 'custom-sponsoring',
    CustomContract = 'custom-contract',
    CampaignsDashboard = 'campaign-dashboard',
    CommunicationCampaignRecipients = 'communication-campaign-recipients',
    DeclarationCampaignRecipients = 'declaration-campaign-recipients',
    CustomLobbyingActions = 'custom-lobbying-actions',
    CustomLobbyingProject = 'custom-lobbying-project',
    CustomLegalSupport = 'custom-legal-support',
    CustomQuestionsAndAnswers = 'custom-questions-and-answers',
    CustomLandlordGeneral = 'custom-landlord-general',
    CustomLandLandlordLease = 'custom-landlord-lease',
    CustomLandLandlordLot= 'custom-landlord-lot',
    CustomLandLandlordOccupant = 'custom-landlord-occupant',
    CustomLandlordOwner = 'custom-landlord-owner',
    CustomActivitiesDatagrid = 'activities-datagrid',
    CustomInvestorsTracker = 'custom-investors-tracker',
    CustomDisputeInstances = 'custom-dispute-instances',
    CustomTradeAssociation= 'custom-trade-association'

}

export enum EDatagridKeys {
    mandateHolders = 'mandate-holders',
    signature = 'signature',
    dates = 'dates',
    participation = 'participation',
    approval = 'approval',
    poaHolders = 'poa-holders',
    campaign = 'campaign',
    campaigns = 'campaigns',
    gift = 'gift-dashboard',
    legalSupport = 'legal-support-dashboard',
    coi = 'coi-dashboard',
    contract = 'contract-dashboard',
    sponsoring = 'sponsoring-dashboard',
    lobbyingActions = 'actions-dashboard',
    lobbyingProject = 'project-dashboard',
    questions = 'questions',
    landlordDashboard = 'landlord-dashboard',
    landlordLeaseDashboard = 'landlord-lease',
    landlordLotDashboard = 'landlord-lot',
    landlordOccupantDashboard = 'landlord-occupant',
    landlordOwner = 'landlord-owner',
    activitiesDatagrid = 'activities-datagrid',
    investorsTracker = 'tracker',
    disputeInstancesDashboard = 'instances',
    tradeAssociationDashboard = 'trade-association-dashboard'
}

export interface IReferentialsIdentities {
    id: string;
    name: string;
    referentialType: EProfileDataType | EReferentials;
    context: EContextList;
    key?: string;
}
export interface IClusterResources {
    name: string;
    type: string;
    id: string;
}

export interface ICreateCorporateCluster {
    templateId: string;
    legalEntity: {
        name: string;
        picture: string;
        presentation: string;
        addres: IAddressGlobal;
        id: string;
    };
    privacy: string;
    organizationName: string;
    organizationUrlName: string;
    step: number;
}

export interface ISingleLink {
    clusterId: string;
    description: string;
    title: string;
    url: string;
    picture: string;
    createdAt: string;
    creator: IUserBrief;
    id: string;
    order: number;
}

export interface ICreateLinks {
    description: string;
    title: string;
    url: string;
    picture: string;
    id?: string;
}

export interface ITeamWithChannels {
    id: string;
    organizationName: string;
    organizationUrlName: string;
    photo: string;
    resourceChildId: string;
    teamChannels: ITeamChannels[];
    title: string;
}

export interface ITeamChannels {
    id: string;
    teamChannelResourceId: string;
    teamChannelResourceType: string;
    teamId: string;
    title: string;
}

export interface IActivityFiles {
    files: ISingleFile[]
}

export interface IApprovalDatatable {
    approval: {
        hyperlinkParameters: {
            clusterId: string;
            validationId: string;
            organizationUrlName: string;
            organizationId: string;
        };
        routeName: string;
        text: string;
    };
    approvers: IMember[]
    authorities: IMember[]
    cluster: IOverviewProps;
    status: {
        enumElement: string;
        translationKey: string;
        value: string;
    }
    createdAt: string;
    lastStatusUpdate: {
        occuredAt: string;
        status: {
            enumElement: string;
            translationKey: string;
            value: string;
        }
    }
    requestor: IExtendedAvatarProps;
}
