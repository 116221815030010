import { IClusterSingleMessage, ITeam } from './IClusters';
import { IModuleMember } from './IPermissions';

export enum EOrganizationRole {
    Owner = 10,
    Member = 30,
    Manager = 20,
    OutsideCollaborator = 40
}

export enum EChannelsRole {
    Owner = 10,
    Member = 20,
    Editor = 30
}

export enum EOrganizationRoles {
    Owner = 'Owner',
    Member = 'Member',
    Guest = 'Guest'
}

export enum EExpandedOrganizationRoles {
    Owner = 'Organizations.Owner',
    Member = 'Organizations.Member',
    Guest = 'Organizations.OutsideCollaborator'
}

export enum EPlatformRole {
    SuperAdmin = 'Platform.SuperAdmin',
    Owner = 'Platform.Owner',
    Member = 'Platform.Member',
    Guest = 'Platform.Guest'
}

export interface IOrganizationTag {
    status: ETagStatus;
    name: string;
}

export enum ETagStatus {
    Disabled = 0,
    Active = 1
}

export interface IOrganizationMember {
    firstName: string;
    lastName: string;
    childName?: string;
    childId?: string;
    id: string;
    picture: string;
    position: string;
    role: EOrganizationRole;
    joiningDate: Date;
    jobTitle: string;
    department: {
        name: string;
        id: string;
    };
    cabinet: string;
    emailContact?: string;
    principalType?: string;
    principalChildId?: string;
}

export interface IOrganizationOwner extends IOrganizationMember {
    joiningDate: Date;
}

export const rolesPlural = {
    10: 'Owner',
    30: 'Member',
    20: 'Manager',
    40: 'OutsideCollaborator'
};

export interface IOrganization {
    name: string;
    description: string;
    isLawFirm: boolean;
    logo: string;
    businessSectors: {
        name: string;
        id: string;
    }[]
    company: {
        websiteUrl: string;
        name: string;
        picture: string;
        id: string;
    }
    urlName: string;
    tenantId?: string;
}

export interface IOrganizationMembers {
    items: IOrganizationMember[];
    size: number;
    page: number;
    count: number;
}

export interface IOrganizationAccount {
    id: string;
    name: string;
}

export interface IOrganizationLink {
    id: string;
    name: string;
    url: string;
}

export interface IChannelActivity {
    beforeLastReply: IClusterSingleMessage;
    lastReply: IClusterSingleMessage;
    repliesCount: number;
    threadMessage: IClusterSingleMessage;
}

export interface ILatestActivitiy {
    mostRecentActivityDate: string | Date;
    team: ITeam;
    notReadTeamActivities: {
        mostRecentActivityDate: string | Date;
        teamChannel: {
            dataType: string;
            id: string;
            isDefault: boolean;
            resourceId: string;
            teamId: string;
            title: string;
            version: string;
            membersSettings: {
                isPrivate: boolean;
                members: IOrganizationMember[];
            };
        };
        notReadChannelActivities: IChannelActivity[];
    }[];
}

export interface IOrgModuleVisibility {
    name: string;
    additionalInformation: Object;
    visibility: boolean;
    organizationId: string;
    organizationName: string;
    id: string;
}

export enum EOrganizationModule {
    Clusters = 'Clusters',
    RFP = 'RFP',
    Documentation = 'Documentation',
    Panels = 'Panels'
}

export interface IOrganizationModule {
    name: EOrganizationModule;
    additionalInformation: object;
    visibility: boolean;
    organizationId: string;
    organizationName: string;
    moduleMembers: IModuleMember[];
    id: string;
}

export enum EOrganizationOptionKey {
    Home = 0,
    Filters = 1
}

export interface IOrganizationOption {
    key: EOrganizationOptionKey;
    value: boolean;
    organizationId: string;
}

export interface IDataGridSettings {
    columnsSettings: {
        width: number;
        field: string;
        headerText: string;
        isVisible: boolean;
    }[];
}

export interface IOrganizationType {
    name: string;
    id: string;
    key?: ETypeOfOrganization;
}

export interface ICurrenciesType {
    name: string;
    id: number;
    key?: number;
}

export enum EWorkspaceTranslationGroup {
    GiftInvitation = 'GiftInvitation',
    COI = 'Coi',
    Corporate = 'Corporate',
    Panel = 'Panel',
    Contract = 'Contract',
    POA = 'Poa',
    Sponsoring = 'Sponsoring',
    Norms = 'Norms',
    Lobbying = 'Lobbying',
    LegalSupport = 'LegalSupport',
    AssetsManagment = 'Assetmanagement',
    Landlord = 'Landlord',
    Dispute = 'Dispute',
    TradeAssociation = 'TradeAssociation'
}

export enum ETypeOfOrganization {
    GENERAL = 'GENERAL',
    CONTROL = 'CONTROL',
    NORMS = 'NORMS',
    CORPORATE = 'CORPORATE',
    SOCIAL = 'SOCIAL',
    GIFT = 'GIFT',
    QUOTATION = 'QUOTATION',
    PANELS = 'PANELS',
    CONTRACT = 'CONTRACT',
    SPONSORING = 'SPONSORING',
    POA = 'POA',
    COI = 'COI',
    LOBBYING = 'LOBBYING',
    LEGALSUPPORT = 'LEGALSUPPORT',
    ASSETSMANAGEMENT = 'ASSETSMANAGEMENT',
    LANDLORD = 'LANDLORD',
    DISPUTE = 'DISPUTE',
    TRADEASSOCIATION = 'TRADEASSOCIATION',
    EAPPROVAL = 'EAPPROVAL'
}

export enum ETypeOfCampaign {
    Declaration = 'Declaration',
    Communication = 'Communication',
    ActionPlan = 'ActionPlan',
    SharingInformation = 'SharingInformation'
}

export interface ICallToAtion {
    icon: string;
    isDisplayable: boolean;
    label: {
        label: string;
        lcid: number;
    }[];
    order: number;
    id: string;
    redirectionSuffix: string;
    requiredPermission: string;
}

export interface IOrganizationDataTable {
    'created-at': Date;
    'organization-overview': {
        businessId: number;
        organizationId: string;
        imageUrl: string;
        organizationName: string;
        organizationUrlName: string;
        organizationType: ETypeOfOrganization;
    };
    owners: {
        cabinet: string;
        emailContact: string;
        firstName: string;
        id: string;
        jobTitle: string;
        joiningDate: Date;
        lastName: string;
        picture: string;
        position: string;
        type: 'Personal' | 'Group';
        role: number;
        upn: string;
    }[];
}

export enum ELegalDocType {
    LEGALDOCLITE = 'LegalDocLite',
    ASSETSMANAGEMENT = 'AssetManagement'
}