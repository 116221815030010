import { action } from '../tools/reduxTools';
import { IModuleRole, IAvailableRole, ERoleType, IAssignedPrincipal, IAssignedRole, IGetInvitation, IGetMultipleGroupsById } from '../entities/IPermissions';
import { IUserBrief } from '../entities/IGlobal';
import { IExtendedAvatarProps } from '../components/Common/ColoredDatagrid/CellTypeComponents/ExtendedAvatar';
import { EUserPrivacy } from '../entities/IRoleManager';
import { EPlatformRole } from '../entities/IOrganization';

export const getModuleRoles = () => action<Promise<IModuleRole[]>>(async (dispatch, getState, http) => {
    const response = await http.permissionsApiGet(`/Roles/module/all`);
    return response || [];
});

export const getAllRoles = (type: ERoleType) => action<Promise<IAvailableRole[]>>((dispatch, getState, http) => {
    const { isSuperAdmin } = getState().profile;
    return http.permissionsApiGet(`/Roles/${type}/all`).then(res => {
        if (!isSuperAdmin) {
            return res.filter(role => role.roleName !== EPlatformRole.SuperAdmin);
        }
        return res;
    });
});

export const createUserWithoutInvitation = (email: string, firstName: string, lastName: string, roleId?: string, resourceChildId?: string, resourceType?: string) => action<Promise<IUserBrief>>((dispatch, getState, http) => {
    const { userLanguage } = getState().language;

    const body = {
        shadowProfile: {
            firstName,
            lastName,
            email
        },
        profilePrivileges: roleId ? {
            resourceType,
            resourceChildId,
            roleId
        // tslint:disable-next-line:no-null-keyword
        } : null
    };

    return http.profilesApiPost(`/users/accounts/create-shadow-profile?lcid=${userLanguage}`, body);
});

export const getResourcePrincipals = (resourceId: string, resourceType: ERoleType) => action<Promise<IAssignedPrincipal>>((dispatch, getState, http) => {
    return http.permissionsApiGet(`/resources/${resourceId}/principals?resourceTypes=${resourceType}`);
});

export const changeAssignedRole = (resourceId: string, principalChildId: string, roleId: string, resourceType: ERoleType, principalType: string) => action<Promise<IAssignedRole>>((dispatch, getState, http) => {
    return http.permissionsApiPut(`/resources/${resourceId}/assigned-roles`, {
        principalChildId,
        principalType,
        roleId,
        resourceType
    });
});

export const changeGroupMemberRole = (groupId: string, principalChildId: string, roleId: string, resourceType: ERoleType, principalType: string) => action<Promise<IAssignedRole>>((dispatch, getState, http) => {
    return http.permissionsApiPut(`/groups/${groupId}/assigned-roles`, {
        principalChildId,
        principalType,
        roleId,
        resourceType
    });
});

export const changeAccessRequestAssignedRole = (resourceId: string, accesRequestId: string, roleId: string) => action<Promise<IAssignedRole>>((dispatch, getState, http) => {
    return http.permissionsApiPut(`/resources/${resourceId}/access-requests/${accesRequestId}/role/${roleId}`, {});
});

export const inviteMembersToSingleGroup = (resourceId: string, roleId: string, principals: {childName: string, childId: string, type: string}[], resourceType: ERoleType, message: string) => action<Promise<void>>((dispatch, getState, http) => {
    return http.permissionsApiPost(`/groups/${resourceId}/assigned-roles/${roleId}`, {
        principals,
        resourceType,
        message
    });
});

export const deleteAssignedRole = (resourceId: string, principalChildId: string, resourceType: ERoleType, principalType?: string) => action<Promise<IAssignedRole>>((dispatch, getState, http) => {
    return http.permissionsApiDelete(`/resources/${resourceId}/assigned-roles`, {
        principalChildId,
        principalType,
        resourceType
    });
});

export const deleteGroupAssignedRole = (resourceId: string, principalChildId: string, resourceType: ERoleType, principalType?: string) => action<Promise<IAssignedRole>>((dispatch, getState, http) => {
    return http.permissionsApiDelete(`/groups/${resourceId}/assigned-roles`, {
        principalChildId,
        principalType,
        resourceType
    });
});

export const addAssignedRole = (resourceId: string, principalChildId: string, roleId: string, resourceType: ERoleType, message?: string, principalType?: string) => action<Promise<void>>((dispatch, getState, http) => {
    return http.permissionsApiPost(`/resources/${resourceId}/assigned-roles`, {
        principalChildId,
        principalType,
        roleId,
        resourceType,
        message
    }).then(res => res).catch(err => err);
});

export const inviteUserToResource = (resourceId: string, roleId: string, receiverEmail: string, receiverFirstName: string, receiverLastName: string, resourceType: ERoleType, message?: string, receiverId?: string) => action<Promise<void>>((dispatch, getState, http) => {
    return http.permissionsApiPost(`/resources/${resourceId}/invitations`, {
        receiverId,
        receiverEmail,
        receiverFirstName,
        receiverLastName,
        message,
        role: roleId,
        resourceType
    }).then(res => res).catch(err => err);
});

export const getSentInvitations = (resourceId: string) => action<Promise<IGetInvitation[]>>(async (dispatch, getState, http) => {
    return http.permissionsApiGet(`/resources/${resourceId}/invitations`);
});

export const resendToAll = (resourceId: string, body: string[]) => action<Promise<IGetInvitation[]>>(async (dispatch, getState, http) => {
    return http.permissionsApiPost(`/resources/${resourceId}/invitations/remind/all`, {invitationIds: body});
});

export const cancelInvitation = (resourceId: string, invitationId: string) => action<Promise<void>>(async (dispatch, getState, http) => {
    await http.permissionsApiPost(`/resources/${resourceId}/invitations/${invitationId}/cancel`);
});

export const resendInvitation = (resourceId: string, invitationId: string) => action<Promise<void>>(async (dispatch, getState, http) => {
    await http.permissionsApiPost(`/resources/${resourceId}/invitations/${invitationId}/remind`);
});

export const getMultipleGroupsById = (groupsIds: string[]) => action<Promise<IGetMultipleGroupsById[]>>(async (dispatch, getState, http) => {
    const body = {
        groupsIds: groupsIds
    };
    return http.permissionsApiPost(`/groups/filter-by-ids`, body);
});

export interface IMembersDataTable {
    'added-at': Date;
    'last-active-at': Date;
    'is-shadow': boolean;
    'invitation-id': string;
    principal: IExtendedAvatarProps;
    privacy: EUserPrivacy;
    role: {
        childId: string;
        id: string;
        isHidden: boolean;
        name: string;
        resourceType: ERoleType;
    };
}

interface IFilters {
    principalType?: string;
    query: string;
    roleIds: any[]
}

export const getMembersDataTable = (resourceId: string, filters: IFilters, resourceType: ERoleType, page?: number) => action<Promise<{items: IMembersDataTable[], facets: Record<string, any[]>, count: number}>>(async (dispatch, getState, http) => {
    return http.permissionsApiPost(`/resources/${resourceId}/principals/as-datatable?page=${page || 0}`, {...filters, query: filters.query?.[0], resourceType: resourceType});
});

export const getPendingDataTable = (resourceId: string, resourceType: ERoleType, filters: Record<string, any[]>, page?: number) => action<Promise<{items: IMembersDataTable[], facets: Record<string, any[]>; count: number}>>(async (dispatch, getState, http) => {
    return http.permissionsApiPost(`/resources/${resourceId}/invitations/as-datatable?page=${page || 0}`, {...filters, query: filters.query?.[0], resourceType: resourceType});
});

export const getGroupMembersDataTable = (groupId: string, filters: Record<string, any[]>, page?: number) => action<Promise<{items: IMembersDataTable[], facets: Record<string, any[]>, count: number}>>(async (dispatch, getState, http) => {
    return http.permissionsApiPost(`/groups/${groupId}/members/as-datatable?page=${page || 0}`, {...filters, query: filters.query?.[0]});
});
