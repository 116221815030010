import { IAttachment } from '../Messaging/IMessaging';
import { EAcceptanceStatus, IReferencial, IUserBrief } from '../IGlobal';
import { EQuestionCategory, ETemplateType, IBusinessId, IInvestmentVehicle, IRelatedItem } from './actions';
import { EMFN, ETypes, ICondition } from './global';

export enum EQuestionType {
    GENERIC = 'GENERIC',
    LPA = 'LPA',
    SL = 'SL'
}

export enum EAnswerType {
    GENERIC = 'GENERIC',
    LPA = 'LPA',
    SL = 'SL'
}

export const templateTypeFromQuestionType = (type: EQuestionType): ETemplateType => {
    switch (type) {
        case EQuestionType.GENERIC:
            return ETemplateType.GENERIC;
        case EQuestionType.LPA:
            return ETemplateType.LPA;
        case EQuestionType.SL:
        default:
            return ETemplateType.SL;
    }
};

export const templateTypeFromAnswerType = (type: EAnswerType): ETemplateType => {
    switch (type) {
        case EAnswerType.GENERIC:
            return ETemplateType.GENERIC;
        case EAnswerType.LPA:
            return ETemplateType.LPA;
        case EAnswerType.SL:
        default:
            return ETemplateType.SL;
    }
};

export const questionTypeFromTemplateType = (type: ETemplateType): EQuestionType => {
    switch (type) {
        case ETemplateType.GENERIC:
            return EQuestionType.GENERIC;
        case ETemplateType.LPA:
            return EQuestionType.LPA;
        case ETemplateType.SL:
        default:
            return EQuestionType.SL;
    }
};

export const answerTypeFromTemplateType = (type: ETemplateType): EAnswerType => {
    switch (type) {
        case ETemplateType.GENERIC:
            return EAnswerType.GENERIC;
        case ETemplateType.LPA:
            return EAnswerType.LPA;
        case ETemplateType.SL:
        default:
            return EAnswerType.SL;
    }
};

export interface IMetadata {
    articles?: IRelatedItem[];
    themes?: IReferencial[];
    vehicles?: IInvestmentVehicle[];
    areaOfResponsibility?: IReferencial[];
}

export interface ICommonNegotiationData {
    id?: string;
    originalAuthorId?: string;
    author: IUserBrief;
    dateModified?: Date;
    metadata?: IMetadata;
}

export interface ICompositeAttachment {
    asRelatedItem: IRelatedItem;
    asAttachment: IAttachment;
}

export interface IProvision extends ICommonNegotiationData {
    id: string;
    businessId: IBusinessId;
    investmentVehiclesIds: string[];
    mainQuestionId: string;
    mfnType: EMFN;
    organizationId: string;
    parentId: string;
    parentSourceType: string;
    threadId: string;
    usedInIds: string[];
    templateType: string;
    relatedItems: IRelatedItem[];
    questionType: EQuestionType;
    questionCategory: EQuestionCategory;
    attachments?: ICompositeAttachment[];
    metadata?: IMetadata;
}

export interface IQuestionGeneric extends ICommonNegotiationData {
    type: EQuestionType;
    text: string;
    clusterId: string;
    acceptanceStatus?: EAcceptanceStatus;
    attachments?: ICompositeAttachment[];
    businessId?: IBusinessId;
    mainQuestionId?: string;
    teamChannelId?: string;
    investorId?: string;
    relatedItems?: IRelatedItem[];
    threadId?: string;
}

export interface IQuestionLPA extends IQuestionGeneric {}
export interface IQuestionSL extends IQuestionGeneric {}

export type NegotiationQuestion = IQuestionGeneric | IQuestionLPA | IQuestionSL;

export interface IAnswerGeneric extends ICommonNegotiationData {
    type: EAnswerType;
    text: string;
    clusterId?: string;
    attachments?: ICompositeAttachment[];
    bottomContent?: React.ReactNode;
    acceptanceStatus?: EAcceptanceStatus;
    mainQuestionId?: string;
    relatedItems?: IRelatedItem[];
    conditionsIds?: string[];
    conditions?: ICondition[];
    qualification?: ETypes;
}

export interface IAnswerLPA extends IAnswerGeneric {}

export interface IAnswerSL extends IAnswerGeneric {
    provisions?: IProvision[];
}

export type NegotiationAnswer = IAnswerGeneric | IAnswerLPA | IAnswerSL;

export interface INegotiationPreparedRequest {
    data: NegotiationQuestion | NegotiationAnswer;
    mainQuestionId?: string;
    questionId?: string;
    organizationId?: string;
    clusterId?: string;
    isAnswer?: boolean;
    isQuestion?: boolean;
    sub?: boolean;
}

export interface IQuestionAnswer {
    question: NegotiationQuestion;
    answer?: NegotiationAnswer;
    index: number;
}

export interface INegotiationData {
    questionAnswers: IQuestionAnswer[];
}
