import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { EHintType, Hint } from '../Hint/Hint';
import { LabelWithEllipsis } from '../Label/Label';
import { MandatoryFieldStar } from '../MandatoryFieldStar/MandatoryFieldStar';
import { DropDown, EDropDownType, IDropDownProps } from '../DropDown/DropDown';
import { IValue } from '../../../entities/IPickers';

const Inner = styled.div<{ disabled: boolean }>`
    border: 2px ${colorStack.ligthGrey} solid;
    background: ${colorStack.white};
    padding: .5rem;
    border-radius: 8px;
    ${props => props.disabled && css`
        background: ${colorStack.whiteGrey};
        color: ${colorStack.disabled};
        &:hover {
            border: 2px solid ${colorStack.ligthGrey} !important;
        }
        &:focus {
            border: 2px solid ${colorStack.ligthGrey} !important;
        }
        &:active {
            border: 2px solid ${colorStack.ligthGrey} !important;
        }
    `}
`;

const Wrapper = styled.div<{ disabled?: boolean; $width?: number | 'auto'; error: boolean }>`
    position: relative;
    ${props =>
        props.$width && typeof props.$width === 'number'
            ? css`
                  width: ${`${props.$width}px`};
              `
            : css`
                  width: auto;
              `}
    > div {
        width: 100%;
    }
    .custom-input {
        max-width: 100%;
        > span {
            border: none;
        }
    }
`;

export type ISelectInputProps = IDropDownProps & {
    inputLabel?: React.ReactNode;
    required?: boolean;
    tooltipMsg?: string;
    tooltipTitle?: string;
    dropdownWidth?: number;
    errorMessage?: string;
    disabled?: boolean;
};

export const SelectInput: FC<React.PropsWithChildren<ISelectInputProps>> = props => {

    const handleChange = (item: IValue) => {
        props.onChange(item);
    };

    const getDropDownType = () => {
        if (props.type === EDropDownType.STATUS) return props.type;
        if (props.type === EDropDownType.MENU) return props.type;
        if (props.options?.length > 7) {
            return EDropDownType.SEARCH;
        } else {
            return EDropDownType.DEFAULT;
        }
    };

    return (
        <Wrapper disabled={props.disabled} error={!!props.errorMessage} $width={props.dropdownWidth} data-lc="js-lc-select-input">
            {props.inputLabel && (
                <LabelWithEllipsis tooltipMsg={props.tooltipMsg} tooltipTitle={props.tooltipTitle} numberOfLines={3}>
                    {props.inputLabel}
                    {props.required && <MandatoryFieldStar />}
                </LabelWithEllipsis>
            )}
            <Inner disabled={props.disabled}>
                <DropDown
                    value={props.value}
                    disabled={props.disabled}
                    type={getDropDownType()}
                    onChange={handleChange}
                    options={props.options}
                    openDropdownToTop={props.openDropdownToTop}
                />
            </Inner>
            {props.errorMessage && (
                <>
                    <Hint type={EHintType.ERROR}>{props.errorMessage}</Hint>
                </>
            )}
        </Wrapper>
    );
};
