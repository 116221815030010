import axios, { AxiosResponse, AxiosError, AxiosProgressEvent } from 'axios';
import { get } from 'lodash';
import { HubConnection } from '@microsoft/signalr';

import { IState } from '../reducers/index';
import * as contextActions from '../actions/contextActions';
import * as globalActions from '../actions/globalActions';
import { B2CToken, redirectToLogin } from './authTools';
import { CustomAxiosErrorResponseData } from '../services/ApiTypes';
import { refreshB2CToken } from '../actions/actionTypes/authActions';
import { addData, clearData, EDBNames, getStoreDataByKey, Stores } from './db';

const REFRESH_THRESHOLD = 300; // 5 minutes in seconds

const getErrorMessage = (error: AxiosError): string => {
    const data = error?.response?.data as CustomAxiosErrorResponseData;
    const errorMessage = data?.Message || get(error, 'response.data.message');
    return errorMessage || JSON.stringify(error);
};

const getHeaders = (subKey: string = '', token: string = '', additionalHeaders?: { [key: string]: string }) => ({
    'Ocp-Apim-Subscription-Key': subKey,
    'Authorization': `Bearer ${token}`,
    'Cache-Control': 'no-cache, no-store',
    'Pragma': 'no-cache',
    'Time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...additionalHeaders
});

export type ApiService = 'apiUrl' |
    'cmsApiUrl' |
    'notificationUrl' |
    'paymentUrl' |
    'equitisApiUrl' |
    'rfpApiUrl' |
    'messagingApiUrl' |
    'signalRUri' |
    'clustersApiUrl' |
    'organizationsApiUrl' |
    'panelsApiUrl' |
    'permissionsApiUrl' |
    'languageApiUrl' |
    'profilesApiUrl' |
    'commonApiUrl' |
    'validationsApiUrl' |
    'legalDocApiUrl';

export type ApiKey = 'subKey' | 'subKeyPaymentApi' | 'subKeyCms';

const apiRequest = (method: string, service: ApiService, key: ApiKey = 'subKey') => (store) => (path: string, data?: any, getCancelSource?: (cancel: () => void) => void, timeout?: number, eStoreName?: Stores) => new Promise(async (resolve, reject) => {
    const state = store.getState() as IState;
    const { token, tokenExpiration, refreshToken } = state.context;
    const { appConfig: { applicationConfig } } = state.config;
    const apiUrl = state.config[service];
    const subKey = state.config[key];
    await store.dispatch(globalActions.setProgressData(0));
    if (tokenExpiration - Math.floor(Date.now() / 1000) < REFRESH_THRESHOLD) {
        refreshB2CToken(refreshToken, applicationConfig).then((refreshTokenResponse: Response) => {
            if (refreshTokenResponse.status === 200) {
                refreshTokenResponse.json().then((refreshTokenData: B2CToken) => {
                    if (refreshTokenData.access_token && refreshTokenData.refresh_token) {
                        store.dispatch(contextActions.setAccessToken(refreshTokenData.access_token, refreshTokenData.refresh_token));
                    } else {
                        redirectToLogin();
                    }
                });
            } else {
                redirectToLogin();
            }
        });
    }

    const eTag: string = eStoreName ? await getStoreDataByKey(eStoreName, 'ETagId') : '';
    const params = (tokenString) => [apiUrl + path, data, {
        headers: getHeaders(subKey, tokenString || '', eTag ? { Etag: eTag } : {}),
        timeout,
        cancelToken: new axios.CancelToken(cancel => getCancelSource && getCancelSource(cancel)),
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let precentage = Math.floor((loaded * 100) / total);
            store.dispatch(globalActions.setProgressData(precentage));
        }
    }];

    const paramsGet = (tokenString) => [apiUrl + path, {
        headers: getHeaders(subKey, tokenString || '', eTag ? { Etag: eTag } : {}),
        timeout,
        cancelToken: new axios.CancelToken(cancel => getCancelSource && getCancelSource(cancel)),
        ...(data ? { params: data } : {})
    }];

    const paramsDelete = (tokenString) => [apiUrl + path, {
        data: data,
        timeout,
        headers: getHeaders(subKey, tokenString || '', eTag ? { Etag: eTag } : {}),
        cancelToken: new axios.CancelToken(cancel => getCancelSource && getCancelSource(cancel))
    }];

    axios[method](...(((method === 'get' && paramsGet(token)) || (method === 'delete' && paramsDelete(token)) || params(token))))
        .then(async (res: AxiosResponse) => {
            state.context.tokenReloading && store.dispatch(contextActions.tokenReload(false));
            if (eStoreName) {
                await clearData(eStoreName, EDBNames.REFERENTIALSDB);
                await addData(eStoreName, res.headers.etag, 'ETagId', EDBNames.REFERENTIALSDB);
            }
            return resolve(res.data);
        })
        .catch((error: AxiosError) => {
            if (get(error, 'response.status') === 401 && state.context.isLoggedIn) {
                redirectToLogin();
            }

            const dataErrorObj = error.response?.data as CustomAxiosErrorResponseData;
            if (dataErrorObj?.ErrorCode > 0 || dataErrorObj?.errorCode > 0 || get(error, 'response.data')?.[0]?.errorCode > 0) {
                reject(get(error, 'response'));
            } else {
                if (error?.message !== undefined && (error?.toJSON() as any)?.message === 'Network Error') {
                    reject('Network Error');
                } else {
                    reject(getErrorMessage(error));
                }
            }
        });
});

const apiUploadRequest = (service: ApiService = 'apiUrl') => (store) => (path: string, data: any, onUploadProgress: (e: AxiosProgressEvent) => void) => new Promise((resolve, reject) => {
    const state = store.getState() as IState;
    const { subKey } = state.config;
    const { token } = state.context;
    const api = state.config[service];

    axios.post(api + path, data, {
        headers: getHeaders(subKey, token || ''),
        onUploadProgress
    })
        .then((res: AxiosResponse) => resolve(res.data))
        .catch((error: AxiosError) => {
            if (error.response.status === 401 && state.context.isLoggedIn) {
                redirectToLogin();
            }
            reject(getErrorMessage(error));
        });
});

export const apiPost = apiRequest('post', 'apiUrl');
export const apiGet = apiRequest('get', 'apiUrl');
export const apiPatch = apiRequest('patch', 'apiUrl');
export const apiPut = apiRequest('put', 'apiUrl');
export const apiDelete = apiRequest('delete', 'apiUrl');

export const apiUpload = apiUploadRequest();
export const rfpApiUpload = apiUploadRequest('rfpApiUrl');

export const notificationApiGet = apiRequest('get', 'notificationUrl');
export const notificationApiPut = apiRequest('put', 'notificationUrl');
export const notificationApiPost = apiRequest('post', 'notificationUrl');

export const messagingApiGet = apiRequest('get', 'messagingApiUrl');
export const messagingApiPost = apiRequest('post', 'messagingApiUrl');
export const messagingApiDelete = apiRequest('delete', 'messagingApiUrl');
export const messagingApiPut = apiRequest('put', 'messagingApiUrl');

export const paymentApiGet = apiRequest('get', 'paymentUrl', 'subKeyPaymentApi');
export const paymentApiPost = apiRequest('post', 'paymentUrl', 'subKeyPaymentApi');
export const paymentApiPut = apiRequest('put', 'paymentUrl', 'subKeyPaymentApi');
export const paymentApiDelete = apiRequest('delete', 'paymentUrl', 'subKeyPaymentApi');

export const cmsApiGet = apiRequest('get', 'cmsApiUrl', 'subKeyCms');
export const cmsApiPost = apiRequest('post', 'cmsApiUrl', 'subKeyCms');
export const cmsApiPut = apiRequest('put', 'cmsApiUrl', 'subKeyCms');
export const cmsApiDelete = apiRequest('delete', 'cmsApiUrl', 'subKeyCms');

export const equitisApiPost = apiRequest('post', 'equitisApiUrl');
export const equitisApiGet = apiRequest('get', 'equitisApiUrl');
export const equitisApiPatch = apiRequest('patch', 'equitisApiUrl');
export const equitisApiPut = apiRequest('put', 'equitisApiUrl');

export const rfpApiPost = apiRequest('post', 'rfpApiUrl');
export const rfpApiGet = apiRequest('get', 'rfpApiUrl');
export const rfpApiPatch = apiRequest('patch', 'rfpApiUrl');
export const rfpApiPut = apiRequest('put', 'rfpApiUrl');

export const organizationsApiPost = apiRequest('post', 'organizationsApiUrl');
export const organizationsApiGet = apiRequest('get', 'organizationsApiUrl');
export const organizationsApiPatch = apiRequest('patch', 'organizationsApiUrl');
export const organizationsApiPut = apiRequest('put', 'organizationsApiUrl');
export const organizationsApiDelete = apiRequest('delete', 'organizationsApiUrl');

export const clustersApiUpload = apiUploadRequest('clustersApiUrl');
export const clustersApiPost = apiRequest('post', 'clustersApiUrl');
export const clustersApiGet = apiRequest('get', 'clustersApiUrl');
export const clustersApiPatch = apiRequest('patch', 'clustersApiUrl');
export const clustersApiPut = apiRequest('put', 'clustersApiUrl');
export const clustersApiDelete = apiRequest('delete', 'clustersApiUrl');

export const panelsApiPost = apiRequest('post', 'panelsApiUrl');
export const panelsApiGet = apiRequest('get', 'panelsApiUrl');
export const panelsApiPatch = apiRequest('patch', 'panelsApiUrl');
export const panelsApiPut = apiRequest('put', 'panelsApiUrl');
export const panelsApiDelete = apiRequest('delete', 'panelsApiUrl');

export const languageApiPost = apiRequest('post', 'languageApiUrl');
export const languageApiGet = apiRequest('get', 'languageApiUrl');
export const languageApiPatch = apiRequest('patch', 'languageApiUrl');
export const languageApiPut = apiRequest('put', 'languageApiUrl');
export const languageApiDelete = apiRequest('delete', 'languageApiUrl');

export const sogeApiPost = apiRequest('post', 'legalDocApiUrl');
export const sogeApiGet = apiRequest('get', 'legalDocApiUrl');
export const sogeApiPatch = apiRequest('patch', 'legalDocApiUrl');
export const sogeApiPut = apiRequest('put', 'legalDocApiUrl');
export const sogeApiDelete = apiRequest('delete', 'legalDocApiUrl');

export const permissionsApiPost = apiRequest('post', 'permissionsApiUrl');
export const permissionsApiGet = apiRequest('get', 'permissionsApiUrl');
export const permissionsApiPatch = apiRequest('patch', 'permissionsApiUrl');
export const permissionsApiPut = apiRequest('put', 'permissionsApiUrl');
export const permissionsApiDelete = apiRequest('delete', 'permissionsApiUrl');

export const profilesApiPost = apiRequest('post', 'profilesApiUrl');
export const profilesApiGet = apiRequest('get', 'profilesApiUrl');
export const profilesApiPatch = apiRequest('patch', 'profilesApiUrl');
export const profilesApiPut = apiRequest('put', 'profilesApiUrl');
export const profilesApiDelete = apiRequest('delete', 'profilesApiUrl');

export const validationsApiPost = apiRequest('post', 'validationsApiUrl');
export const validationsApiGet = apiRequest('get', 'validationsApiUrl');
export const validationsApiPatch = apiRequest('patch', 'validationsApiUrl');
export const validationsApiPut = apiRequest('put', 'validationsApiUrl');
export const validationsApiDelete = apiRequest('delete', 'validationsApiUrl');

export const commonApiGet = apiRequest('get', 'commonApiUrl');
export const commonApiPatch = apiRequest('patch', 'commonApiUrl');
export const commonApiPut = apiRequest('put', 'commonApiUrl');
export const commonApiDelete = apiRequest('delete', 'commonApiUrl');
export const commonApiPost = apiRequest('post', 'commonApiUrl');

type Request = <T = any>(path: string, data?: any, getCancelSource?: (cancel: () => void) => void, timeout?: number, eStoreName?: string) => Promise<T>;

export interface Http {
    apiPost: Request;
    apiGet: Request;
    apiPatch: Request;
    apiPut: Request;
    apiDelete: Request;
    apiUpload: (path: string, data: any, progressCallback: (e: ProgressEvent) => void) => Promise<any>;
    notificationApiGet: Request;
    notificationApiPut: Request;
    notificationApiPost: Request;
    messagingApiGet: Request;
    messagingApiPut: Request;
    messagingApiPost: Request;
    messagingApiDelete: Request;
    paymentApiGet: Request;
    paymentApiPost: Request;
    paymentApiPut: Request;
    paymentApiDelete: Request;
    cmsApiGet: Request;
    cmsApiPost: Request;
    cmsApiPut: Request;
    cmsApiDelete: Request;
    equitisApiPost: Request;
    equitisApiGet: Request;
    equitisApiPatch: Request;
    equitisApiPut: Request;
    rfpApiPost: Request;
    rfpApiGet: Request;
    rfpApiPatch: Request;
    rfpApiPut: Request;
    rfpApiUpload: (path: string, data: any, progressCallback: (e: ProgressEvent) => void) => Promise<any>;
    organizationsApiPost: Request;
    organizationsApiGet: Request;
    organizationsApiPatch: Request;
    organizationsApiPut: Request;
    organizationsApiDelete: Request;
    clustersApiUpload: (path: string, data: any, progressCallback: (e: ProgressEvent) => void) => Promise<any>;
    clustersApiPost: Request;
    clustersApiGet: Request;
    clustersApiPatch: Request;
    clustersApiPut: Request;
    clustersApiDelete: Request;
    panelsApiPost: Request;
    panelsApiGet: Request;
    panelsApiPatch: Request;
    panelsApiPut: Request;
    panelsApiDelete: Request;
    languageApiPost: Request;
    languageApiGet: Request;
    languageApiPatch: Request;
    languageApiPut: Request;
    languageApiDelete: Request;
    sogeApiPost: Request;
    sogeApiGet: Request;
    sogeApiPatch: Request;
    sogeApiPut: Request;
    sogeApiDelete: Request;
    permissionsApiPost: Request;
    permissionsApiGet: Request;
    permissionsApiPatch: Request;
    permissionsApiPut: Request;
    permissionsApiDelete: Request;
    profilesApiPost: Request;
    profilesApiGet: Request;
    profilesApiPatch: Request;
    profilesApiPut: Request;
    profilesApiDelete: Request;
    validationsApiPost: Request;
    validationsApiGet: Request;
    validationsApiPatch: Request;
    validationsApiPut: Request;
    validationsApiDelete: Request;
    commonApiPost: Request;
    commonApiGet: Request;
    commonApiiPatch: Request;
    commonApiPut: Request;
    commonApiDelete: Request;
    signalRConnection: HubConnection;
}
