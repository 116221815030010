import { IFormData } from '../../../../entities/IDeclaration';
import { EProfileType, IViewReferential } from '../../../../entities/IGlobal';
import { ISIngleLegalEntity } from '../../../../entities/ILegalEntities';

export const initialFormValues = (currentUserProfile, defaultCurrency: IViewReferential): IFormData => {
    return {
        formData: {
            declarationType: '',
            declaration: {
                type: '',
                nature: undefined,
                declarationDate: undefined,
                value: undefined,
                amount: {
                    number: undefined,
                    unit: defaultCurrency,
                    baseNumber: 0,
                    baseUnit: undefined
                },
                attachments: [],
                context: ''
            },
            thirdParties: [{
                userData: {
                    firstName: '',
                    lastName: '',
                    picture: '',
                    Address: '',
                    DataType: '',
                    IsPublic: true,
                    LegalForm: {
                        id: '',
                        Name: '',
                        Parents: []
                    },
                    Name: '',
                    Picture: '',
                    Size: '',
                    TypeOfLegalEntity: {
                        id: '',
                        Name: '',
                        Parents: []
                    },
                    id: ''
                },
                fullName: '',
                jobTitle: '',
                legalEntity: undefined,
                email: '',
                relationshipNature: undefined,
                sensitivePerson: undefined,
                userId: undefined
            }],
            information: {
                fullName: `${currentUserProfile?.firstName} ${currentUserProfile?.lastName}`.trim(),
                firstName: currentUserProfile?.firstName,
                lastName: currentUserProfile?.lastName,
                jobTitle: currentUserProfile?.jobTitle,
                picture: currentUserProfile?.picture,
                legalEntity: currentUserProfile?.cabinet ? {
                    name: currentUserProfile?.cabinet?.Name || currentUserProfile?.cabinet?.name,
                    picture: currentUserProfile?.cabinet?.Picture || currentUserProfile?.cabinet?.picture,
                    id: currentUserProfile?.cabinet?.id,
                    type: EProfileType.Company
                } as ISIngleLegalEntity : undefined,
                manager: currentUserProfile?.manager ? {
                    id: currentUserProfile?.manager?.id,
                    firstName: currentUserProfile?.manager?.firstName,
                    lastName: currentUserProfile?.manager?.lastName,
                    picture: currentUserProfile?.manager?.picture,
                    jobTitle: currentUserProfile?.manager?.jobTitle,
                    cabinet: currentUserProfile?.manager?.cabinet
                } : undefined,
                userId: currentUserProfile?.id
            },
            message: {
                subject: '',
                body: ''
            }
        },
        step: 1,
        amountValue: undefined,
        readPolicy: false,
        contextLength: false,
        tableLoaded: false
    };
};
