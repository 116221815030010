import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import IntlMessage, { useIntlMessage } from '../../../Common/IntlMessage';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    span {
        margin: 0 0 .5rem 0;
        &:nth-child(1),
        &:nth-child(2) {
            color: ${colorStack.darkGrey};
            > span {
                color: ${colorStack.middleBlue};
            }
        }
    }
`;

interface IProps {
    clusterName: string;
}

export const Confirm: FC<React.PropsWithChildren<IProps>> = props => {
    const { intlFormatMessage } = useIntlMessage();
    return (
        <Wrapper>
            <span><IntlMessage id="general.wizard.confirm.text1" /></span>
            <span dangerouslySetInnerHTML={{
                __html: intlFormatMessage({ id: 'general.wizard.confirm.text2' }, {
                    clusterName: props.clusterName
                })
            }} />
            <span><IntlMessage id="general.wizard.confirm.text3" /></span>
        </Wrapper>
    );
};

export default Confirm;
