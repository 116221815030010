import React, { FC, useCallback, MouseEvent, useRef, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colorStack } from '../../../styleHelpers/colors';
import { ILevel1MenuItem } from '../../../entities/IMenu';
import { WorkspaceLogo } from '../../Common/WorkspaceLogo/WorkspaceLogo';
import { clearPdfOverlay } from '../../..//actions/pdfOverlayActions';
import { ToolTipWrapper } from '../../Common/ToolTip/ToolTipWrapper';
import { camelize } from '../../../tools/string';
import { ToolTip } from '../../Common/ToolTip/ToolTip';

const Wrapper = styled(Link) <{ $isHidden: boolean }>`
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    min-height: 52px;
    padding: .5rem 1.1rem;
    transition: background-color .2s ease;
    &:hover {
        text-decoration: none;
        background-color: ${colorStack.lightBlue};
    }
    ${({ $isHidden }) => $isHidden && css`
        visibility: hidden;
    `}
    &.first-item {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 10%;
            width: 80%;
            height: 3px;
            border-radius: 20px;
            background: ${colorStack.bodyBg};
        }
    }
    &.last-item {
        margin-bottom: 0.5rem;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 10%;
            width: 80%;
            height: 3px;
            border-radius: 20px;
            background: ${colorStack.bodyBg};
        }
    }
`;

const Icon = styled(FontAwesomeIcon)`
    min-width: 32px;
`;

const ContextInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const ContextName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    margin-left: .5rem;
    max-width: 15rem;
    text-overflow: ellipsis;
`;

const TooltipMenuWrapper = styled.div`
    margin: 0px;
    position: relative;
`;

interface IMenuItem extends ILevel1MenuItem {
    isHidden?: boolean;
    onClick?(orgUrlName: string);
}

export const MenuItem: FC<React.PropsWithChildren<IMenuItem>> = ({ imagePath, faIcon, svgIcon, name, link, orgUrlName, isHidden, fullMenu, onClick, className }) => {
    const dispatch = useDispatch();
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [coords, setCoords] = useState<number[]>([]);

    const onElementClick = useCallback(() => {
        dispatch(clearPdfOverlay());
        onClick?.(orgUrlName);
    }, [orgUrlName, onClick]);

    const menuItemRef = useRef<HTMLDivElement>();
    const isLongText = useMemo(() => {
        return menuItemRef?.current?.scrollWidth > menuItemRef?.current?.offsetWidth;
    }, [menuItemRef?.current]);

    const showTooltipHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        setCoords([e.pageX - (menuItemRef?.current?.offsetWidth / 2), menuItemRef?.current?.getBoundingClientRect().top + 30]);
        setShowTooltip(true);
    };

    return (
        <Wrapper onClick={onElementClick} to={link || '#'} $isHidden={isHidden} className={`lc-st-sidebar-${name ? camelize(name) : ''} ${className?.length > 0 ? className : ''}`}>
            {svgIcon ? (
                <>{svgIcon}</>
            ) : faIcon ? (
                <Icon icon={faIcon} size="lg" />
            ) : (
                <WorkspaceLogo
                    picture={imagePath}
                    name={name}
                    id={name}
                    size={{ width: 32, height: 32 }}
                />
            )}
            <ContextInfoWrapper>
                {(fullMenu && name) && (
                    <TooltipMenuWrapper>
                        <ContextName
                            ref={menuItemRef}
                            onMouseEnter={showTooltipHandler}
                            onMouseLeave={() => setShowTooltip(false)}
                            onMouseMove={showTooltipHandler}
                        >
                            {name}
                        </ContextName>
                    </TooltipMenuWrapper>
                )}
            </ContextInfoWrapper>
            {(isLongText && showTooltip) && <ToolTip title={name} left={coords[0]} top={coords[1]} />}
        </Wrapper>
    );
};
