import React, { useState, useCallback, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import { IState } from '../../../../reducers';
import { IProfileReducer } from '../../../../reducers/profileReducer';
import { IFormData, EDeclarationTypeEnum } from '../../../../entities/IDeclaration';
import { EmailRegExp } from '../../../../tools/emailChecker';
import { StepsPopup } from '../../../Common/StepsPopup/StepsPopup';
import { DeclarationType } from './DeclarationType';
import { Principles } from './Principles';
import { Description } from './Description';
import { Beneficiary } from './Beneficiary';
import { YourInformation } from './YourInformation';
import { SuccessfulSubmission } from './SuccessfulSubmission';
import { initialFormValues } from './InitialFormValues';
import { EPopupSizes } from '../../../../entities/IGlobal';
import { postGiftInvitationForm } from '../../../../actions/clustersActions';
import { ETemplateTypes, ISingleCluster } from '../../../../entities/IClusters';
import { useOrganization } from '../../../../tools/organizationHooks';
import { EPanelUrlParams } from '../../../Common/Panel/PanelContainer';
import { useIntlMessage } from '../../../Common/IntlMessage';
import BeneficiaryTable from './BeneficiaryTable';
import DonorTable from './DonorTable';

type PostGiftInvitationForm = ReturnType<typeof postGiftInvitationForm>;

interface IProps {
    organizationId: string;
    organizationUrlName: string;
    isPopupShown: boolean;
    isHome?: boolean;
    setIsGiftInvitationPopupOn(giftInvitationPopupOn: boolean);
}

export const GiftInvitationPopup: FC<React.PropsWithChildren<IProps>> = ({ isHome, isPopupShown, organizationId, organizationUrlName, setIsGiftInvitationPopupOn }) => {
    const dispatch = useDispatch();
    const { intlFormatMessage } = useIntlMessage();
    const history = useNavigate();
    const { organization } = useParams<{ organization: string }>();
    const { currentOrganization } = useOrganization(organization);
    const { currentUserProfile } = useSelector<IState, IProfileReducer>(state => state.profile);
    const [stepFinished, setStepFinished] = useState<boolean>(false);
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(true);
    const [isFormFinnished, setIsFormFinnished] = useState<boolean>(false);
    const [cumulatedObject, setCumulatedObject] = useState<ISingleCluster>(undefined);

    const closePopup = (resetForm) => {
        setIsGiftInvitationPopupOn(false);
        setStepFinished(false);
        resetForm();
        if (isFormFinnished) {
            history(`/orgs/${organizationUrlName}/dashboard?${EPanelUrlParams.REFRESH}=true`);
            setIsFormFinnished(false);
        } else if (isHome) {
            history('/');
        }
    };

    const submitFormData = (form: IFormData) => {
        setIsConfirmButtonDisabled(true);
        return dispatch<PostGiftInvitationForm>(postGiftInvitationForm(organizationId, form, cumulatedObject)).then(() => {
            setIsConfirmButtonDisabled(false);
            setStepFinished(true);
            setIsFormFinnished(true);
        }).catch(reason => {
            console.log('Declaration not sent: ', reason);
        });
    };

    const clearForm = useCallback((resetForm) => {
        resetForm();
        setStepFinished(false);
    }, [setStepFinished, organizationId]);

    return (
        <Formik<IFormData>
            initialValues={initialFormValues(currentUserProfile, currentOrganization?.details?.defaultCurrency)}
            validationSchema={() => Yup.lazy((values: any) => {
                const stepNr = values.step;
                return Yup.object().shape({
                    formData: Yup.object().shape({
                        declarationType: Yup.string().required(),
                        declaration: stepNr === 3 && Yup.object({
                            type: Yup.string().required(),
                            nature: Yup.string().required(),
                            value: Yup.string().required(),
                            declarationDate: Yup.date().required(),
                            amount: Yup.object({
                                number: Yup.number().required(),
                                unit: Yup.string().required()
                            })
                        }),
                        thirdParties: stepNr === 4 && Yup.array().of(
                            Yup.object().shape({
                                fullName: Yup.string().required(),
                                userData: Yup.object().shape({
                                    id: Yup.string().required()
                                }),
                                legalEntity: Yup.object().shape({
                                    name: Yup.string().required(),
                                    id: Yup.string().required()
                                }),
                                email: Yup.string()
                                    .matches(EmailRegExp)
                                    .required(),
                                relationshipNature: Yup.object().required(),
                                sensitivePerson: Yup.boolean().required()
                            })
                        ),
                        information: stepNr === 5 && Yup.object({
                            fullName: Yup.string().required(),
                            manager: Yup.object().shape({
                                id: Yup.string().required()
                            }),
                            legalEntity: Yup.object().shape({
                                name: Yup.string().required(),
                                id: Yup.string().required()
                            })
                        })
                    }),
                    readPolicy: stepNr === 2 && Yup.boolean().oneOf([true]),
                    contextLength: stepNr === 3 && Yup.boolean().oneOf([true]),
                    tableLoaded: stepNr === 6 && Yup.boolean().oneOf([true])
                });
            })}
            enableReinitialize
            onSubmit={submitFormData}
        >
            {({ errors, values, isValid, dirty, submitForm, resetForm }) => (
                <StepsPopup
                    showPopup={isPopupShown}
                    size={EPopupSizes.BIG}
                    title={intlFormatMessage({
                        id: `${values.formData.declarationType === 'gift'
                            ? 'giftinvitation.step6.giftDeclaration' : values.formData.declarationType === 'invitation'
                                ? 'giftinvitation.step6.invitationDeclaration' : 'giftinvitation.popup.title'}`

                    })}
                    confirmDisabled={isConfirmButtonDisabled}
                    preventClosingOnClickingOutside
                    isFormValid={isValid}
                    dirty={dirty}
                    finishHandler={() => closePopup(resetForm)}
                    confirmFinished={stepFinished}
                    confirmHandler={submitForm}
                    showDeclareNew
                    clearForm={() => clearForm(resetForm)}
                    wizardType={ETemplateTypes.Gift}
                    steps={values.formData.declaration.type === EDeclarationTypeEnum.offeredGift || values.formData.declaration.type === EDeclarationTypeEnum.offeredInvitation ? [
                        {
                            title: intlFormatMessage({ id: 'giftinvitation.step1.title' }),
                            content: <DeclarationType organizationId={organizationId} />
                        },
                        {
                            title: intlFormatMessage({ id: 'giftinvitation.step2.title' }),
                            content: <Principles />
                        },
                        {
                            title: intlFormatMessage({ id: 'giftinvitation.step3.title' }),
                            content: <Description organizationId={organizationId} />
                        },
                        {
                            title: intlFormatMessage({
                                id: 'giftinvitation.step4.title.beneficiaries'
                            }),
                            content: <Beneficiary />
                        },
                        {
                            title: intlFormatMessage({ id: 'giftinvitation.step5.title' }),
                            content: <YourInformation legalEntityId={currentUserProfile?.cabinet?.id} />
                        },
                        {
                            content: <BeneficiaryTable setCumulatedObject={setCumulatedObject} />
                        },
                        {
                            title: intlFormatMessage({
                                id: 'giftinvitation.step6.success'
                            }),
                            content: <SuccessfulSubmission />
                        }
                    ] : [
                        {
                            title: intlFormatMessage({ id: 'giftinvitation.step1.title' }),
                            content: <DeclarationType organizationId={organizationId} />
                        },
                        {
                            title: intlFormatMessage({ id: 'giftinvitation.step2.title' }),
                            content: <Principles />
                        },
                        {
                            title: intlFormatMessage({ id: 'giftinvitation.step3.title' }),
                            content: <Description organizationId={organizationId} />
                        },
                        {
                            title: intlFormatMessage({
                                id: `${values.formData.declaration.type === EDeclarationTypeEnum.receivedGift
                                    || values.formData.declaration.type === EDeclarationTypeEnum.receivedInvitation
                                    ? 'giftinvitation.step4.title.donors' : 'giftinvitation.step4.title.beneficiaries'}`
                            }),
                            content: <Beneficiary />
                        },
                        {
                            title: intlFormatMessage({ id: 'giftinvitation.step5.title' }),
                            content: <YourInformation legalEntityId={currentUserProfile?.cabinet?.id} />
                        },
                        {
                            content: <DonorTable setCumulatedObject={setCumulatedObject} />
                        },
                        {
                            title: intlFormatMessage({
                                id: 'giftinvitation.step6.success'
                            }),
                            content: <SuccessfulSubmission />
                        }
                    ]}
                />
            )}
        </Formik>
    );
};
