import { action } from '../tools/reduxTools';
import { SET_BACKGROUND_DATATABLE_IDS, ADD_BACKGROUND_DATATABLE_ID, CLEAR_BACKGROUND_DATATABLE, CLEAR_ALL_BACKGROUND_DATATABLES } from './actionTypes/datatableTypes';

export const setBackgroundDatatableIds = (datatableName: string, ids: string[]) => action(async (dispatch) => {
    dispatch({ type: SET_BACKGROUND_DATATABLE_IDS, datatableName, backgroundDatatableIds: ids });
});

export const addBackgroundDatatableId = (datatableName: string, id: string) => action(async (dispatch) => {
    dispatch({ type: ADD_BACKGROUND_DATATABLE_ID, datatableName, backgroundDatatableId: id });
});

export const clearBackgroundDatatable = (datatableName: string) => action(async (dispatch) => {
    dispatch({ type: CLEAR_BACKGROUND_DATATABLE, datatableName });
});

export const clearBackgroundDatatables = () => action(async (dispatch) => {
    dispatch({ type: CLEAR_ALL_BACKGROUND_DATATABLES });
});