import React, { useState, useEffect, useRef, FC, Fragment } from 'react';
import styled, { css } from 'styled-components';
import Scrollbars from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faPipe, faSearch, faXmark } from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { colorStack } from '../../../styleHelpers/colors';
import { useDropdown } from '../../../tools/hooks';
import { NewCompanyForm } from './Components/NewCompanyForm';
import { NewUserForm } from './Components/NewUserForm';
import { LabelWithEllipsis } from '../Label/Label';
import { MandatoryFieldStar } from '../MandatoryFieldStar/MandatoryFieldStar';
import { EProfileType, EFilterType } from '../../../entities/IGlobal';
import { IValue } from '../../../entities/IPickers';
import { EPillColorSchema, EPillShape, Pill } from '../Pill/Pill';
import { ResultBox } from '../ResultsBox/ResultBox';
import { IItem } from '../../../entities/IFilters';
import { Badge, EBadgeColor } from '../Badge/Badge';
import { EHintType, Hint } from '../Hint/Hint';
import { SearchResults } from './Components/SearchResults';
import { EMAIL_INVITATION } from '../../../entities/IRoleManager';
import { checkUserInHrApi } from '../../../actions/profileActions';
import { useIntlMessage } from '../IntlMessage';

type CheckUserInHrApi = ReturnType<typeof checkUserInHrApi>;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    border-radius: 4px;
`;
const SearchResult = styled.div<{ openDropdownUpwards: boolean }>`
    width: 100%;
    position: absolute;
    left: 0;
    top: ${props => (props.openDropdownUpwards ? 'auto' : '100%')};
    bottom: ${props => (props.openDropdownUpwards ? '100%' : 'auto')};
    z-index: 99999;
`;

const TextWrapper = styled.div<{ selected: boolean; multiSelect: boolean; error: boolean; disabled: boolean, expand: boolean; noIcon: boolean, numberOfLines: number }>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: ${colorStack.content};
    height: 40px;
    min-height: 40px;
    background: ${colorStack.white};
    border: 2px solid ${colorStack.ligthGrey};
    border-radius: 8px;
    ${props => props.error && css`
        border: 2px solid ${colorStack.middleRed};
    `}
    ${props => props.disabled && css`
        background: ${colorStack.bodyBg};
    `}
    ${({ expand, numberOfLines }) => (expand && numberOfLines === 1) && css`
        height: 73px;
    `}
    ${({ expand, numberOfLines }) => (expand && numberOfLines > 1) && css`
        height: 113px;
    `}
    ${props => props.noIcon && css`
        padding: 0;
    `}
    :hover {
        border: 2px solid ${colorStack.middleBlue};
        ${props => props.disabled && css`
            border: 2px solid ${colorStack.ligthGrey};
        `}
    }
`;

const SelectedElemsList = styled.div<{ expand: boolean; disabled: boolean; leftIcon: boolean; }>`
    height: 32px;
    padding: 0 .5rem 0 0;
    max-width: 100%;
    width: 100%;
    display: flex;
    ${props => props.expand && css`
        height: auto;
        margin-bottom: .5rem;
    `}
    ${props => props.expand && css`
        margin-bottom: 0;
    `}
    ${props => props.leftIcon && css`
        padding-left: 12px;
    `}
`;

const RightIcons = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    position: absolute;
    right: 13px;
    top: 0;
    bottom: 0;
    margin: auto;
    > svg {
        cursor: pointer;
    }
`;

const Content = styled.div<{ expand: boolean; disabled: boolean; noIcon: boolean; }>`
    height: 100%;
    width: calc(100% - 50px - 16px); // 100% - right-width - left-width
    display: flex;
    align-items: center;
    padding: 2px .5rem 0 1.5rem;
    ${props => props.expand && css`
        flex-direction: column;
    `}
    > div:nth-child(2) {
        cursor: pointer;
    }
    ${props => props.expand && css`
        padding: 2px .5rem .5rem 1.5rem;
    `}
    ${props => props.noIcon && css`
        padding: 2px .5rem 0 0;
    `}
`;

const InputObject = styled.input<{ expand: boolean; }>`
    border: none!important;
    outline: none!important;
    width: auto !important;
    min-width: 104px!important;
    flex-grow: 1;
    font-weight: 400!important;
    font-size: 16px!important;
    line-height: 16px!important;
    &:hover {
        border: none!important;
        outline: none!important;
    }
    ${props => props.expand && css`
        width: 100%!important;
        font-weight: 400!important;
        min-height: 32px;
        padding: 0 0 0 0.5rem;
    `}
    &::placeholder {
        color: ${colorStack.middleGrey}!important;
        font-weight: 400!important;
        font-size: 16px!important;
        line-height: 16px!important;
        font-family: 'Roboto', sans-serif;
        &:disabled {
            color: ${colorStack.middleGrey}!important;
        }
    }
`;

const ScrollBox = styled.div<{ expand: boolean; numberOfLines: number }>`
    display: flex;
    padding: 0 .5rem;
    align-items: center;
    ${props => props.expand && css`
        padding: 0 24px 0 .5rem;
    `}
    ${props => (props.expand && props.numberOfLines === 1) && css`
        height: 73px;
    `}
    ${props => (props.expand && props.numberOfLines > 1) && css`
        height: 115px;
    `}
`;

const InnerBox = styled.div<{ expand?: boolean; fullwidth: boolean; multiSelect: boolean }>`
    display: flex;
    align-items: center;
    gap: 4px;
    width: calc(100% - 50px);
    flex-wrap: wrap;
    height: 32px;
    overflow: hidden;
    ${props => props.expand && css`
        overflow: visible;
        height: auto;
    `}
    ${props => props.fullwidth && css`
        width: 100%;
    `}

    ${props => !props.multiSelect && css`
        > div {
            width: 100%;
        }
    `};
    input[type=text] {
        padding: 0!important;
        font-weight: 400;
    }
`;

const SearchIcon = styled.div`
    position: absolute;
    left: 1rem;
`;

export enum EButtonsTypes {
    Person = 'Person',
    Company = 'Company',
    Theme = 'Theme',
    AreaOfResponsibility = 'AreaOfResponsibility',
    Invite = 'Invite',
    All = 'All'
}

export interface IPickerProps {
    value?: IValue[];
    parentRef?: React.RefObject<HTMLDivElement>;
    placeholder?: string;
    errorMsg?: string | React.ReactNode;
    disabled?: boolean;
    multiSelect?: boolean;
    pillColorSchema?: EPillColorSchema;
    hideButtons?: boolean;
    onlyDataFromForm?: boolean;
    label?: React.ReactNode;
    required?: boolean;
    data?: IValue[];
    buttonsType?: EButtonsTypes;
    oldVersion?: boolean;
    onSelectElement?(value: IValue[]);
    onSelectElementMulti?(value: IValue[]);
    getData?(query?: string): Promise<IValue[]>;
    createTheme?(inputValue: string): Promise<IValue>;
    createAreaOfResponsibility?(inputValue: string): Promise<IValue>;
}

let debounceTimeout: ReturnType<typeof setTimeout>;

export const CorePicker: FC<IPickerProps> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const dispatch = useDispatch();
    const selectedElemetsListRef = useRef<HTMLDivElement>();
    const selectedElemetsListInnerRef = useRef<HTMLDivElement>();
    const scrollBarsRef = useRef<Scrollbars>();
    const [wrapperRef, dropdownOpen, __, closeDropdown, openDropdown] = useDropdown(true);
    const [searchResults, setSearchResults] = useState<IValue[]>([]);
    const [showCompanyForm, setshowCompanyForm] = useState<boolean>(false);
    const [showUserForm, setshowUserForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [selectedElements, setSelectedElements] = useState<IValue[]>([]);
    const [selectedItems, setSelectedItems] = useState<IItem[]>([]);
    const [expand, setExpand] = useState<boolean>(false);
    const [openDropdownUpwards, setOpenDropdownUpwards] = useState<boolean>(false);
    const [hrApiData, setHrApiData] = useState<{
        firstName: string;
        lastName: string;
        email: string;
    }>(undefined);

    useEffect(() => {
        if (scrollBarsRef?.current && expand) {
            scrollBarsRef?.current?.scrollToBottom();
        }
        if (!!selectedElemetsListInnerRef && props.multiSelect && Array.from(selectedElemetsListInnerRef?.current?.children || [])?.filter((elem: any) => elem.offsetTop > 10)?.length > 1 && !dropdownOpen) {
            setExpand(true);
        } else {
            setExpand(false);
        }
    }, [selectedElements]);

    useEffect(() => {
        if (scrollBarsRef?.current && expand) {
            scrollBarsRef?.current?.scrollToBottom();
        }
    }, [scrollBarsRef?.current, expand]);

    useEffect(() => {
        if (!dropdownOpen && !props.value && showUserForm) {
            setInputValue(undefined);
        }
        if (!dropdownOpen) {
            setExpand(false);
        }
        if (props.oldVersion && !dropdownOpen) {
            setSelectedItems(selectedElements.map(elem => ({
                data: elem.data,
                id: elem.key,
                text: elem.text,
                translationKey: '',
                visible: true
            })));
        }
    }, [dropdownOpen]);

    useEffect(() => {
        if (((selectedElements && selectedElements.length === 0) || !selectedElements) && props.value?.filter(elem => elem !== null)?.length > 0) {
            setSelectedElements(props.value);
            setSelectedItems(props.value?.map(elem => ({
                visible: true,
                text: elem.text,
                id: elem.key,
                data: elem.data,
                translationKey: ''
            })));
        }

        if (!props.value || props.value?.length === 0) {
            setSelectedElements([]);
            setSelectedItems([]);
        }
    }, [props.value]);

    const getData = async (text: string) => {
        setLoading(true);
        setSearchResults([]);
        const results = await props.getData(text);
        setLoading(false);
        setSearchResults(results);

    };

    const selectElement = (e: React.MouseEvent<HTMLButtonElement>, item: IValue, isRemove?: boolean) => {
        e && closeDropdown();
        setSelectedItems(state => isRemove ? state.filter(elem => elem.id !== item.data.id) : _.uniqBy([...state, { text: item.text, id: item.key, data: item, visible: true, translationKey: '' }], (elem) => elem.id));
        setSelectedElements(state => isRemove ? state.filter(elem => elem.key !== item.data.id) : _.uniqBy([...state, item], (elem) => elem.key));
        setTimeout(() => {
            props.multiSelect ?
                props.onSelectElementMulti(isRemove ? selectedElements.filter(elem => elem.key !== item.data.id) : _.uniqBy([...selectedElements, item], (elem) => elem.key))
                :
                props.onSelectElement(isRemove ? selectedElements.filter(elem => elem.key !== item.data.id) : _.uniqBy([...selectedElements, item], (elem) => elem.key));
        }, 100);
        setSearchResults([]);
        setInputValue('');
        getData('');
    };

    const companyFormHandler = () => {
        setshowCompanyForm(state => !state);
        closeDropdown();
    };

    const userFormHandler = () => {
        dispatch<CheckUserInHrApi>(checkUserInHrApi(inputValue)).then((res) => {
            if (res.items.length > 0) {
                setHrApiData({
                    firstName: res.items[0].firstName,
                    lastName: res.items[0].lastName,
                    email: res.items[0].emailContact
                });
            }
            setshowUserForm(state => !state);
            closeDropdown();
        });
    };

    const closeUserRofmHandler = () => {
        setHrApiData(undefined);
        setshowUserForm(state => !state);
        closeDropdown();
    };

    const themeFormHandler = () => {
        props.createTheme(inputValue).then((themeValue: IValue) => {
            setInputValue('');
            selectElement(undefined, themeValue);
        });
    };

    const areaOfResponsibilityFormHandler = () => {
        props.createAreaOfResponsibility(inputValue).then((areaOfResponsibilityValue: IValue) => {
            setInputValue('');
            selectElement(undefined, areaOfResponsibilityValue);
        });
    };

    const selectItemsHandler = (item: IItem) => {
        const existItem = !!selectedItems.find(elem => elem.id === item.id);
        const finalData = [item].map(elem => ({
            key: elem.id,
            text: elem.text,
            data: { ...elem.data }
        }));
        if (props.multiSelect) {
            setSelectedItems(state => state.find(elem => elem.id === item.id) ? state.filter(elem => elem.id !== item.id) : [...state, item]);
            setSelectedElements(state => existItem ? state.filter(elem => elem.key !== item.id) : [...state, ...finalData]);
            props.onSelectElementMulti(existItem ? selectedElements.filter(elem => elem.key !== item.id) : [...selectedElements, ...finalData]);
            closeDropdown();
        } else {
            setSelectedItems(state => state.find(elem => elem.id === item.id) ? state.filter(elem => elem.id !== item.id) : [item]);
            setSelectedElements(state => existItem ? state.filter(elem => elem.key !== item.id) : (props.multiSelect ? [...state, ...finalData] : finalData));
            props.onSelectElement(existItem ? selectedElements.filter(elem => elem.key !== item.id) : finalData);
            closeDropdown();
        }
    };

    const autoSaveSelectItemsHandler = (item: IItem) => {
        const existItem = !!selectedItems.find(elem => elem.id === item.id);
        const finalData = [item].map(elem => ({
            key: elem.id,
            text: elem.text,
            data: { ...elem.data }
        }));
        setSelectedItems(state => existItem ? state.filter(elem => elem.id !== item.id) : (props.multiSelect ? [...state, item] : [item]));
        setSelectedElements(state => existItem ? state.filter(elem => elem.key !== item.id) : (props.multiSelect ? [...state, ...finalData] : finalData));
        props.multiSelect ?
            props.onSelectElementMulti(existItem ? selectedElements.filter(elem => elem.key !== item.id) : [...selectedElements, ...finalData])
            :
            props.onSelectElement(existItem ? selectedElements.filter(elem => elem.key !== item.id) : finalData);
        setInputValue('');
        getData('');
        closeDropdown();
    };

    const removeElement = (value: string) => {
        if (!props.disabled) {
            setSelectedItems(state => state.filter(elem => elem.id !== value));
            selectElement(undefined, {
                key: '',
                text: '',
                data: {
                    id: value
                }
            },
                true);
            setSelectedElements(state => state.filter(elem => elem.key !== value));
        }
    };

    const onClick = () => {
        if (!showCompanyForm && !showUserForm) {
            if (selectedElements?.length === 0 || props.multiSelect) {
                openDropdown();
            }
            if (props.multiSelect && !expand && Array.from(selectedElemetsListInnerRef?.current?.children)?.filter((elem: any) => elem.offsetTop > 10)?.length > 0) {
                setExpand(true);
            } else {
                setExpand(false);
            }
            getData(inputValue || '');
        }
    };

    const clearAllSelectedItems = () => {
        if (!props.disabled) {
            closeDropdown();
            setExpand(false);
            setSelectedItems([]);
            setSelectedElements([]);
            props.multiSelect ? props.onSelectElementMulti([]) : props.onSelectElement([]);
            setInputValue('');
            getData('');
        }
    };

    const saveHandler = () => {
        const finalData = selectedItems.map(elem => ({
            key: elem.id,
            text: elem.text,
            data: { ...elem.data }
        }));
        setSelectedElements(finalData);
        closeDropdown();
        props.multiSelect ? props.onSelectElementMulti(finalData) : props.onSelectElement(finalData);
    };

    useEffect(() => {
        if (dropdownOpen && wrapperRef.current && props.parentRef?.current) {
            const corePickerWrapper = wrapperRef.current.getBoundingClientRect();
            const parentWrapper = props.parentRef.current.getBoundingClientRect();
            setOpenDropdownUpwards(parentWrapper.bottom - corePickerWrapper.bottom < 200);
        }
    }, [dropdownOpen]);

    let pillColorSchema = props.pillColorSchema;
    if (!pillColorSchema) {
        pillColorSchema = props.multiSelect ? EPillColorSchema.BLUE : EPillColorSchema.TRANSPARENT;
    }

    const pillsList = selectedElements?.map((elem, index) =>
        <Fragment key={elem?.key || index}>
            {elem &&
                <>
                    {elem.data?.type?.toString()?.toLowerCase() === 'default' ? (
                        <Pill
                            key={elem.key}
                            text={`${elem.text || elem.data?.Name || elem.data?.name || elem?.data?.value || elem.data?.firstName} ${elem.data?.lastName ? elem?.data?.lastName : ''}`}
                            id={elem.key || elem.data?.id}
                            colorSchema={pillColorSchema}
                            onClick={props.multiSelect ? removeElement : undefined}
                            preventRedirection={elem.data?.id === EMAIL_INVITATION}
                        />
                    ) : (
                        <Pill
                            profileType={elem?.data?.type?.toString()?.toLowerCase()}
                            key={elem?.key}
                            colorSchema={pillColorSchema}
                            shape={(elem?.data?.type?.toString()?.toLowerCase() === EProfileType.Personal || elem?.data?.type?.toString()?.toLowerCase() === EProfileType.Group || !elem?.data?.type) ? EPillShape.CIRCLE : EPillShape.SQUARE}
                            picture={elem?.data?.picture || elem?.data?.Picture || undefined}
                            text={`${elem.text || elem?.data?.Name || elem?.data?.name || elem?.data?.value || elem?.data?.firstName}${(elem?.data?.lastName && !elem.text) ? ` ${elem?.data?.lastName}` : ''}`}
                            id={elem.key || elem?.data?.id || elem?.data?.Id}
                            onClick={props.multiSelect ? removeElement : undefined}
                            preventRedirection={elem.data?.id === EMAIL_INVITATION}
                        />
                    )}
                </>
            }
        </Fragment>
    );

    const changeMainInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(debounceTimeout);
        openDropdown();
        setInputValue(e.target.value);
        debounceTimeout = setTimeout(() => {
            getData(e.target.value);
        }, 500);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Tab' && (!props.multiSelect || selectedElements?.length < 2)) {
            e.preventDefault();
        } else if (e.key === 'Tab' && props.multiSelect) {
            setExpand(true);
        }
    };

    return (
        <Wrapper className="Wrapper">
            {props.label &&
                <LabelWithEllipsis>
                    {props.label}
                    {props.required &&
                        <MandatoryFieldStar />
                    }
                </LabelWithEllipsis>
            }
            <TextWrapper
                selected={!!selectedElements}
                numberOfLines={Array.from(selectedElemetsListInnerRef?.current?.children || [])?.filter((elem: any) => elem.offsetTop > 10)?.length}
                multiSelect={props.multiSelect}
                disabled={props.disabled}
                expand={expand}
                ref={wrapperRef}
                onKeyDown={handleKeyDown}
                error={!!props.errorMsg}
                noIcon={wrapperRef?.current && wrapperRef?.current?.getBoundingClientRect().width < 400}
                className="TextWrapper"
            >
                {(wrapperRef?.current && wrapperRef?.current?.getBoundingClientRect().width > 400) &&
                    <SearchIcon>
                        <FontAwesomeIcon icon={faSearch} color={colorStack.darkGrey} />
                    </SearchIcon>
                }
                <Scrollbars hideTracksWhenNotNeeded ref={scrollBarsRef}>
                    <ScrollBox expand={expand} numberOfLines={Array.from(selectedElemetsListInnerRef?.current?.children || [])?.filter((elem: any) => elem.offsetTop > 10)?.length} >
                        <Content expand={expand} disabled={props.disabled} noIcon={wrapperRef?.current && wrapperRef?.current?.getBoundingClientRect().width < 400}>
                            <SelectedElemsList
                                ref={selectedElemetsListRef}
                                disabled={props.disabled}
                                expand={expand}
                                leftIcon={wrapperRef?.current?.getBoundingClientRect().width > 400}
                                onClick={props.disabled ? undefined : onClick}
                            >
                                {!expand ? (
                                    <>
                                        <InnerBox multiSelect={props.multiSelect} ref={selectedElemetsListInnerRef} fullwidth={!(Array.from(selectedElemetsListInnerRef?.current?.children || [])?.filter((elem: any) => elem.offsetTop > 10)?.length > 1 && selectedElements?.length > 1)}>
                                            {pillsList}
                                            {(!props.disabled && !props.oldVersion) &&
                                                <InputObject disabled={props.disabled} type="text" value={inputValue} onChange={(showCompanyForm || showUserForm) ? undefined : changeMainInputValue} placeholder={props.placeholder || intlFormatMessage({ id: 'global.search' })} expand={expand} />
                                            }
                                        </InnerBox>
                                        {(Array.from(selectedElemetsListInnerRef?.current?.children || [])?.filter((elem: any) => elem.offsetTop > 10)?.length > 1 && selectedElements?.length > 1) &&
                                            <Badge
                                                color={EBadgeColor.GRAY}
                                                onClick={() => setExpand(state => !state)}
                                                placeholder={expand ? `${selectedElements?.length}` : `${Array.from(selectedElemetsListInnerRef?.current?.children || [])?.filter((elem: any) => elem.offsetTop > 10)?.length - 1}+`}
                                            />
                                        }
                                    </>
                                ) : (
                                    <InnerBox ref={selectedElemetsListInnerRef} expand={expand} fullwidth multiSelect={props.multiSelect}>
                                        {pillsList}
                                        {(!props.disabled && !props.oldVersion) &&
                                            <InputObject disabled={props.disabled} type="text" value={inputValue} onChange={(showCompanyForm || showUserForm) ? undefined : changeMainInputValue} placeholder={props.placeholder || intlFormatMessage({ id: 'global.search' })} expand={expand} />
                                        }
                                    </InnerBox>

                                )}
                            </SelectedElemsList>
                        </Content>
                    </ScrollBox>
                </Scrollbars>
                <RightIcons>
                    <FontAwesomeIcon icon={faXmark} color={colorStack.darkGrey} onClick={clearAllSelectedItems} />
                    <FontAwesomeIcon icon={faPipe} color={colorStack.ligthGrey} />
                    <FontAwesomeIcon icon={dropdownOpen ? faCaretUp : faCaretDown} color={colorStack.darkGrey} />
                </RightIcons>
            </TextWrapper>
            {dropdownOpen &&
                <SearchResult className="SearchResult" openDropdownUpwards={openDropdownUpwards}>
                    {props.oldVersion ? (
                        <ResultBox
                            loading={loading}
                            placeholder={props.placeholder}
                            buttonsType={props.buttonsType}
                            hideButtons={props.hideButtons}
                            items={searchResults.map(elem => ({
                                text: elem.text,
                                id: elem.key,
                                visible: true,
                                data: elem.data,
                                translationKey: ''
                            }))}
                            multiSelect={props.multiSelect}
                            outsideGetSearchValue={setInputValue}
                            selectedItems={selectedItems}
                            onSelectItem={selectItemsHandler}
                            userFormHandler={userFormHandler}
                            companyFormHandler={companyFormHandler}
                            onClear={clearAllSelectedItems}
                            onSave={saveHandler}
                            type={EFilterType.personal}
                            translationKey=""
                            getData={getData}
                        />
                    ) : (
                        <SearchResults
                            loading={loading}
                            inputText={inputValue}
                            hideButtons={props.hideButtons}
                            selectedItems={selectedItems}
                            userFormHandler={userFormHandler}
                            companyFormHandler={companyFormHandler}
                            themeFormHandler={props.createTheme ? themeFormHandler : undefined}
                            areaOfResponsibilityFormHandler={props.createAreaOfResponsibility ? areaOfResponsibilityFormHandler : undefined}
                            onSelectItem={autoSaveSelectItemsHandler}
                            type={EFilterType.personal}
                            buttonsType={props.buttonsType}
                            multiSelect={props.multiSelect}
                            items={searchResults.filter(result => !(selectedItems.map(item => item.id).includes(result.key))).map(elem => ({
                                text: elem.text,
                                id: elem.key,
                                visible: true,
                                data: elem.data,
                                translationKey: ''
                            }))}
                        />
                    )}

                </SearchResult>
            }
            <NewCompanyForm
                isVisible={showCompanyForm}
                closeHandler={companyFormHandler}
                initialData={{
                    name: inputValue
                }}
                returnData={selectElement}
            />
            <NewUserForm
                onlyDataFromForm={props.onlyDataFromForm}
                isVisible={showUserForm}
                closeHandler={closeUserRofmHandler}
                initialData={{
                    firstName: hrApiData?.firstName ?? (inputValue?.split(/ (.*)/)?.[0] || ''),
                    lastName: hrApiData?.lastName ?? (inputValue?.split(/ (.*)/)?.[1] || ''),
                    email: hrApiData?.email ?? ''
                }}
                returnData={selectElement}
            />
            {!!props.errorMsg &&
                <Hint type={EHintType.ERROR}>{props.errorMsg}</Hint>
            }
        </Wrapper>
    );
};
