import React, { FC, MutableRefObject, ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addAlert as addAlertAction } from '../actions/alertActions';
import { getConditions, searchInClauses, searchInPOAClauses } from '../actions/assetsmanagementActions';
import { getProfileById } from '../actions/profileActions';
import { IGetFaqForQnA, ISearchFaq } from '../entities/AssetManagement/actions';
import { ICondition } from '../entities/AssetManagement/global';
import { EAlertType } from '../entities/IAlert';
import { ICabinet } from '../entities/IProfile';
import { getUserPrefferedLanguage } from '../actions/languageActions';
import { ELcid } from '../entities/ILanguage';

export const useDropdown = <T extends HTMLElement = HTMLDivElement>(findFirstNonClickAncestor?: boolean): [
    RefObject<T>,
    boolean,
    () => void,
    () => void,
    () => void
] => {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const wrapperRef = useRef<T>(null); // tslint:disable-line:no-null-keyword

    const toggleDropdown = useCallback(() => {
        setDropdownOpen(!dropdownOpen);
    }, [dropdownOpen]);

    const closeDropdown = useCallback(() => {
        setDropdownOpen(false);
    }, []);

    const openDropdown = useCallback(() => {
        setDropdownOpen(true);
    }, []);

    const hasNonClick = (node) => {
        return node.dataset.nonclick && node.dataset.nonclick === (true).toString();
    };

    // tslint:disable-next-line:no-null-keyword
    const getFirstNonClickAncestor = (elem: HTMLElement): HTMLElement | null => {
        let node = elem.parentNode;
        do {
            if (node instanceof HTMLElement && hasNonClick(node)) {
                return node;
            }
            // tslint:disable-next-line:no-conditional-assignment
        } while ((node = node?.parentNode));
    };

    const handleClick = useCallback((e: MouseEvent) => {
        const target = e.target as any;

        if (dropdownOpen && wrapperRef?.current && !wrapperRef.current.contains(target)) {
            if (hasNonClick(target) || (findFirstNonClickAncestor && getFirstNonClickAncestor(target))) {
                // first ancestor with nonclik data attr means we ignore the event
                return;
            }
            setDropdownOpen(false);
        }
    }, [dropdownOpen]);

    useEffect(() => {
        if (dropdownOpen) {
            document.addEventListener('click', handleClick, false);
            return () => document.removeEventListener('click', handleClick, false);
        } else {
            document.removeEventListener('click', handleClick, false);
        }
    }, [dropdownOpen]);

    return [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown, openDropdown];
};

interface IUrlQuery {
    [key: string]: unknown;
}

interface IResizeState {
    innerWidth: number;
    innerHeight: number;
    onResize?: () => void;
}

export const useResize = (onResize?: () => void) => {
    const [sizeState, setSizeState] = useState<IResizeState>({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
        onResize
    });

    const debounceRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

    const resizeHandler = useCallback(() => {
        if (debounceRef.current !== undefined) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(() => {
            setSizeState({
                innerHeight: window.innerHeight,
                innerWidth: window.innerWidth,
                onResize
            });

            if (onResize) {
                onResize();
            }
        }, 600);
    }, [setSizeState]);

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [resizeHandler]);

    return sizeState;
};

interface IUseResizeProps {
    onResize?: () => void;
    children(resize: IResizeState): React.ReactElement;
}

export const UseResize: FC<React.PropsWithChildren<IUseResizeProps>> = ({ children, onResize }) => {
    const { innerHeight, innerWidth } = useResize(onResize);
    return children({ innerHeight, innerWidth });
};

export const useAlert = () => {
    const dispatch = useDispatch();

    const addAlert = useCallback((content: ReactNode, type: EAlertType = EAlertType.SUCCESS) => {
        dispatch(addAlertAction(content, type));
    }, []);

    return addAlert;
};

export const useOutsideClickListener = (ref: React.MutableRefObject<any>, action: () => void) => {
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                action();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, action]);
};

export const useMedia = (query: string): boolean => {
    const [matches, setMatches] = useState<boolean>(window.matchMedia(query).matches);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }

        const listener = () => setMatches(media.matches);
        media.addListener(listener);

        return () => media.removeListener(listener);
    }, [query]);

    return matches;
};
export const useCancel = (organization: string) => {
    const onCancelClick = useCallback(() => {
        window.location.replace(`/orgs/${organization}/dashboard`);
    }, [organization]);

    return { onCancelClick };
};

const supportedCopyToClipboard = document.queryCommandSupported?.('copy') || !!navigator.clipboard?.writeText;

export const useCopyInputToClipboard = (ref: MutableRefObject<HTMLInputElement>) => {
    const select = useCallback(() => ref.current?.select(), []);
    const copy = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(ref.current?.value);
        } catch (error) {
            select();
            document.execCommand?.('copy');
        }
    }, []);
    return [supportedCopyToClipboard, copy, select] as const;
};

const hiddenInput = document.createElement('input');

export const useCopyValueToClipboard = () => {
    const inputRef = useRef(hiddenInput);
    const [supported, copy] = useCopyInputToClipboard(inputRef);
    const copyValue = useCallback(async (value: string) => {
        try {
            await navigator.clipboard.writeText(value);
        } catch (error) {
            document.body.appendChild(inputRef.current);
            inputRef.current.value = value;
            copy();
            document.body.removeChild(inputRef.current);
        }
    }, []);
    return { supported, copyValue } as const;
};

export const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string) => {
    useEffect(() => {
        if (textAreaRef) {
            // We need to reset the height momentarily to get the correct
            // scrollHeight for the textarea
            textAreaRef.style.height = '0px';
            const scrollHeight = textAreaRef.scrollHeight;

            // We then set the height directly, outside of the render loop
            // Trying to set this with state or a ref will product an
            // incorrect value.
            textAreaRef.style.height = scrollHeight + 'px';
        }
    }, [textAreaRef, value]);
};

type GetProfileById = ReturnType<typeof getProfileById>;

export interface ICachedProfile {
    id: string;
    firstName: string;
    lastName: string;
    picture?: string;
    jobTitle?: string;
    cabinet?: ICabinet;
}

interface ICachedProfiles {
    [authorId: string]: ICachedProfile;
}

interface ILoadingProfiles {
    [authorId: string]: boolean;
}

const cachedProfiles: ICachedProfiles = {};
const loadingProfiles: ILoadingProfiles = {};

export const useCachedProfiles = () => {
    const dispatch = useDispatch();

    const getProfilePromise = (authorId: string): Promise<ICachedProfile> => {
        return new Promise((resolve, reject) => {
            const profile = cachedProfiles[authorId];

            if (profile) {
                resolve(profile);
            } else if (loadingProfiles[authorId]) {
                // retry the whole process in a moment, to ensure the data is loaded
                // or properly rejected, we wait for the request associated with the profile to finish
                setTimeout(() => {
                    getProfilePromise(authorId).then(resolve).catch(reject);
                }, 1000);
            } else {
                // mark profile as loading so that we can avoid many requests
                loadingProfiles[authorId] = true;

                return dispatch<GetProfileById>(getProfileById(authorId))
                    .then(profileFromResponse => {
                        cachedProfiles[authorId] = { ...profileFromResponse };
                        delete loadingProfiles[authorId];
                        resolve(profileFromResponse);
                    })
                    .catch(reject);
            }
        });
    };

    return getProfilePromise;
};

type GetConditions = ReturnType<typeof getConditions>;

export interface ICachedCondition extends ICondition { }

let cachedConditionsDuration: number = 10000; // 10 seconds
let cachedConditionsTime: number = undefined;
let cachedConditions: ICachedCondition[] = [];

export const useCachedConditions = (bustCache?: boolean) => {
    const dispatch = useDispatch();

    const getConditionsPromise = (): Promise<ICondition[]> => {
        return new Promise((resolve, reject) => {
            if (bustCache || !cachedConditionsTime || (Date.now()) >= (cachedConditionsTime + cachedConditionsDuration)) {
                cachedConditionsTime = Date.now();

                return dispatch<GetConditions>(getConditions())
                    .then(conditionsFromResponse => {
                        cachedConditions = conditionsFromResponse;
                        resolve(conditionsFromResponse);
                    })
                    .catch(reject);
            }

            resolve(cachedConditions);
        });
    };

    return getConditionsPromise;
};

type SearchInClauses = ReturnType<typeof searchInClauses>;

let cachedSearchInClausesDuration: number = 5000; // 5 seconds

interface ICachedClausierSearch {
    cachedAt: number;
    data: IGetFaqForQnA[];
}

interface ICachedClausierSearches {
    [bodyCacheKey: string]: ICachedClausierSearch;
}

const cachedClausierSearch: ICachedClausierSearches = {};

export const useCachedClausierSearch = () => {
    const dispatch = useDispatch();
    const encoder = new TextEncoder();
    const decoder = new TextDecoder();

    const getClausierSearchPromise = async (organizationId: string, body: ISearchFaq): Promise<IGetFaqForQnA[]> => {
        const buffer = encoder.encode(JSON.stringify(body));
        const cacheKey = decoder.decode(await crypto.subtle.digest('SHA-256', buffer));

        return new Promise((resolve, reject) => {
            const cachedSearch = cachedClausierSearch[cacheKey];

            if (!cachedSearch || (Date.now() >= (cachedSearch.cachedAt + cachedSearchInClausesDuration))) {
                return dispatch<SearchInClauses>(searchInClauses(organizationId, body))
                    .then(dataFromResponse => {
                        cachedClausierSearch[cacheKey] = {
                            cachedAt: Date.now(),
                            data: dataFromResponse
                        };
                        resolve(dataFromResponse);
                    })
                    .catch(reject);
            }

            resolve(cachedSearch.data);
        });
    };

    return getClausierSearchPromise;
};

type SearchInPOAClauses = ReturnType<typeof searchInPOAClauses>;

const cachedClausierPOASearch: ICachedClausierSearches = {};

export const useCachedClausierPOASearch = () => {
    const dispatch = useDispatch();
    const encoder = new TextEncoder();
    const decoder = new TextDecoder();

    const getClausierPOASearchPromise = async (organizationId: string, body: ISearchFaq): Promise<IGetFaqForQnA[]> => {
        const buffer = encoder.encode(JSON.stringify(body));
        const cacheKey = decoder.decode(await crypto.subtle.digest('SHA-256', buffer));

        return new Promise((resolve, reject) => {
            const cachedSearch = cachedClausierPOASearch[cacheKey];

            if (!cachedSearch || (Date.now() >= (cachedSearch.cachedAt + cachedSearchInClausesDuration))) {
                return dispatch<SearchInPOAClauses>(searchInPOAClauses(organizationId, body))
                    .then(dataFromResponse => {
                        cachedClausierPOASearch[cacheKey] = {
                            cachedAt: Date.now(),
                            data: dataFromResponse
                        };
                        resolve(dataFromResponse);
                    })
                    .catch(reject);
            }

            resolve(cachedSearch.data);
        });
    };

    return getClausierPOASearchPromise;
};

type GetUserPreferedLanguage = ReturnType<typeof getUserPrefferedLanguage>;
type CachedUserPreferedLanguage = ELcid;

const cachedUserPreferedLanguageDuration: number = 10000; // 10 seconds
let cachedUserPreferedLanguageTime: number = undefined;
let cachedUserPreferedLanguage: ELcid = undefined;

export const useCachedUserPreferedLanguage = (bustCache?: boolean) => {
    const dispatch = useDispatch();

    const getUserPreferendLanguagePromise = (): Promise<CachedUserPreferedLanguage> => {
        return new Promise((resolve, reject) => {
            if (bustCache || !cachedUserPreferedLanguageTime || (Date.now()) >= (cachedUserPreferedLanguageTime + cachedUserPreferedLanguageDuration)) {
                cachedUserPreferedLanguageTime = Date.now();

                return dispatch<GetUserPreferedLanguage>(getUserPrefferedLanguage())
                    .then(lcid => {
                        cachedUserPreferedLanguage = lcid;
                        resolve(lcid);
                    })
                    .catch(reject);
            }

            resolve(cachedUserPreferedLanguage);
        });
    };

    return getUserPreferendLanguagePromise;
};