import React, { FC, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { EPOAContext, IPOAContext } from '.';
import { Row } from '../../../Common/Row/Row';
import { LegalEntitiesPicker } from '../../../Common/Pickers/LegalEntitiesPicker';
import { TextComponent } from '../../../Common/Inputs/TextComponent';
import { UsersPicker } from '../../../Common/Pickers/UsersPicker';
import { EProfileType, IUserBrief } from '../../../../entities/IGlobal';
import { Overview } from '../../../Common/ColoredDatagrid/CellTypeComponents/Overview';
import { useIntlMessage } from '../../../Common/IntlMessage';
import { IClusterTemplate, ISingleCluster } from '../../../../entities/IClusters';
import { EOverviewColumnSubType } from '../../../../entities/IDatagrid';
import { colorStack } from '../../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../../styleHelpers/fontSizes';
import { EPropertieKey } from '../../../../entities/IClusterStake';

const Wrapper = styled.div`
    width: 100%;
    display: block;
    margin: 0 auto;
`;

const InnerWrapper = styled.div`
    width: 100%;
    > div {
        margin-bottom: 1rem
    }
`;

const Title = styled.div`
    color: ${colorStack.darkBlue};
    margin-bottom: 1rem;
    ${fontSizeAndHeight[16]};
    font-weight: 600;
    position: relative;
    padding-top: 1rem;
    margin-top: 1rem;
    &::before {
        display: block;
        border-radius: 20px;
        width: 100%;
        height: 3px;
        background: ${colorStack.bodyBg};
        content: '';
        position: absolute;
        top: 0;
    }
`;

const ClusterData = styled.div`
    padding-left: 1rem;
    padding-right: 1rem;
`;

interface IProps {
    currentClusterData?: ISingleCluster;
    clusterTemplates?: IClusterTemplate[];
}

enum EPOAPropertyKeys {
    COMPANYNAME = 'company-name',
    DELEGATEDNAME = 'delegated-name',
    DELEGATEDCAPACITY = 'delegated-capacity'
}

export const Step2: FC<IProps> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const POAContext = useContext<IPOAContext>(EPOAContext);
    const { organization } = useParams<{ organization: string }>();
    const propertiesList = useMemo(() => props.currentClusterData?.dynamicStakes?.map(elem => elem?.stakeListItems)?.map((elem, i) => elem?.filter(el => [EPropertieKey.PoaParties].includes(el.key)))?.filter(elem => elem?.length).flat()?.[0]?.clusterStakes?.[0]?.properties, [props.currentClusterData]);

    const getPropertieIndex = (field: string) => {
        return propertiesList?.findIndex(elemProp => elemProp?.key === field);
    };

    useEffect(() => {
        if (props.currentClusterData) {
            POAContext.modifyData({ ...props.currentClusterData.dynamicStakes[0].stakeListItems[0].clusterStakes[0].properties[getPropertieIndex(EPOAPropertyKeys.COMPANYNAME)].value, type: EProfileType.LegalEntity }, 'company', 2);
            POAContext.modifyData(props.currentClusterData.dynamicStakes[0].stakeListItems[0].clusterStakes[0].properties[getPropertieIndex(EPOAPropertyKeys.DELEGATEDNAME)].value, 'delegator', 2);
            POAContext.modifyData(props.currentClusterData.dynamicStakes[0].stakeListItems[0].clusterStakes[0].properties[getPropertieIndex(EPOAPropertyKeys.DELEGATEDCAPACITY)].value, 'delegatorCapacity', 2);
        } else {
            POAContext.modifyData(undefined, undefined, 2);
        }
    }, []);

    const onChangeDelegator = (element: { key: string; text: string; data: IUserBrief; }[]) => {
        POAContext.modifyData(element?.[0]?.data, 'delegator', 2);
        POAContext.modifyData(element?.[0]?.data?.jobTitle, 'delegatorCapacity', 2);
    };

    const onChangeDelegate = (element: { key: string; text: string; data: IUserBrief; }[]) => {
        POAContext.modifyData(element?.[0]?.data, 'delegate', 2);
        POAContext.modifyData(element?.[0]?.data?.jobTitle, 'delegateCapacity', 2);
    };

    const onChangeCompany = (element: { key: string; text: string; data: IUserBrief; }[]) => {
        POAContext.modifyData(element?.[0]?.data, 'company', 2);
    };

    const onChangeDelegatorCapacity = (value: string) => {
        POAContext.modifyData(value, 'delegatorCapacity', 2);
    };

    const onChangeDelegateCapacity = (value: string) => {
        POAContext.modifyData(value, 'delegateCapacity', 2);
    };

    const onChangeClusterName = (value: string) => {
        POAContext.modifyData(value, 'declarationName', 2);
    };

    return (
        <Wrapper>
            <InnerWrapper>
                {props.currentClusterData &&
                    <ClusterData>
                        <Overview
                            viewMode
                            subType={EOverviewColumnSubType.cluster}
                            businessId={props.currentClusterData?.businessId?.value}
                            clusterId={props.currentClusterData?.id}
                            organizationUrlName={organization}
                            clusterName={props.currentClusterData?.presentation?.name}
                            clusterTemplateName={props.clusterTemplates?.find(template => template.id === props.currentClusterData?.clusterTemplateId)?.templateName}
                        />
                        <Title>
                            {intlFormatMessage({ id: 'poa.wizard.step2.title' })}
                        </Title>
                    </ClusterData>
                }
                <Row numberOfElements={2} noBorder>
                    <div>
                        <LegalEntitiesPicker
                            label={intlFormatMessage({ id: 'poa.wizard.label.company' })}
                            allCompanies
                            required
                            value={POAContext.data?.company?.id ? [{
                                key: POAContext.data?.company?.id,
                                text: POAContext.data?.company.Name,
                                data: POAContext.data?.company
                            }] : undefined}
                            onSelectElement={onChangeCompany}
                        />
                    </div>
                    <div>
                    </div>
                </Row>
                <Row numberOfElements={2} noBorder>
                    <div>
                        <UsersPicker
                            label={intlFormatMessage({ id: 'poa.wizard.label.delegator' })}
                            required
                            value={POAContext.data?.delegator?.id ? [{
                                key: POAContext.data?.delegator?.id,
                                text: `${POAContext.data?.delegator?.firstName}
                                    ${POAContext.data?.delegator?.lastName}`,
                                data: POAContext.data?.delegator
                            }] : undefined}
                            onSelectElement={onChangeDelegator}
                        />
                    </div>
                    <div>
                        <TextComponent
                            label={intlFormatMessage({ id: 'poa.wizard.label.delegatorCapacityOf' })}
                            required
                            value={POAContext.data?.delegatorCapacity}
                            onChange={onChangeDelegatorCapacity}
                        />
                    </div>
                </Row>
                <Row numberOfElements={2} noBorder>
                    <div>
                        <UsersPicker
                            label={intlFormatMessage({ id: 'poa.wizard.label.delegate' })}
                            required
                            value={POAContext.data?.delegate?.id ? [{
                                key: POAContext.data?.delegate?.id,
                                text: `${POAContext.data?.delegate?.firstName}
                                    ${POAContext.data?.delegate?.lastName}`,
                                data: POAContext.data?.delegate
                            }] : undefined}
                            onSelectElement={onChangeDelegate}
                        />
                    </div>
                    <div>
                        <TextComponent
                            value={POAContext.data?.delegateCapacity}
                            onChange={onChangeDelegateCapacity}
                            label={intlFormatMessage({ id: 'poa.wizard.label.delegateCapacityOf' })}
                            required
                        />
                    </div>
                </Row>
                <Row numberOfElements={1} noBorder>
                    <TextComponent
                        label={intlFormatMessage({ id: 'poa.wizard.label.declarationName' })}
                        required
                        value={POAContext.data?.declarationName}
                        onChange={onChangeClusterName}
                    />
                </Row>
            </InnerWrapper>
        </Wrapper>
    );
};