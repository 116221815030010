import React, { useCallback, useState, useEffect, FC, useRef } from 'react';
import styled, { css } from 'styled-components';
import Scrollbars from 'react-custom-scrollbars';
import { rgba } from 'polished';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

import { useDropdown } from '../../../tools/hooks';
import { useEscKey } from '../../../tools/keyboardTools';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize, fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import boxShadow, { modalsShadow } from '../../../styleHelpers/mixins/shadow';
import { Button, EButtonTypeSchema } from '../Buttons/NewButton';
import { EPopupSizes } from '../../../entities/IGlobal';
import { SingleStep } from './SingleStep';
import { ETemplateTypes } from '../../../entities/IClusters';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import IntlMessage, { useIntlMessage } from '../IntlMessage';

const SWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background: ${rgba(colorStack.label, .75)};
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    ${modalsShadow()};
`;

const RightMenuWrapper = styled.div`
    display: flex;
`;

const SWrapperInner = styled.div<{ size: EPopupSizes }>`
    background: ${colorStack.white};
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;
    border-radius: 8px;
    ${boxShadow()};
    max-width: 100%;
    ${props => props.size === EPopupSizes.SMALL && css`
        width: auto;
        max-width: 700px;
    `}
    ${props => props.size === EPopupSizes.SMALL && css`
        width: 700px;
    `}
    ${props => props.size === EPopupSizes.MEDIUM && css`
        width: 768px;
    `}
    ${props => props.size === EPopupSizes.BIG && css`
        width: 1024px;
    `}
    ${props => props.size === EPopupSizes.MONSTER && css`
        width: 1200px;
    `}
`;

const ContextMenuWrapper = styled.div`
    position: relative;
    > svg {
        color: ${colorStack.middleBlue};
        margin: 0 1rem 0 0;
        font-size: ${fontSize[20]};
        cursor: pointer;
    }
`;

const SHeader = styled.div`
    padding: 1.5rem;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${colorStack.whiteGrey};
    svg {
        fill: ${colorStack.disabled};
        &:hover{
            fill: ${colorStack.red}
        }
    }
`;
const STitle = styled.div`
    font-weight: 700;
    color: ${colorStack.content};
    ${fontSizeAndHeight[20]};
`;
const SClosePopup = styled.div`
    cursor: pointer;
    svg {
        font-size: 24px;
    }
`;

const SContent = styled.div`
    padding: .5rem;
`;

const SpinnerWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const SFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-radius: 0 0 8px 8px;
`;
const SSteps = styled.div`
    width:55%;
    ${fontSizeAndHeight[16]};
    color: ${colorStack.content};
    span {
        margin-left: 24px;
        ${fontSizeAndHeight[13]};
        color: ${colorStack.middleGrey};
    }
`;
const SButtonBox = styled.div`
    display: flex;
    justify-content: space-between;
    gap: .5rem;
`;

export interface IProps {
    showPopup: boolean;
    size?: EPopupSizes;
    title: React.ReactNode;
    confirmDisabled: boolean;
    steps: {
        title?: string;
        content: React.ReactNode;
        name?: string;
        nextStepButtonText?: string;
        showSteps?: boolean
    }[];
    isNextButtonDisabled?: boolean;
    confirmFinished?: boolean;
    sendButtonText?: React.ReactNode;
    lastButtonText?: React.ReactNode;
    showDeclareNew?: boolean;
    wizardType?: string;
    isFormValid?: boolean;
    dirty?: boolean;
    sendButtonType?: 'button' | 'submit' | 'reset';
    contextMenu?: React.ReactNode;
    noPadding?: boolean;
    loadingData?: boolean;
    preventClosingOnClickingOutside?: boolean;
    confirmHandler?();
    finishHandler?();
    clearForm?();
}

export const StepsPopup: FC<React.PropsWithChildren<IProps>> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const popupWrapper = useRef<HTMLDivElement>();

    useEscKey(props.finishHandler);

    useEffect(() => {
        if (props.showPopup) {
            setCurrentStep(1);
        }
        setShowPopup(props.showPopup);
    }, [props.showPopup]);

    useEffect(() => {
        if (!props.preventClosingOnClickingOutside) {
            const handleClickOutside = (event) => {
                if (popupWrapper.current && !popupWrapper.current.contains(event.target)) {
                    props.finishHandler();
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, []);

    const handleScrollbarsMount = useCallback((scrollbar) => {
        scrollbar?.scrollToTop();
    }, [currentStep]);

    const addStep = useCallback(() => {
        setCurrentStep(step => step + 1);
    }, []);

    useEffect(() => {
        props.confirmFinished && addStep();
    }, [props.confirmFinished]);

    const substractStep = useCallback(() => {
        setCurrentStep(step => step - 1);
    }, []);

    const confirm = () => {
        if (props.confirmHandler) {
            setLoading(true);

            const ret = props.confirmHandler();
            if (ret.then) {
                ret.then(() => setLoading(false));
            } else {
                setLoading(false);
            }
        }
    };

    const finish = useCallback(() => {
        setLoading(false);
        props.finishHandler();
    }, [props.finishHandler]);

    const declareNew = useCallback(() => {
        props.clearForm();
        setCurrentStep(1);
    }, [currentStep]);

    const openContextMenu = useCallback(() => {
        toggleDropdown();
    }, [toggleDropdown]);

    return (
        <>
            {showPopup &&
                <SWrapper>
                    <SWrapperInner size={props.size || EPopupSizes.SMALL} ref={popupWrapper}>
                        <SHeader>
                            <STitle>{props.title}</STitle>
                            <RightMenuWrapper>
                                {props.contextMenu &&
                                    <ContextMenuWrapper ref={wrapperRef} onClick={openContextMenu}>
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                        {dropdownOpen &&
                                            <>
                                                {props.contextMenu}
                                            </>
                                        }
                                    </ContextMenuWrapper>
                                }
                                <SClosePopup onClick={props.finishHandler}>
                                    <FontAwesomeIcon icon={faXmark} color={colorStack.darkGrey}/>
                                </SClosePopup>
                            </RightMenuWrapper>
                        </SHeader>
                        <SContent>
                            <Scrollbars
                                autoHeight
                                autoHeightMin="60vh"
                                autoHeightMax="60vh"
                                ref={handleScrollbarsMount}
                            >
                                {props.steps.map((step, index) =>
                                    <SingleStep
                                        key={index}
                                        data={({
                                            title: step.title,
                                            name: step.showSteps && (step.name || `${intlFormatMessage({ id: 'global.step' }, { index: index.toString() })}`)
                                        })}
                                        isActive={currentStep - 1 === index}
                                        noPadding={props.noPadding}
                                    >
                                        {step.content}
                                    </SingleStep>
                                )}
                            </Scrollbars>
                        </SContent>
                        <SFooter>
                            <SSteps>
                                {props?.steps?.length > 2 && currentStep !== props.steps?.length && (
                                    <>
                                        <IntlMessage id="cluster.stepsPopup.steps" values={{ currentStep: currentStep, stepsLength: props.steps?.length - 1 }} />
                                        <ProgressBar percent={(currentStep / (props.steps?.length - 1)) * 100} />
                                    </>
                                )}
                            </SSteps>
                            <SButtonBox>
                                {(currentStep > 1 && currentStep !== props.steps.length) &&
                                    <Button onClick={substractStep} disabled={loading || props.loadingData} typeSchema={EButtonTypeSchema.TERTIARY} data-lc="js-lc-button-back"><IntlMessage id="global.back" /></Button>
                                }
                                {currentStep < props.steps.length - 1 &&
                                    <Button
                                        onClick={addStep}
                                        disabled={!(props.isFormValid && props.dirty)}
                                        data-lc="js-lc-button-next"
                                    >
                                        <IntlMessage id="global.next" />
                                    </Button>
                                }
                                {currentStep === props.steps.length && (
                                    <>
                                        {props.showDeclareNew && (
                                            <Button onClick={declareNew} typeSchema={EButtonTypeSchema.TERTIARY} data-lc="js-lc-button-anotherDeclaration">
                                                {props.wizardType === ETemplateTypes.COI && <IntlMessage id="coi.declareAnotherLink" />}
                                                {props.wizardType === ETemplateTypes.Gift && <IntlMessage id="giftinvitation.startAnotherDeclaration" />}
                                                {props.wizardType === ETemplateTypes.TradeAssociations && <IntlMessage id="tradeAssociation.wizard.declareNew" />}
                                            </Button>
                                        )}
                                        <Button typeSchema={EButtonTypeSchema.TERTIARY} data-lc="js-lc-button-close" onClick={finish}>{props.lastButtonText || <IntlMessage id="global.close" />}</Button>
                                    </>
                                )}
                                {(currentStep === props.steps.length - 1 || (props.confirmHandler && props.steps.length === 1)) &&
                                    <>
                                        <SpinnerWrapper>
                                            <Button onClick={confirm} data-lc="js-lc-button-send" type={props.sendButtonType || 'submit'} disabled={loading || !props.isFormValid || props.loadingData} loading={loading}>
                                                {props.sendButtonText || <IntlMessage id="global.send" />}
                                            </Button>
                                        </SpinnerWrapper>
                                    </>
                                }
                            </SButtonBox>
                        </SFooter>
                    </SWrapperInner>
                </SWrapper>
            }
        </>
    );
};
