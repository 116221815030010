import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { IClusterTemplate } from '../../../../entities/IClusters';
import { getClusterTemplates } from '../../../../actions/clustersActions';
import { ESpinnerSize, Spinner } from '../../../Common/Spinner/Spinner';
import IntlMessage from '../../../Common/IntlMessage';
import { SelectCard } from '../../../Common/SelectCard/SelectCard';
import { GeneralContext, IGeneralContext } from '.';

type GetClusterTemplates = ReturnType<typeof getClusterTemplates>;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
        margin: 0 0 1rem 0;
    }
`;

const Step1: FC = () => {
    const generalContext = useContext<IGeneralContext>(GeneralContext);
    const [templates, setTemplates] = useState<IClusterTemplate[]>([]);
    const [loadingTemplates, setLoadingTemplates] = useState<boolean>(true);
    const dispatch = useDispatch();

    useEffect(() => {
        generalContext.setData({...generalContext.data, stepNumber: 1});
        setLoadingTemplates(true);
        dispatch<GetClusterTemplates>(getClusterTemplates(generalContext.organizationId, true)).then(clusterTemplates => {
            setTemplates(clusterTemplates);
            setLoadingTemplates(false);
        });
    }, []);

    const checkHandler = (templateKey: string) => {
        generalContext.setData({...generalContext.data, selectedTemplateId: templates.find(elem => elem.templateKey === templateKey).id});
    };

    return (
        <Wrapper>
            {!loadingTemplates ?
                <>
                    {templates?.length > 0 ? (
                        templates?.map((elem) =>
                            <Fragment key={elem.id}>
                                <SelectCard
                                    isChecked={generalContext?.data?.selectedTemplateId === elem.id}
                                    value={elem.templateKey}
                                    imageKey={undefined}
                                    templateKey={elem.templateKey}
                                    templateName={elem.templateName}
                                    onClick={checkHandler}
                                    name={`general.popup.step1.name.${elem.templateKey}`}
                                    description={`general.popup.step1.description.${elem.templateKey}`}
                                />
                            </Fragment>
                        )
                    ) : (
                        <IntlMessage id="global.wizard.noTemplates" />
                    )}
                </>
                : <Spinner size={ESpinnerSize.MEDIUM} />
            }
        </Wrapper>
    );
};

export default Step1;