import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { TextComponent } from '../../../../Common/Inputs/TextComponent';
import { ErrorText } from './StakeStyles';
import { NewRT } from '../../../../Common/RichText/NewRT';
import { NewRtView } from '../../../../Common/RichText/NewRtView';

const Wrapper = styled.div`
    span {
        overflow-wrap: break-word;
    }
`;

interface ITextContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: string;
    simple?: boolean;
    setEditingData(propertie: IClusterStakeProperty);
}

export const TextContent: FC<React.PropsWithChildren<ITextContentProps>> = ({ simple, error, data, editStake, propertieId, setEditingData }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            setEditingData({
                ...data,
                value: inputValue,
                id: propertieId
            });
        }
        didMountRef.current = true;
    }, [inputValue]);

    useEffect(() => {
        setInputValue(data.value || '');
    }, [editStake]);

    const changeText = (value: string) => {
        setInputValue(value);
    };

    return (
        <Wrapper>
            {(editStake && didMountRef.current) ? (
                <>
                    {simple ? (
                        <TextComponent value={inputValue} onChange={(value: string) => changeText(value)} />
                    ) : (
                        <NewRT
                            content={inputValue}
                            onChange={value => changeText(value)}
                        />
                    )}
                    {error &&
                        <ErrorText>{error}</ErrorText>
                    }
                </>
            ) : (
                <>
                    {simple ? (
                        <div>{data.value || ''}</div>
                    ) : (
                        <NewRtView content={data.value || ''} />
                    )}
                </>
            )}
        </Wrapper>
    );
};