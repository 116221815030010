import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';

export enum EProgressBarSize {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM'
}

export enum EProgressBarColorTheme {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
    GREEN = 'GREEN',
    RED = 'RED',
    ORANGE = 'ORANGE'
}

const Bar = styled.div<IProps & { withMargin?: boolean }>`
    border-radius: 4px;
    height: 8px;
    width: 100%;
    background: ${colorStack.ligthGrey};
    overflow: hidden;
    display: flex;
    align-items: center;
    ${props => props.size === EProgressBarSize.MEDIUM && css`
        height: 16px;
    `};
    ${props => props.withMargin && css`
        margin: 0 .5rem;
    `}
    &:before {
        content: '';
        display: block;
        background: ${colorStack.middleBlue};
        border-radius: 4px;
        height: 8px;
        width: 100%;
        position: relative;
        transition: right .3s;
        ${props => props.size === EProgressBarSize.MEDIUM && css`
            height: 16px;
        `};
        ${props => props.percent && css`
            right: ${`calc(100% - ${props.percent}%)`};
        `}
        ${props => props.themeColor === EProgressBarColorTheme.DISABLED && css`
            background: ${colorStack.disabled};
        `};
        ${props => props.themeColor === EProgressBarColorTheme.GREEN && css`
            background: ${colorStack.middleGreen};
        `};
        ${props => props.themeColor === EProgressBarColorTheme.RED && css`
            background: ${colorStack.middleRed};
        `};
        ${props => props.themeColor === EProgressBarColorTheme.ORANGE && css`
            background: ${colorStack.middleOrange};
        `};
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin: .3rem 0 0 0;
`;

const Num = styled.div`
    color: ${colorStack.content};
    ${fontSizeAndHeight[16]};
    font-weight: 600;
`;

interface IProps {
    size?: EProgressBarSize;
    themeColor?: EProgressBarColorTheme;
    percent: number;
    leftNumber?: number;
    rightNumber?: number;
}

export const ProgressBar: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <Wrapper>
            {props.leftNumber &&
                <Num>{props.leftNumber}</Num>
            }
            <Bar
                withMargin={!!props.leftNumber || !!props.rightNumber}
                percent={props.percent}
                size={props.size || EProgressBarSize.SMALL}
                themeColor={props.themeColor || EProgressBarColorTheme.ACTIVE}
            />
            {props.rightNumber &&
                <Num>{props.rightNumber}</Num>
            }
        </Wrapper>
    );
};