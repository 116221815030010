import React, { useEffect, useState, useCallback, useMemo, FC } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faRepeat, faLightbulbOn } from '@fortawesome/pro-regular-svg-icons';
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';
import { faBell } from '@fortawesome/pro-solid-svg-icons';

import { colorStack } from '../../../../../styleHelpers/colors';
import boxShadow from '../../../../../styleHelpers/mixins/shadow';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { DateStandardFormat } from '../../../../Common/Date/DateStandardFormat';
import { numberOfDays } from '../../../../../tools/date';
import { getOverviewDates, removeDate } from '../../../../../actions/datesActions';
import { IState } from '../../../../../reducers';
import { IClusterReducer } from '../../../../../reducers/clustersReducer';
import { DatesPopup } from './DatesPopup/DatesPopup';
import { StepsPopup } from '../../../../Common/StepsPopup/StepsPopup';
import { IDates, EDatesTypes } from '../../../../../entities/IDates';
import { IStakeConfiguration } from '../../../../../entities/IClusterStake';
import { Avatar } from '../../../../Common/Avatar/Avatar';
import { Button, EButtonTypeSchema } from '../../../../Common/Buttons/NewButton';
import { EClusterPermissionsBase } from '../../../../../entities/IPermissions';
import { EDatesStatus } from '../../../../../entities/IDates';
import { EPopupSizes, IReferencial } from '../../../../../entities/IGlobal';
import { getLegalEntitiesDates } from '../../../../../actions/clustersActions';
import { addNewKeyDate, updateKeyDate } from '../../../../../actions/datesActions';
import { ContextMenu } from '../../../../Common/ContextMenu/ContextMenu';
import { useAlert } from '../../../../../tools/hooks';
import { EAlertType } from '../../../../../entities/IAlert';
import { ILanguageReducer } from '../../../../../reducers/languageReducer';
import { getReferentialsByUniqueKey } from '../../../../../actions/globalActions';
import { EFacetsTypes } from '../../../../../entities/IFilters';
import IntlMessage, { useIntlMessage } from '../../../../Common/IntlMessage';
import { getStatusText } from '../../../../../tools/statusHelper';

type GetOverviewDates = ReturnType<typeof getOverviewDates>;
type GetLegalEntitiesDates = ReturnType<typeof getLegalEntitiesDates>;
type AddNewKeyDate = ReturnType<typeof addNewKeyDate>;
type UpdateKeyDate = ReturnType<typeof updateKeyDate>;
type RemoveDate = ReturnType<typeof removeDate>;
type GetReferentialsByUniqueKey = ReturnType<typeof getReferentialsByUniqueKey>;

const Wrapper = styled.div`
    margin: 0 0 20px 0;
`;

const InfoBox = styled.div<{ colors: { bg: string, border: string } }>`
    ${props => props.colors && css`
        background-color: ${props.colors.bg};
        border-bottom: 1px solid ${props.colors.border};
    `}
    width: 8px;
    height: 100%;
`;

const SingleItem = styled.div`
    height: 42px;
    display: flex;
    align-items: center;
    margin: 0 0 1px 0;
`;

const MainSingleBox = styled.div`
    background-color: ${colorStack.whiteGrey};
    height: 100%;
    padding-bottom: 11px;
    padding-top: 13px;
    padding-left: 8px;
    padding-right: 1rem;
    margin-right: 2px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    cursor: pointer;
    border-bottom: 1px solid ${colorStack.whiteGrey};
`;

const SingleBox = styled.div`
    background-color: ${colorStack.whiteGrey};
    border-bottom: 1px solid ${colorStack.whiteGrey};
    height: 100%;
    padding-bottom: 11px;
    padding-top: 13px;
    margin-right: 2px;
    width: 17%;
    max-width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DateBox = styled.div<{ colors: { bg: string, border: string } }>`
    ${props => props.colors && css`
        background-color: ${props.colors.bg};
        border-bottom: 1px solid ${props.colors.border};
    `}
    width: 17%;
    max-width: 100%;
    height: 100%;
    padding-bottom: 11px;
    padding-top: 13px;
    margin-right: 2px;
    text-align: center;
`;

const Headers = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TableHeaders = styled.div<{ isLobbyingActionHearder?: boolean }>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0 0 8px 0;
    > div {
        color: ${colorStack.disabled};
        width: 17%;
        text-align: center;
        ${props => !props.isLobbyingActionHearder && css`
            &:first-child {
                width: 50%;
            }
        `}
    }
`;

const ActorsNumber = styled.div`
    width: 32px;
    height: 32px;
    background: ${colorStack.ligthGrey};
    color: ${colorStack.label};
    border-radius: 100%;
    font-size: ${fontSize[13]};
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colorStack.white};
    position: relative;
    left: -9px;
`;

const Tooltip = styled.div`
    display: none;
    ${boxShadow()};
    position: absolute;
    border: 1px solid ${colorStack.ligthGrey};
    background: ${colorStack.white};
    padding: ${fontSize[10]};
    z-index: 999;
    border-radius: 5px;
    font-size: ${fontSize[13]};
    > span {
        display: flex;
        align-items: center;
        > span {
            font-weight: 500;
        }
    }
`;

const RecuringContainer = styled.span`
    &:hover {
        .recuring-tooltip {
            display: block;
        }
    }
    .recuring-tooltip svg {
        margin-right: 0.5rem;
    }
`;

const StatusContainer = styled.div<{ colors: string[] }>`
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: .5rem;
    justify-content: center;
    width: 100%;
    color: ${colorStack.label};
    ${props => props.colors && css`
        color: ${props.colors[0]};
        background: ${props.colors[1]};
    `}
`;

const NotesContainer = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1rem;
`;

interface IProps {
    stakeFamilyKey?: string;
    stakeListKey?: string;
    configuration?: IStakeConfiguration;
    legalEntityId?: string;
    legalDateData?: any;
}

export const DatesContainer: FC<React.PropsWithChildren<IProps>> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const addAlert = useAlert();
    const dispatch = useDispatch();

    const [showEventPopup, setShowEventPopup] = useState<boolean>(false);
    const [dates, setDates] = useState<IDates[]>(undefined);
    const [currentDate, setCurrentDate] = useState<IDates>(undefined);
    const { currentClusterId, clusterPermissions } = useSelector<IState, IClusterReducer>(state => state.clusters);
    const [formValid, setFormValid] = useState<boolean>(false);

    const colors = useMemo(() => ({
        [EDatesStatus.InProgress]: [colorStack.middleBlue, colorStack.whiteBlue],
        [EDatesStatus.NotStarted]: [colorStack.black, colorStack.ligthGrey],
        [EDatesStatus.Done]: [colorStack.lightGreen, colorStack.whiteGreen],
        [EDatesStatus.Overdue]: [colorStack.red, colorStack.whiteRed]
    }), []);

    useEffect(() => {
        if (currentClusterId) {
            dispatch<GetOverviewDates>(getOverviewDates(currentClusterId)).then(response => {
                setDates(response?.[props.stakeFamilyKey]?.[props.stakeListKey]);
            });
        } else {
            dispatch<GetLegalEntitiesDates>(getLegalEntitiesDates(props.legalEntityId)).then(response => {
                setDates(response);
            });
        }
    }, [currentClusterId, props.legalEntityId, props?.legalDateData]);

    const checkIfDateExpired = (date: string) => {
        return numberOfDays(date?.toString());
    };

    const assignColor = (date: string) => {
        const days = checkIfDateExpired(date);
        if (date) {
            return days > 0 ? { bg: colorStack.whiteOrange, border: colorStack.lightOrange } : { bg: colorStack.whiteBlue, border: colorStack.lightBlue };
        } else {
            return ({ bg: colorStack.whiteGrey, border: colorStack.whiteGrey });
        }
    };

    const openPopup = (date: IDates) => {
        if (clusterPermissions?.[EClusterPermissionsBase.ClustersEditInViewMode]) {
            setShowEventPopup(true);
            setCurrentDate(date);
        }
    };

    const closeEditPopup = useCallback(() => {
        setShowEventPopup(false);
        dispatch<GetOverviewDates>(getOverviewDates(currentClusterId)).then(response => {
            setDates(response?.[props.stakeFamilyKey]?.[props.stakeListKey]);
        });
    }, [props]);

    const getDataFromPopup = useCallback((data: IDates) => {
        setCurrentDate(data);
    }, []);

    const confirmHandler = useCallback(() => {
        return new Promise((resolve) => {
            if (currentDate?.id && currentDate?.title) {
                dispatch<UpdateKeyDate>(updateKeyDate(currentClusterId, currentDate.id, currentDate)).then(() => {
                    closeEditPopup();
                    resolve(true);
                });
            } else if (currentDate?.title) {
                dispatch<AddNewKeyDate>(addNewKeyDate(currentClusterId, currentDate, props.stakeFamilyKey, props.stakeListKey)).then(() => {
                    closeEditPopup();
                    resolve(true);
                });
            }
        });
    }, [currentDate, props, closeEditPopup]);

    const validationDates = useCallback((valid: boolean) => {
        setFormValid(valid);
    }, []);

    const removeDateHandler = useCallback(() => {
        dispatch<RemoveDate>(removeDate(currentClusterId, currentDate.id)).then(() => {
            addAlert(<IntlMessage id="dates.remove.success" />, EAlertType.SUCCESS);
            closeEditPopup();
        });
    }, [currentDate]);

    return (
        <>
            <Headers>
                <TableHeaders isLobbyingActionHearder={props?.legalDateData}>
                    {!props?.legalDateData ? (
                        <>
                            <div><IntlMessage id="dates.label.title" /></div>
                            <div><IntlMessage id="dates.label.actors" /></div>
                            <div><IntlMessage id="dates.label.dueDate" /></div>
                            <div><IntlMessage id="dates.label.reminder" /></div>
                        </>
                    ) : (
                        <>
                            <div><IntlMessage id="dates.label.dueDate" /></div>
                            <div><IntlMessage id="dates.label.actors" /></div>
                            <div><IntlMessage id="dates.label.reminder" /></div>
                            <div><IntlMessage id="global.periodicity" /></div>
                            <div><IntlMessage id="dates.label.notes" /></div>
                            <div><IntlMessage id="dates.label.status" /></div>
                        </>
                    )}
                </TableHeaders>
            </Headers>
            <Wrapper>
                {!props?.legalDateData ? dates?.map(date =>
                    <SingleItem key={date.id}>
                        <InfoBox colors={assignColor(date.endAt)} />
                        <MainSingleBox onClick={() => openPopup(date)}>
                            <TitleItem title={date.title} />
                            {date.recuring &&
                                <RecuringContainer >
                                    <FontAwesomeIcon icon={faRepeat}/>
                                    <Tooltip className="recuring-tooltip">
                                        <span>
                                            <FontAwesomeIcon color={colorStack.blue} icon={faLightbulbOn}/>
                                            <IntlMessage
                                                id={`dates.recuring.tooltip.${date?.recuring?.frequency?.type}${date?.recuring?.frequency?.number < 2 ? 'V2' : ''}`}
                                                values={{
                                                    number: date?.recuring?.frequency?.number,
                                                    endDate: date?.recuring?.frequency?.endDate ? moment(date?.recuring?.frequency?.endDate).format('DD MMM YYYY') : intlFormatMessage({id: `dates.label.canceled`}),
                                                    dayOfMonth: date?.recuring?.frequency?.dayOfMonth,
                                                    month: date?.recuring?.frequency?.month,
                                                    daysOfWeek: date?.recuring?.frequency?.daysOfWeek.map(elem => intlFormatMessage({id: `global.dayName.${elem}`})).join(',')
                                                }}
                                            />
                                        </span>
                                    </Tooltip>
                                </RecuringContainer>
                            }
                        </MainSingleBox>
                        <SingleBox>
                            {date.actors.length > 0 &&
                                <>
                                    <Avatar
                                        picture={date.actors[0].picture}
                                        firstName={date.actors[0].firstName}
                                        lastName={date.actors[0].lastName}
                                        id={date.actors[0].id}
                                    />
                                    {date.actors.length > 1 &&
                                        <ActorsNumber>+{date.actors.length - 1}</ActorsNumber>
                                    }
                                </>
                            }
                        </SingleBox>
                        <DateBox colors={assignColor(date.nextOccurrenceDate || date.recuring?.frequency?.endDate)}>
                            {(date.nextOccurrenceDate || date.recuring?.frequency?.endDate) &&
                                <DateStandardFormat date={date.nextOccurrenceDate || date.recuring?.frequency?.endDate} />
                            }
                        </DateBox>
                        <SingleBox>
                            {date.isReminderActivated &&
                                <FontAwesomeIcon icon={faBell} size="lg" color={checkIfDateExpired(date.endAt || date.recuring?.frequency?.endDate) < 0 ? colorStack.middleBlue : colorStack.disabled} />
                            }
                        </SingleBox>
                    </SingleItem>
                ) : props?.legalDateData && (
                    <SingleItem key={props?.legalDateData?.id}>
                        <DateBox colors={assignColor(props?.legalDateData.nextOccurrenceDate)}>
                            {(props?.legalDateData?.endAt) &&
                                <DateStandardFormat date={props?.legalDateData?.endAt} />
                            }
                        </DateBox>
                        <SingleBox>
                            {props?.legalDateData?.actors.length > 0 &&
                                <>
                                    <Avatar
                                        picture={props?.legalDateData.actors[0].picture}
                                        firstName={props?.legalDateData.actors[0].firstName}
                                        lastName={props?.legalDateData.actors[0].lastName}
                                        id={props?.legalDateData.actors[0].id}
                                    />
                                    {props?.legalDateData.actors.length > 1 &&
                                        <ActorsNumber>+{props?.legalDateData.actors.length - 1}</ActorsNumber>
                                    }
                                </>
                            }
                        </SingleBox>
                        <SingleBox>
                            {props?.legalDateData.isReminderActivated &&
                                <FontAwesomeIcon icon={faBell} size="lg" color={checkIfDateExpired(props?.legalDateData.endAt || props?.legalDateData.recuring?.frequency?.endDate) < 0 ? colorStack.middleBlue : colorStack.disabled} />
                            }
                        </SingleBox>
                        <SingleBox>
                            {props?.legalDateData?.recuring &&
                                <FontAwesomeIcon icon={faRepeat}/>
                            }
                        </SingleBox>
                        <SingleBox>
                            <NotesContainer>
                                {props?.legalDateData?.description}
                            </NotesContainer>
                        </SingleBox>
                        <SingleBox>
                            <StatusContainer colors={colors[props?.legalDateData?.status]}>
                                {getStatusText(props?.legalDateData?.status, intlFormatMessage)}
                            </StatusContainer>
                        </SingleBox>
                    </SingleItem>
                )}
                </Wrapper>
                {showEventPopup &&
                    <StepsPopup
                        showPopup={showEventPopup}
                        title={<IntlMessage id="dates.title.popup.mainDate" />}
                        confirmDisabled={false}
                        finishHandler={closeEditPopup}
                        lastButtonText={<IntlMessage id="global.cancel" />}
                        sendButtonText={<IntlMessage id="global.save" />}
                        confirmHandler={confirmHandler}
                        sendButtonType="button"
                        size={EPopupSizes.MEDIUM}
                        isFormValid={formValid}
                        contextMenu={currentDate?.type?.name === EDatesTypes.KeyDate ? <ContextMenu
                            links={[{
                                name: <IntlMessage id="global.delete" />, // 'Delete'
                                ico: faTrashAlt,
                                icoColor: colorStack.red,
                                action: removeDateHandler,
                                visible: currentDate?.type?.name === EDatesTypes.KeyDate
                            }]}
                        /> : undefined}
                        dirty
                        steps={[
                            {
                                content: <DatesPopup
                                    currentDate={currentDate}
                                    configuration={props.configuration}
                                    stakeListKey={props.stakeListKey}
                                    stakeFamilyKey={props.stakeFamilyKey}
                                    isValid={validationDates}
                                    getDataFromPopup={getDataFromPopup}
                                />
                            }
                        ]}
                    />
                }
            {clusterPermissions?.[EClusterPermissionsBase.ClustersEditInViewMode] && !props?.legalDateData &&
                <Button onClick={() => openPopup(undefined)} leftIco={faPlus} typeSchema={EButtonTypeSchema.TERTIARY}>
                    <IntlMessage id="dates.button.addNewKeyDate" />
                </Button>
            }
        </>
    );
};

interface ITitleItemProps {
    title: IReferencial;
}

const TitleItem: FC<React.PropsWithChildren<ITitleItemProps>> = props => {
    const dispatch = useDispatch();
    const [nameTranslate, setNameTranslate] = useState<string>('');
    const { userLanguage } = useSelector<IState, ILanguageReducer>(state => state.language);

    useEffect(() => {
        if (props.title?.facetType === EFacetsTypes.ReferentialDataDto && props.title.id) {
            dispatch<GetReferentialsByUniqueKey>(getReferentialsByUniqueKey(props.title.id)).then(res => {
                setNameTranslate(res.name.find(elem => elem.lcid === userLanguage).label);
            });
        } else {
            setNameTranslate(props.title.name);
        }
    }, []);

    return (
        <>
            {nameTranslate}
        </>
    );
};
