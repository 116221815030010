import { IDirectoryContent, IDirectoryTreeData } from '../entities/IClustersFilesystem';
import { ITree } from '../components/Common/TreeView/TreeView';

export const joinPath = (path: string[]) => (path || []).join('\\');

export const convertSlashesToBackslashes = (path: string) => path.replace(/\//g, '\\');

interface IFileExt {
    name: string;
    ext: string;
}

export const getFileExt = (fileName: string = ''): IFileExt => {
    if (fileName === null) {
        return {
            // tslint:disable-next-line:no-null-keyword
            name: null,
            ext: undefined
        };
    }

    const extDotIndex = fileName.lastIndexOf('.');
    if (!extDotIndex) {
        return {
            name: fileName,
            ext: undefined
        };
    } else {
        return {
            name: fileName.substr(0, extDotIndex),
            ext: fileName.substr(extDotIndex + 1)
        };
    }
};

export const parseDirectoryTree = (content: IDirectoryContent[]): ITree<IDirectoryTreeData>[] => {
    return content.map(directoryItem => ({
        id: directoryItem.name,
        name: directoryItem.name,
        children: directoryItem.items && parseDirectoryTree(directoryItem.items),
        data: {
            locked: directoryItem.locked,
            subType: directoryItem.subType
        }
    }));
};

export const extToMime = {
    pdf: 'application/pdf',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
};